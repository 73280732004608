<mat-form-field
  *ngIf="formArray && autocompleteItems"
  class="w-100"
  style="position: relative; top: -10px"
  appearance="outline"
>
  <mat-chip-grid #itemsChipList>
    <mat-chip-row
      *ngFor="let item of formArray.value"
      [ngClass]="getChipColor(item)"
      select
      [editable]="false"
      [removable]="!disabled"
      (removed)="onItemRemove(item)"
    >
      {{ getItemName(item) }}
      <mat-icon matChipRemove [hidden]="disabled"> cancel </mat-icon>
    </mat-chip-row>
    <input
      [placeholder]="name"
      [hidden]="disabled"
      #itemInput
      [formControl]="inputFormControl"
      class="input-field"
      type="text"
      [matAutocomplete]="disabled ? null : itemAutocomplete"
      [matChipInputFor]="itemsChipList"
      (blur)="onItemBlur()"
      (ngModelChange)="handleInputChange($event)"
      matInput
      required
    />
  </mat-chip-grid>
  <mat-autocomplete
    autoActiveFirstOption
    #itemAutocomplete="matAutocomplete"
    (optionSelected)="onItemSelected($event)"
  >
    <cdk-virtual-scroll-viewport
      class="select-menu-inner"
      [itemSize]="25"
      [style.height.px]="calculateDropdownHeightPx()"
    >
      <div *cdkVirtualFor="let group of groupedFilteredOptions">
        <div *ngIf="group.name && group.name.length > 0" class="select-item">
          <img
            class="select-item-icon"
            alt="group icon"
            *ngIf="group.iconUrl"
            [src]="group.iconUrl"
            loading="lazy"
          />
          <span>{{ group.name }}</span>
        </div>
        <mat-option *ngFor="let option of group.options">
          <img
            class="select-item-icon"
            alt="option icon"
            *ngIf="option.iconUrl"
            [src]="option.iconUrl"
            loading="lazy"
          />
          <span>{{ option.label }}</span>
        </mat-option>
      </div>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</mat-form-field>
