import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyService } from "@cai-services";
import Swal from "sweetalert2";
import { APP_PROPS } from "../../../cai-common.module";
import { ApplicationProperties } from "../../../core/_base/layout/models/app-properties.model";
import { Forwarder } from "../../../core/_models/forwarder.model";
import { Office } from "../../../core/_models/office.model";
import { IntercomService } from "../../../core/_services/intercom.service";
import { RegisterService } from "../../../core/_services/register.service";
import { SessionService } from "../../../core/_services/session.service";
import { environment } from "../../../environments";
import { BrandIconUtil } from "../../../utils/brand.util";

@Component({
  "selector": "kt-iata-cass-registration",
  "animations": [
    trigger("openClose", [
      // ...
      state(
        "open",
        style({
          "maxHeight": "300px",
          "opacity": 1,
          "display": "block",
        })
      ),
      state(
        "closed",
        style({
          "maxHeight": "0",
          "overflow": "hidden",
          "opacity": 0,
          "marginTop": 0,
          "marginBottom": 0,
          "display": "block",
        })
      ),
      transition("open => closed", [animate("0.3s")]),
      transition("closed => open", [animate("0.8s 0.5s")]),
    ]),
  ],
  "templateUrl": "./iata-cass-registration.component.html",
  "styleUrls": ["./iata-cass-registration.component.scss"],
})
export class IataCassRegistrationComponent implements OnInit {
  registerForm: FormGroup;
  token: string;
  user: Forwarder;
  confirmation: boolean;
  currentOffices: Office[];
  userOffices: Office[];
  accordionSelected = 0;
  isIataCassFromOtherCompany: boolean;
  isWaiting = false;
  isDisabled = true;
  isVideoClosed = false;
  initialIataCass: string;
  isUpdated: boolean;
  isViewOnly = true;

  @ViewChild("iataCassInput", { "static": false }) iataCassInput: ElementRef;
  @ViewChild("checkbox", { "static": false }) checkbox: ElementRef;

  get isIataCassValid (): boolean {
    return (
      (!this.registerForm.controls.iataCass.value &&
        this.checkbox?.nativeElement?.checked) ||
      this.registerForm.controls.iataCass.valid
    );
  }

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly sessionService: SessionService,
    private readonly registerService: RegisterService,
    private readonly companyService: CompanyService,
    private readonly fb: FormBuilder,
    private readonly ref: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly intercomService: IntercomService
  ) {}

  async ngOnInit (): Promise<void> {
    this.token = this.route.snapshot.queryParamMap.get("token");
    this.onResize();
    this.user = (await this.registerService.getUser(this.token)) as Forwarder;
    (window as any).intercomSettings = {
      "app_id": environment.intercomAppId,
    };
    this.userOffices = (await this.companyService.getCompanyOffices(
      this.token
    )) as Office[];
    this.currentOffices = (await this.companyService.getOffices(
      this.user.company.id,
      this.token
    )) as Office[];
    this.initRegisterForm();
    this.setRegisterFormValidator();
    this.initialIataCass = this.getIataCass();
    this.iataCassInput?.nativeElement?.setAttribute("disabled", "true");
  }

  async onSkipClick (): Promise<void> {
    this.router.navigateByUrl("/auth/login");
  }

  onRegisterClick (): void {
    if (this.registerForm.controls.noIataCass.value) {
      this.onConfirmClick();
      return;
    }
    this.confirmation = true;
  }

  onBackClick (): void {
    this.confirmation = false;
  }

  shouldDisableEdit (): boolean {
    return this.registerForm.controls.noIataCass.value;
  }

  onConfirmClick (): void {
    const iataCass = this.registerForm.controls.iataCass.value as string,
     noIataCass = this.registerForm.controls.noIataCass
      .value as boolean;
    this.user.iataCode = iataCass.split("-")[0];
    this.user.cassCode = iataCass.split("-")[1];
    this.user.noIataCass = noIataCass;

    this.registerService.updateForwarder(this.user, this.token).subscribe(
      async (res) => {
        this.sessionService.setCurrentUser(res);
        this.updateIntercomData(res);
        await this.onSkipClick();
      },
      (e) => {
        console.error("cannot updateForwarder: ", e);
        Swal.fire(
          "",
          $localize`:@@registration.user.error:Something went wrong while registering, please contact support`,
          "error"
        );
      }
    );
  }

  onEditClick (): any {
    this.isViewOnly = !this.isViewOnly;
    if (!this.isIataCassValid) {
      this.isDisabled = true;
    } else {
      this.isDisabled = !this.isViewOnly;
    }

    if (!this.isViewOnly) {
      setTimeout(() => {
        this.iataCassInput.nativeElement.focus();
      });
    }
    this.ref.detectChanges();
    if (this.isViewOnly) {
      this.iataCassInput.nativeElement.setAttribute("disabled", "true");
    } else {
      this.iataCassInput.nativeElement.removeAttribute("disabled");
    }
  }

  onAccordionClick (selected: number): void {
    this.accordionSelected = selected;
  }

  handleBlur () {
    if (!this.isUpdated) {
      this.onIataCassChange();
    }
  }

  async onIataCassChange (): Promise<void> {
    this.isUpdated = true;
    this.isDisabled = true;
    if (
      this.iataCassInput.nativeElement.value.length === 12 &&
      !isNaN(this.iataCassInput.nativeElement.value.replace("-", ""))
    ) {
      this.registerForm.controls.iataCass.setValue(
        this.iataCassInput.nativeElement.value
      );
      const iataCass = this.registerForm.controls.iataCass.value as string,
       iataCode = iataCass.split("-")[0],
       cassCode = iataCass.split("-")[1];
      if (
        !this.currentOffices.some(
          (x) => x.iata === iataCode && x.cass === cassCode
        )
      ) {
        this.isWaiting = true;
        const isExisting = await this.companyService.checkIataCassExist(
          iataCode,
          cassCode,
          this.token
        );
        if (isExisting) {
          if (!this.registerForm || !this.registerForm.valid) {
            this.isDisabled = true;
          } else {
            this.isIataCassFromOtherCompany = isExisting;
            this.isDisabled = false;
          }
          this.isWaiting = false;
          this.ref.detectChanges();
        } else {
          this.isIataCassFromOtherCompany = isExisting;
          this.isWaiting = false;
          this.isDisabled = false;
          this.iataCassInput.nativeElement.setAttribute("disabled", "true");
          this.isViewOnly = true;
          this.ref.detectChanges();
        }
      } else if (this.initialIataCass === iataCass) {
        this.isDisabled = false;
      } else {
        const sameCityOffices = this.currentOffices?.filter((currentOffice) =>
          currentOffice?.airports?.some(
            (airport) =>
              airport?.airportCode === this.user?.originAirport?.airportCode
          )
        );
        if (
          sameCityOffices?.some(
            (x) => x?.iata === iataCode && x?.cass === cassCode
          )
        ) {
          this.isDisabled = false;
          this.ref.detectChanges();
        }
      }
    }
  }

  initRegisterForm () {
    this.registerForm = this.fb.group({
      "noIataCass": [false, Validators.compose([])],
      "iataCass": [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]{7}-[0-9]{4}$"),
        ]),
      ],
    });
    this.registerForm.controls.iataCass.setValue(this.getIataCass());
    this.ref.detectChanges();
  }

  onNoIataCassChange (event: any): void {
    this.registerForm.controls.iataCass.setValidators(
      Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{7}-[0-9]{4}$"),
      ])
    );
    this.registerForm.controls.iataCass.setValue("");
    this.isDisabled = !event.target.checked;
    this.ref.detectChanges();
  }

  setRegisterFormValidator () {
    const iataCass = this.registerForm.get("iataCass");
    this.registerForm.get("noIataCass").valueChanges.subscribe((value) => {
      if (value) {
        iataCass.setValidators(null);
      } else {
        iataCass.setValidators([Validators.required]);
      }
    });
  }

  getIataCass (): string {
    if (this.userOffices) {
      if (this.userOffices[0].iata && this.userOffices[0].cass) {
        this.isDisabled = false;
        return `${this.userOffices[0].iata}-${this.userOffices[0].cass}`;
      } else if (this.userOffices[0].iata) {
        return `${this.userOffices[0].iata}-xxxx`;
      }
    }
    if (!this.currentOffices?.length || !this.user) {
      return "";
    }
    const sameCountry = this.currentOffices.find(
      (office) => office.country.countryCode === this.user.country.countryCode
    ),
     sameAirport = this.currentOffices.find((office) =>
      office.airports.some(
        (airport) => airport.airportCode === this.user.originAirport.airportCode
      )
    );

    if (sameAirport) {
      if (sameAirport.iata && sameAirport.cass) {
        this.isDisabled = false;
        return `${sameAirport.iata}-${sameAirport.cass}`;
      }
    }
    if (sameCountry) {
      if (sameCountry.iata) {
        return `${sameCountry.iata}-xxxx`;
      }
    }
    return "";
  }

  updateIntercomData (user: Forwarder) {
    const intercomSetting = {
      "app_id": environment.intercomAppId,
      "email": user.userEmail,
      "FORWARDER_IATA_CASS": user.iataCode
        ? `${user.iataCode}-${user.cassCode}`
        : undefined,
      "short_company_name": user.company.companyCode,
      "full_company_name": user.company.companyName,
      "Account_Activation_status": user.userStatus,
    };
    this.intercomService.update(intercomSetting);
  }

  closeVideo () {
    this.isVideoClosed = true;
    this.ref.detectChanges();
  }

  onResize () {
    this.isVideoClosed = window.innerWidth < 1250;
    this.ref.detectChanges();
  }

  get backgroundImage (): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      "/assets/media/logos/"
    );
  }

  get isCargoMart (): boolean {
    return this.appProperties.name === "cargomart";
  }

  get isCargoWallet (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
