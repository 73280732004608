import {
  BookingTypeEnum,
  PackingTypeEnum,
  QuoteRequestStatusEnum,
  QuoteStatusEnum,
  WeightTypeEnum,
} from "@cai-services";
import { QuoteItem } from "../../core/_models/quote-item.model";
import { Tracking } from "../../core/_models/tracking.model";

export const mockedTrackingQuoteWithOneStatus = Object.freeze({
  "quoteId": 6975,
  "skyPalletID": null,
  "quoteStatus": QuoteStatusEnum.PENDING_DELIVERY,
  "quoteDate": new Date(2021, 6, 10, 10, 20, 30, 500),
  "awb": "15711112222",
  "awbType": null,
  "awbDisabled": false,
  "maxAttachmentCount": 3,
  "maxAttachmentTotalSize": 9000000,
  "isViewForwarder": true,
  "isViewAirline": false,
  "quoteCurrency": "SGD",
  "airlineBrand": {
    "airlineCompanyId": 29,
    "airlineCompanyName": "Philippine Airlines",
    "airlineCompanyCode": "PR",
    "mainAirlineLogoPath":
      "https://forwarders.cargoai.co/assets/media/airlinesLogo/PR.png",
    "mainWebsiteUrl":
      "http://cargoserv.champ.aero/tracking.asp?Carrier=PR&amp;Pfx=079",
    "awbPrefix": "079",
    "generalConditions": "",
    "company": {
      "id": 1,
      "companyCode": "PR",
      "companyName": "Philippine  Airlines",
      "companyType": "AIRLINE",
      "users": [],
      "airlineBrands": [],
    },
    "emailToConfirms": undefined,
    "negoRoutes": undefined,
    "routes": undefined,
  },
  "lastUpdatedDate": new Date(2021, 6, 10, 10, 20, 30, 500),
  "isRate": false,
  "minWeight": 1767,
  "maxWeight": 3314,
  "maxDensity": 20,
  "validity": new Date(2021, 6, 15, 10, 20, 30, 500),
  "contacts": [{ "contact": { "userEmail": "teamcargoai+PR_SIN@gmail.com" } }],
  "searchUUID": "aaaaaaaa",
  "flightUUID": "bbbbbbbb",
  "provider": false,
  "attachments": [],
  "quoteRequest": {
    "quotes": [],
    "quoteRequestId": 2266,
    "quoteRequestStatus": QuoteRequestStatusEnum.PENDING,
    "transCurrencyAmount": 1,
    "originalQuotePrice": 0,
    "originalQuoteCapacity": 2209,
    "bookingType": BookingTypeEnum.MANUAL_BOOK,
    "comments": "AutoTest CargoAI Matt22/09 13:05",
    "maxDepartureDays": 1,
    "forwarder": {
      "userId": 1,
      "userName": "Forwarder Singapore",
      "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
      "company": {
        "id": 7,
        "companyCode": "Forwarder",
        "companyName": "Forwarder",
        "companyType": "FORWARDER",
      },
      "userStatus": "ACTIVATED",
      "logoPath": "user1.png",
      "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "" },
      "smtp": "",
      "pima": "pimatest",
      "defaultCurrency": "SGD",
    } as any,
    "quoteItems": [
      {
        "quoteItemId": 5253,
        "weight": 2209,
        "dimensionLength": -100,
        "dimensionWidth": -100,
        "dimensionHeight": -1000,
        "numOfItems": 1,
        "weightType": WeightTypeEnum.TOTAL,
        "typeOfProduct": {
          "typeOfProductId": 5,
          "productCode": "GCR",
          "productDescription": "General Cargo",
        },
        "packing": PackingTypeEnum.NONE,
      } as QuoteItem,
    ],
    "isShipmentScreened": false,
    "specialHandlingCodes": [],
  },
  "trips": [
    {
      "id": 96958,
      "endArrivalDate": "2020-09-25T17:00:00+08:00",
      "startDepartureDate": "2020-09-24T19:30:00+08:00",
      "flightNo": "SQ111",
      "flights": [
        {
          "id": 189165,
          "arrivalDate": "2020-09-24T23:10:00+08:00",
          "departureDate": "2020-09-24T19:30:00+08:00",
          "flightNo": "SQ111",
          "originRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "SIN",
            "airportName": "Changi",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1455, "cityCode": "SIN", "cityName": "Singapore" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "destRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "MNL",
            "airportName": "Ninoy Aquino Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "aircraft": {
            "aircraftName": "Unknown Aircraft",
            "aircraftCode": "UNKN",
            "capacity": 50000,
            "aircraftType": {
              "aircraftTypeId": 5,
              "aircraftTypeName": "Unknown",
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "aircrafts": undefined,
            },
          },
        },
      ],
    },
  ],
  "possibleActions": [],
  "originAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "SIN",
    "airportName": "Changi",
    "city": {
      "cityId": 1455,
      "cityCode": "SIN",
      "cityName": "Singapore",
      "state": {
        "country": {
          "countryCode": "SN",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "destinationAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "NRT",
    "airportName": "Narita Intl",
    "city": {
      "cityId": 912,
      "cityCode": "TYO",
      "cityName": "Tokyo",
      "state": {
        "stateId": 1,
        "stateName": "Tokyo",
        "stateCode": "TYO",
        "country": {
          "countryCode": "JP",
          "countryName": "Japan",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "chargeableWeight": 166.0,
  "actualWeight": 1.0,
  "actualVolume": 1.0,
  "trackings": [
    {
      "id": 38,
      "code": "DEP",
      "origin": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },
      "destination": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },

      "location": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },
      "eventDate": new Date(2021, 6, 11, 10, 20, 30, 500),
      "weight": "1",
      "pieces": "1",
      "volume": "1",
      "flightNumber": "SQ111",
      "carbonEmission": "10",
    } as Tracking,
  ],
  "airlineConditions": "",
  "salesConditions": "",
  "trackTraceSubscribe": false,
} as any);

export const mockedTrackingQuoteWithMultipleStatus = Object.freeze({
  "quoteId": 6975,
  "skyPalletID": null,
  "quoteStatus": QuoteStatusEnum.PENDING_DELIVERY,
  "quoteDate": new Date(2021, 6, 10, 10, 20, 30, 500),
  "awb": "15711112222",
  "awbType": null,
  "awbDisabled": false,
  "maxAttachmentCount": 3,
  "maxAttachmentTotalSize": 9000000,
  "isViewForwarder": true,
  "isViewAirline": false,
  "quoteCurrency": "SGD",
  "airlineBrand": {
    "airlineCompanyId": 29,
    "airlineCompanyName": "Philippine Airlines",
    "airlineCompanyCode": "PR",
    "mainAirlineLogoPath":
      "https://forwarders.cargoai.co/assets/media/airlinesLogo/PR.png",
    "mainWebsiteUrl":
      "http://cargoserv.champ.aero/tracking.asp?Carrier=PR&amp;Pfx=079",
    "awbPrefix": "079",
    "generalConditions": "",
    "company": {
      "id": 1,
      "companyCode": "PR",
      "companyName": "Philippine  Airlines",
      "companyType": "AIRLINE",
      "users": [],
      "airlineBrands": [],
    },
    "emailToConfirms": undefined,
    "negoRoutes": undefined,
    "routes": undefined,
  },
  "lastUpdatedDate": new Date(2021, 6, 10, 10, 20, 30, 500),
  "isRate": false,
  "minWeight": 1767,
  "maxWeight": 3314,
  "maxDensity": 20,
  "validity": new Date(2021, 6, 15, 10, 20, 30, 500),
  "contacts": [{ "contact": { "userEmail": "teamcargoai+PR_SIN@gmail.com" } }],
  "searchUUID": "aaaaaaaa",
  "flightUUID": "bbbbbbbb",
  "provider": false,
  "attachments": [],
  "quoteRequest": {
    "quotes": [],
    "quoteRequestId": 2266,
    "quoteRequestStatus": QuoteRequestStatusEnum.PENDING,
    "transCurrencyAmount": 1,
    "originalQuotePrice": 0,
    "originalQuoteCapacity": 2209,
    "bookingType": BookingTypeEnum.MANUAL_BOOK,
    "comments": "AutoTest CargoAI Matt22/09 13:05",
    "maxDepartureDays": 1,
    "forwarder": {
      "userId": 1,
      "userName": "Forwarder Singapore",
      "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
      "company": {
        "id": 7,
        "companyCode": "Forwarder",
        "companyName": "Forwarder",
        "companyType": "FORWARDER",
      },
      "userStatus": "ACTIVATED",
      "logoPath": "user1.png",
      "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "" },
      "smtp": "",
      "pima": "pimatest",
      "defaultCurrency": "SGD",
    } as any,
    "quoteItems": [
      {
        "quoteItemId": 5253,
        "weight": 2209,
        "dimensionLength": -100,
        "dimensionWidth": -100,
        "dimensionHeight": -1000,
        "numOfItems": 1,
        "weightType": WeightTypeEnum.TOTAL,
        "typeOfProduct": {
          "typeOfProductId": 5,
          "productCode": "GCR",
          "productDescription": "General Cargo",
        },
        "packing": PackingTypeEnum.NONE,
      } as QuoteItem,
    ],
    "isShipmentScreened": false,
    "specialHandlingCodes": [],
  },
  "trips": [
    {
      "id": 96958,
      "endArrivalDate": "2020-09-25T17:00:00+08:00",
      "startDepartureDate": "2020-09-24T19:30:00+08:00",
      "flightNo": "SQ111",
      "flights": [
        {
          "id": 189165,
          "arrivalDate": "2020-09-24T23:10:00+08:00",
          "departureDate": "2020-09-24T19:30:00+08:00",
          "flightNo": "SQ111",
          "originRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "SIN",
            "airportName": "Changi",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1455, "cityCode": "SIN", "cityName": "Singapore" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "destRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "MNL",
            "airportName": "Ninoy Aquino Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "aircraft": {
            "aircraftName": "Unknown Aircraft",
            "aircraftCode": "UNKN",
            "capacity": 50000,
            "aircraftType": {
              "aircraftTypeId": 5,
              "aircraftTypeName": "Unknown",
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "aircrafts": undefined,
            },
          },
        },
      ],
    },
  ],
  "possibleActions": [],
  "originAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "SIN",
    "airportName": "Changi",
    "city": {
      "cityId": 1455,
      "cityCode": "SIN",
      "cityName": "Singapore",
      "state": {
        "country": {
          "countryCode": "SN",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "destinationAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "NRT",
    "airportName": "Narita Intl",
    "city": {
      "cityId": 912,
      "cityCode": "TYO",
      "cityName": "Tokyo",
      "state": {
        "stateId": 1,
        "stateName": "Tokyo",
        "stateCode": "TYO",
        "country": {
          "countryCode": "JP",
          "countryName": "Japan",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "chargeableWeight": 166.0,
  "actualWeight": 1.0,
  "actualVolume": 1.0,
  "trackings": [
    {
      "id": 38,
      "code": "DEP",
      "origin": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },
      "destination": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },

      "location": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },
      "eventDate": new Date(2021, 6, 11, 10, 20, 30, 500),
      "weight": "1",
      "pieces": "1",
      "volume": "1",
      "flightNumber": "SQ111",
      "carbonEmission": "10",
    } as Tracking,
    {
      "id": 39,
      "code": "ARR",
      "origin": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },
      "destination": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },

      "location": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "countryCode": "",
        "timezone": "Asia/Tokyo",
        "cityName": "TOkyo",
        "cityCode": "NRT",
      },
      "eventDate": new Date(2021, 6, 12, 10, 20, 30, 500),
      "weight": "1",
      "pieces": "1",
      "volume": "1",
      "flightNumber": "SQ111",
      "carbonEmission": "10",
    } as Tracking,
  ],
  "airlineConditions": "",
  "salesConditions": "",
  "trackTraceSubscribe": false,
} as any);
