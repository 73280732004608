import {
  BookingTypeEnum,
  FlightChargeTypeEnum,
  FlightRateTypeEnum,
  LoadTypeEnum,
  MeasurementSystemEnum,
  PackingTypeEnum,
  QuoteRequestStatusEnum,
  QuoteStatusEnum,
  UserTypeEnum,
  WeightTypeEnum,
} from "@cai-services";
import { Observable, of } from "rxjs";
import { Country } from "../../core/_models/country.model";
import { FlightInput, Leg } from "../../core/_models/flight-input.model";
import { Forwarder } from "../../core/_models/forwarder.model";
import { Office } from "../../core/_models/office.model";
import { QuoteItem } from "../../core/_models/quote-item.model";
import { QuoteWithErrors } from "../../core/_models/quote.model";
import { AppQuoteService } from "../../core/_services/app-quote.service";
import { QuoteListService } from "../../core/_services/quote-list/quote-list.service";
import { QuotecartService } from "../../core/_services/quotecart.service";
import { ViewQuoteRequestService } from "../../core/_services/view-quote-request.service";

export const mockedQuote = {
  "actions": [],
  "quoteId": 6975,
  "skyPalletID": null,
  "quoteStatus": QuoteStatusEnum.PENDING_AIRLINE,
  "quoteDate": new Date(1600751162516),
  "awb": "15711112222",
  "awbType": null,
  "awbDisabled": false,
  "maxAttachmentCount": 3,
  "maxAttachmentTotalSize": 9000000,
  "isViewForwarder": true,
  "internalRateUpdatedDate": new Date(),
  "isViewAirline": false,
  "quoteCurrency": "SGD",
  "rateDetail": {
    "currency": "SGD",
    "allInRate": 5.432530120481927,
    "total": 901.8,
    "weightType": "CHARGEABLE_WEIGHT",
    "rateSurcharges": [],
    "ocdc": [
      {
        "code": "sco",
        "label": "Surface charges origin",
        "rate": 3.2,
        "type": FlightChargeTypeEnum.SHIPMENT,
      },
      {
        "code": "dgf",
        "label": "Dangerous goods fee",
        "rate": 0.59,
        "type": FlightChargeTypeEnum.GROSS_WEIGHT,
      },
    ],
    "netRate": 5.432530120481927,
    "rateType": "NET_RATE",
    "rate": 5.432530120481927,
  },
  "airlineBrand": {
    "airlineCompanyId": 29,
    "airlineCompanyName": "Philippine Airlines",
    "airlineCompanyCode": "PR",
    "mainAirlineLogoPath":
      "https://forwarders.cargoai.co/assets/media/airlinesLogo/PR.png",
    "mainWebsiteUrl":
      "http://cargoserv.champ.aero/tracking.asp?Carrier=PR&amp;Pfx=079",
    "awbPrefix": "079",
    "generalConditions": "",
    "company": {
      "id": 1,
      "companyCode": "PR",
      "companyName": "Philippine  Airlines",
      "companyType": "AIRLINE",
      "users": [],
      "airlineBrands": [],
    },
    "emailToConfirms": undefined,
    "negoRoutes": undefined,
    "routes": undefined,
  },
  "lastUpdatedDate": new Date(1600751162516),
  "isRate": false,
  "minWeight": 1767,
  "maxWeight": 3314,
  "maxDensity": 20,
  "validity": new Date(1600923965000),
  "contacts": [{ "contact": { "userEmail": "teamcargoai+PR_SIN@gmail.com" } }],
  "searchUUID": "aaaaaaaa",
  "flightUUID": "bbbbbbbb",
  "provider": false,
  "attachments": [],
  "quoteRequest": {
    "measurementSystem": MeasurementSystemEnum.METRIC,
    "quotes": [],
    "quoteRequestId": 2266,
    "quoteRequestStatus": QuoteRequestStatusEnum.PENDING,
    "transCurrencyAmount": 1,
    "originalQuotePrice": 5.432530120481927,
    "originalQuoteCapacity": 2209,
    "bookingType": BookingTypeEnum.MANUAL_BOOK,
    "comments": "AutoTest CargoAI Matt22/09 13:05",
    "maxDepartureDays": 1,
    "forwarder": {
      "userId": 1,
      "userName": "Forwarder Singapore",
      "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
      "userStatus": "ACTIVATED",
      "company": {
        "id": 7,
        "companyCode": "Forwarder",
        "companyName": "Forwarder",
        "companyType": "FORWARDER",
      },
      "logoPath": "user1.png",
      "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "" },
      "smtp": "",
      "pima": "pimatest",
      "defaultCurrency": "SGD",
    } as any,
    "quoteItems": [
      {
        "quoteItemId": 5253,
        "weight": 2209,
        "dimensionLength": -100,
        "dimensionWidth": -100,
        "dimensionHeight": -1000,
        "numOfItems": 1,
        "weightType": WeightTypeEnum.TOTAL,
        "typeOfProduct": {
          "typeOfProductId": 5,
          "productCode": "GCR",
          "productDescription": "General Cargo",
        },
        "packing": PackingTypeEnum.NONE,
        "loadType": LoadTypeEnum.TOTAL,
        "volume": null,
        "uldType": null,
      } as QuoteItem,
    ],
    "forwarderOffice": {
      "country": {
        "countryName": "Singapore",
      } as Country,
      "airports": [{ "airportCode": "SIN" }],
    } as Office,
    "isShipmentScreened": false,
    "specialHandlingCodes": [],
  },
  "trips": [
    {
      "id": 96958,
      "endArrivalDate": "2020-09-25T17:00:00+08:00",
      "startDepartureDate": "2020-09-24T19:30:00+08:00",
      "flightNo": "TBC",
      "flights": [
        {
          "id": 189165,
          "arrivalDate": "2020-09-24T23:10:00+08:00",
          "departureDate": "2020-09-24T19:30:00+08:00",
          "flightNo": "TBC",
          "originRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "SIN",
            "airportName": "Changi",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1455, "cityCode": "SIN", "cityName": "Singapore" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "destRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "MNL",
            "airportName": "Ninoy Aquino Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "aircraft": {
            "aircraftName": "Unknown Aircraft",
            "aircraftCode": "UNKN",
            "capacity": 50000,
            "aircraftType": {
              "aircraftTypeId": 5,
              "aircraftTypeName": "Unknown",
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "aircrafts": undefined,
            },
          },
        },
        {
          "id": 189166,
          "arrivalDate": new Date(1601053200000),
          "departureDate": new Date(1601039400000),
          "flightNo": "TBC",
          "originRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "MNL",
            "airportName": "Ninoy Aquino Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "destRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "NRT",
            "airportName": "Narita Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 912, "cityCode": "TYO", "cityName": "Tokyo" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "aircraft": {
            "aircraftName": "Unknown Aircraft",
            "aircraftCode": "UNKN",
            "capacity": 50000,
            "aircraftType": {
              "aircraftTypeId": 5,
              "aircraftTypeName": "Unknown",
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "aircrafts": undefined,
            },
          },
          "quoteList": [],
        },
      ],
    },
  ],
  "possibleActions": [],
  "originAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "SIN",
    "airportName": "Changi",
    "city": {
      "cityId": 1455,
      "cityCode": "SIN",
      "cityName": "Singapore",
      "state": {
        "country": {
          "countryCode": "SN",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "destinationAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "NRT",
    "airportName": "Narita Intl",
    "city": {
      "cityId": 912,
      "cityCode": "TYO",
      "cityName": "Tokyo",
      "state": {
        "stateId": 1,
        "stateName": "Tokyo",
        "stateCode": "TYO",
        "country": {
          "countryCode": "JP",
          "countryName": "Japan",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "chargeableWeight": 166.0,
  "actualWeight": 1.0,
  "actualVolume": 1.0,
  "trackings": [],
  "airlineConditions": "",
  "salesConditions": "",
  "trackTraceSubscribe": false,
  "apiContacts": ["textContact@email.com"],
} as any;

export const mockedQuoteBup = {
  "actions": [],
  "quoteId": 6975,
  "skyPalletID": null,
  "quoteStatus": QuoteStatusEnum.PENDING_AIRLINE,
  "quoteDate": new Date(1600751162516),
  "awb": "15711112222",
  "awbType": null,
  "awbDisabled": false,
  "isViewForwarder": true,
  "internalRateUpdatedDate": new Date(),
  "isViewAirline": false,
  "quoteCurrency": "SGD",
  "rateDetail": {
    "currency": "EUR",
    "allInRate": 5.3999999999999995,
    "total": 901.8,
    "weightType": "CHARGEABLE_WEIGHT",
    "rateSurcharges": [],
    "netRate": 5,
    "rateType": "NET_RATE",
    "rate": 5,
  },
  "airlineBrand": {
    "airlineCompanyId": 29,
    "airlineCompanyName": "Philippine Airlines",
    "airlineCompanyCode": "PR",
    "mainAirlineLogoPath":
      "https://forwarders.cargoai.co/assets/media/airlinesLogo/PR.png",
    "mainWebsiteUrl":
      "http://cargoserv.champ.aero/tracking.asp?Carrier=PR&amp;Pfx=079",
    "awbPrefix": "079",
    "generalConditions": "",
    "company": {
      "id": 1,
      "companyCode": "PR",
      "companyName": "Philippine  Airlines",
      "companyType": "AIRLINE",
      "users": [],
      "airlineBrands": [],
    },
    "emailToConfirms": undefined,
    "negoRoutes": undefined,
    "routes": undefined,
  },
  "lastUpdatedDate": new Date(1600751162516),
  "isRate": false,
  "minWeight": 1767,
  "maxWeight": 3314,
  "maxDensity": 20,
  "validity": new Date(1600923965000),
  "contacts": [{ "contact": { "userEmail": "teamcargoai+PR_SIN@gmail.com" } }],
  "searchUUID": "aaaaaaaa",
  "flightUUID": "bbbbbbbb",
  "provider": false,
  "attachments": [],
  "quoteRequest": {
    "measurementSystem": MeasurementSystemEnum.METRIC,
    "quotes": [],
    "quoteRequestId": 2266,
    "quoteRequestStatus": QuoteRequestStatusEnum.PENDING,
    "transCurrencyAmount": 1,
    "originalQuotePrice": 0,
    "originalQuoteCapacity": 2209,
    "bookingType": BookingTypeEnum.MANUAL_BOOK,
    "comments": "AutoTest CargoAI Matt22/09 13:05",
    "maxDepartureDays": 1,
    "forwarder": {
      "userId": 1,
      "userName": "Forwarder Singapore",
      "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
      "userStatus": "ACTIVATED",
      "company": {
        "id": 7,
        "companyCode": "Forwarder",
        "companyName": "Forwarder",
        "companyType": "FORWARDER",
      },
      "logoPath": "user1.png",
      "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "" },
      "smtp": "",
      "pima": "pimatest",
      "defaultCurrency": "SGD",
    } as any,
    "quoteItems": [
      {
        "quoteItemId": 5253,
        "weight": 2209,
        "weightType": WeightTypeEnum.TOTAL,
        "typeOfProduct": {
          "typeOfProductId": 5,
          "productCode": "GCR",
          "productDescription": "General Cargo",
        },
        "packing": PackingTypeEnum.NONE,
        "loadType": LoadTypeEnum.BUP,
        "volume": 750,
        "uldType": "AKE",
        "numOfItems": 1,
      } as QuoteItem,
    ],
    "forwarderOffice": {
      "country": {
        "countryName": "Singapore",
      } as Country,
      "airports": [{ "airportCode": "SIN" }],
    } as Office,
    "isShipmentScreened": false,
    "specialHandlingCodes": [],
  },
  "trips": [
    {
      "id": 96958,
      "endArrivalDate": "2020-09-25T17:00:00+08:00",
      "startDepartureDate": "2020-09-24T19:30:00+08:00",
      "flightNo": "TBC",
      "flights": [
        {
          "id": 189165,
          "arrivalDate": "2020-09-24T23:10:00+08:00",
          "departureDate": "2020-09-24T19:30:00+08:00",
          "flightNo": "TBC",
          "originRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "SIN",
            "airportName": "Changi",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1455, "cityCode": "SIN", "cityName": "Singapore" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "destRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "MNL",
            "airportName": "Ninoy Aquino Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "aircraft": {
            "aircraftName": "Unknown Aircraft",
            "aircraftCode": "UNKN",
            "capacity": 50000,
            "aircraftType": {
              "aircraftTypeId": 5,
              "aircraftTypeName": "Unknown",
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "aircrafts": undefined,
            },
          },
        },
        {
          "id": 189166,
          "arrivalDate": new Date(1601053200000),
          "departureDate": new Date(1601039400000),
          "flightNo": "TBC",
          "originRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "MNL",
            "airportName": "Ninoy Aquino Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "destRegionAirport": {
            "regionCode": "ASPAC",
            "regionName": "Asia Pacific",
            "airportCode": "NRT",
            "airportName": "Narita Intl",
            "timezone": "Asia/Singapore",
            "city": { "cityId": 912, "cityCode": "TYO", "cityName": "Tokyo" },
            "destFlights": undefined,
            "originFlights": undefined,
          },
          "aircraft": {
            "aircraftName": "Unknown Aircraft",
            "aircraftCode": "UNKN",
            "capacity": 50000,
            "aircraftType": {
              "aircraftTypeId": 5,
              "aircraftTypeName": "Unknown",
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "aircrafts": undefined,
            },
          },
          "quoteList": [],
        },
      ],
    },
  ],
  "possibleActions": [],
  "originAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "SIN",
    "airportName": "Changi",
    "city": {
      "cityId": 1455,
      "cityCode": "SIN",
      "cityName": "Singapore",
      "state": {
        "country": {
          "countryCode": "SN",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "destinationAirport": {
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "airportCode": "NRT",
    "airportName": "Narita Intl",
    "city": {
      "cityId": 912,
      "cityCode": "TYO",
      "cityName": "Tokyo",
      "state": {
        "stateId": 1,
        "stateName": "Tokyo",
        "stateCode": "TYO",
        "country": {
          "countryCode": "JP",
          "countryName": "Japan",
        },
      },
    },
    "destFlights": undefined,
    "originFlights": undefined,
  },
  "chargeableWeight": 166.0,
  "actualWeight": 1.0,
  "actualVolume": 1.0,
  "trackings": [],
  "airlineConditions": "",
  "salesConditions": "",
  "trackTraceSubscribe": false,
} as any;

export const mockedQuoteValidity = {
  "minWeight": mockedQuote.minWeight,
  "maxWeight": mockedQuote.maxWeight,
  "maxDensity": mockedQuote.maxDensity,
  "fromValidity": mockedQuote.fromValidity,
  "validity": mockedQuote.validity,
  "possibleActions": mockedQuote.possibleActions,
};

export const mockedQuoteRequest = {
  "quotes": [
    {
      "quoteId": 6975,
      "skyPalletID": null,
      "quoteStatus": QuoteStatusEnum.PENDING_AIRLINE_INFORMATION,
      "quoteDate": new Date(1600751162516),
      "awb": "15711112222",
      "awbType": null,
      "awbDisabled": false,
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
      "isViewForwarder": true,
      "isViewAirline": false,
      "quoteCurrency": "SGD",
      "lastUpdatedDate": new Date(1600751162516),
      "isRate": false,
      "minWeight": 1767,
      "maxWeight": 3314,
      "maxDensity": 20,
      "validity": new Date(1600923965000),
      "contacts": [{ "contact": { "userEmail": "teamcargoai+PR_SIN@gmail.com" } }],
      "searchUUID": "aaaaaaaa",
      "flightUUID": "bbbbbbbb",
      "provider": false,
    },
  ],
  "measurementSystem": MeasurementSystemEnum.METRIC,
  "quoteRequestId": 2266,
  "quoteRequestStatus": QuoteRequestStatusEnum.PENDING,
  "transCurrencyAmount": 1,
  "originalQuotePrice": 0,
  "originalQuoteCapacity": 2209,
  "bookingType": BookingTypeEnum.SEARCH_BOOK,
  "comments": "AutoTest CargoAI Matt22/09 13:05",
  "maxDepartureDays": 1,
  "pdfCount": 0,
  "forwarder": {
    "userId": 1,
    "userName": "Forwarder Singapore",
    "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
    "userStatus": "ACTIVATED",
    "logoPath": "user1.png",
    "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "" },
    "smtp": "",
    "pima": "pimatest",
    "defaultCurrency": "SGD",
  } as Forwarder,
  "quoteItems": [],
  "isShipmentScreened": false,
  "specialHandlingCodes": [],
};

export const mockedSelectedFlight: FlightInput[] = [
  {
    "flightUUID": "a3ca2e0f-3cd4-11ed-bca5-2ae8e29fbe55",
    "source": "CW-FLR",
    "arrivalTime": "2022-09-26T08:50:00+09:00",
    "arrivalAirport": "NRT",
    "airlineCode": "NH",
    "departureTime": "2022-09-26T00:45:00+08:00",
    "departureAirport": "SIN",
    "latestAcceptanceTime": "0001-01-01T00:00:00Z",
    "timeOfAvailability": "0001-01-01T00:00:00Z",
    "handlingInfoLink": "https://www.cargoai.co/terms",
    "flightNumber": "NH8412",
    "sortingFlightKey": "NH84122022-09-26",
    "loading": false,
    "available": true,
    "availableReason": null,
    "awbPrefix": null,
    "features": {
      "equote": null,
      "bookable": true,
      "instantBookable": null,
      "awbOptional": null,
      "awbDisabled": null,
      "eap": null,
      "eaw": null,
      "allotment": null,
      "awbCommentDisabled": null,
      "dimensionDetails": null,
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
      "mailQuotable": true,
      "walletConfig": "ALL",
    },
    "legs": [
      {
        "flightNumber": "NH8412",
        "arrivalTime": "2022-09-26T08:50:00+09:00",
        "arrivalAirport": "NRT",
        "departureTime": "2022-09-26T00:45:00+08:00",
        "departureAirport": "SIN",
        "bodyType": "Widebody",
        "aircraftCode": "763",
        "co2": {
          "value": 0.9341583055703184,
          "estimation": false,
        },
      },
    ] as Leg[],
    "rates": [
      {
        "id": "1",
        "name": "",
        "type": FlightRateTypeEnum.MARKET_RATE,
        "currency": "SGD",
        "netRate": 2.45,
        "allInRate": 2.7500000000000004,
        "total": 459.25000000000006,
        "minimumRate": false,
        "charges": [
          {
            "code": "fsc",
            "label": "Fuel surcharge",
            "type": FlightChargeTypeEnum.CHARGEABLE_WEIGHT,
            "rate": 0.1,
            "minAmount": 0,
          },
          {
            "code": "ssc",
            "label": "Security surcharge",
            "type": FlightChargeTypeEnum.CHARGEABLE_WEIGHT,
            "rate": 0.2,
            "minAmount": 0,
          },
        ],
        "lastUpdatedDate": "2022-06-16T16:19:14.000+00:00",
        "isManualRate": true,
        "formattedName": "Market Rate",
      },
    ],
    "airlineConditions": null,
    "salesConditions": null,
    "contacts": null,
    "checked": true,
    "selectedRate": {
      "id": "1",
      "name": "",
      "type": FlightRateTypeEnum.MARKET_RATE,
      "currency": "SGD",
      "netRate": 2.45,
      "allInRate": 2.7500000000000004,
      "total": 459.25000000000006,
      "minimumRate": false,
      "charges": [
        {
          "code": "fsc",
          "label": "Fuel surcharge",
          "type": FlightChargeTypeEnum.CHARGEABLE_WEIGHT,
          "rate": 0.1,
          "minAmount": 0,
        },
        {
          "code": "ssc",
          "label": "Security surcharge",
          "type": FlightChargeTypeEnum.CHARGEABLE_WEIGHT,
          "rate": 0.2,
          "minAmount": 0,
        },
      ],
      "lastUpdatedDate": "2022-06-16T16:19:14.000+00:00",
      "isManualRate": true,
      "formattedName": "Market Rate",
    },
    "disabled": false,
  } as FlightInput,
];

export const quoteListServiceStub: Partial<QuoteListService> = {
  "retrieveQuote": (quoteId, token) => new Observable((ob) => {
      setTimeout(() => {
        ob.next(mockedQuote);
        ob.complete();
      });
    }),
  "updateQuoteStatus": (newQuote) => new Observable((ob) => {
      setTimeout(() => {
        ob.next({ "quote": newQuote, "errors": [] } as QuoteWithErrors);
        ob.complete();
      });
    }),
  "retrieveAllQuotesByUser": () => new Observable((ob) => {
      setTimeout(() => {
        ob.next([]);
        ob.complete();
      });
    }),
  "archiveQuoteRequest": () => of({}),
};

export const quoteCartServiceStub: Partial<QuotecartService> = {
  "createNewQuoteRequest": () =>
    new Observable((ob) => {
      ob.next(mockedQuoteRequest as any);
      ob.complete();
    }),
};

export const quoteRequestServiceStub: Partial<ViewQuoteRequestService> = {
  "retrieveQuoteRequestByForwarder": () =>
    new Observable((ob) => {
      ob.next({ "content": [] });
      ob.complete();
    }),
  "retrieveQuoteByQuoteId": () =>
    new Observable((ob) => {
      ob.next(mockedQuote);
      ob.complete();
    }),
  "updateQuoteRequestRating": (br) =>
    new Observable((ob) => {
      ob.next();
      ob.complete();
    }),
};

export const appQuoteServicesStub: Partial<AppQuoteService> = {
  "getQuotes": () => new Observable((ob) => {
      ob.next({ "content": [mockedQuote] });
      ob.complete();
    }),
  "retrieveQuoteById": () => new Promise((resolve) => {
      resolve(mockedQuote);
    }),
  "getShipmentSummaryAsPDF": () => new Observable((ob) => {
      ob.next({ "link": { "link": "/shipment-summary.pdf" } });
      ob.complete();
    }),
  "deleteQuoteAttachment": () => new Promise((resolve, reject) => {
      resolve(null);
    }),
  "generateUploadUrlForQuoteAttachment": () => new Promise((resolve, reject) => {
      resolve(null);
    }),
  "generateDownloadUrlForQuoteAttachment": () => Promise.resolve(null),
  "getSubscribedEmails": () => new Promise((resolve, reject) => {
      resolve(null);
    }),
  "getFwbDetails": () => new Promise((resolve, reject) => {
      resolve(null);
    }),
};

export const mockedAction = {
  "actionId": 0,
  "actionDate": new Date("2022-08-10T16:41:00.000+02:00"),
  "actionDetails": "has updated flight route",
  "isUserLogin": true,
  "userType": UserTypeEnum.AIRLINE,
  "user": undefined,
  "quote": mockedQuote,
  "actionNames": [],
};
