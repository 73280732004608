import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { SessionService } from "../../../core/_services/session.service";
import { RegisterService } from "../../../core/_services/register.service";
import { IataCassFormatterPipe } from "../../../core/_base/layout/pipes/iata-cass-formatter.pipe";
import { IntercomService } from "../../../core/_services/intercom.service";
import { Forwarder } from "../../../core/_models/forwarder.model";
import { environment } from "../../../environments";
import { BrandIconUtil } from "../../../utils";
import { ApplicationProperties } from "../../../core/_base/layout/models/app-properties.model";
import { APP_PROPS } from "../../../cai-common.module";

@Component({
  "selector": "kt-information-registration",
  "templateUrl": "./information-registration.component.html",
  "styleUrls": ["./information-registration.component.scss"],
  "providers": [IataCassFormatterPipe],
})
export class InformationRegistrationComponent implements OnInit, AfterViewInit {
  registerForm: FormGroup;
  user: Forwarder;
  token: string;
  success: boolean;
  confirmation: boolean;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private sessionService: SessionService,
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private iataCassFormatter: IataCassFormatterPipe,
    private readonly cdr: ChangeDetectorRef,
    private readonly intercomService: IntercomService
  ) {}

  async ngOnInit () {
    this.token = this.route.snapshot.queryParamMap.get("token");
    try {
      this.user = (await this.registerService.getUser(this.token)) as Forwarder;
    } catch (e) {
      this.router.navigateByUrl(`/register/invalid?token=${this.token}`);
    }
    this.initRegisterForm();
    this.cdr.detectChanges();
    if (this.route.snapshot.queryParamMap.get("success")) {
      this.success = true;
    }
  }
  ngAfterViewInit () {
    if (this.route.snapshot.queryParamMap.get("success") == "true") {
      this.success = true;
    }
  }
  onNoIataCassChange (): void {
    this.registerForm.controls.iataCass.setValue("");
    this.cdr.detectChanges();
  }

  onRegisterClick (): void {
    this.user.company.companyCode =
      this.registerForm.controls.companyCode.value;
    this.user.linkedInProfile = this.registerForm.controls.linkedin.value;
    const iataCass = this.registerForm.controls.iataCass.value as string;
    if (
      !(
        this.registerForm.controls.noIataCass &&
        this.registerForm.controls.noIataCass.value
      )
    ) {
      this.user.iataCode = iataCass.split("-")[0];
      this.user.cassCode = iataCass.split("-")[1];
    }
    this.user.noIataCass = this.registerForm.controls.noIataCass.value;
    this.registerService.updateForwarder(this.user, this.token).subscribe(
      (forwarder) => {
        this.sessionService.setCurrentUser(forwarder);
        this.updateIntercomData(forwarder);
        this.success = true;
        this.router.navigate([], {
          "relativeTo": this.route,
          "queryParams": {
            "success": "true",
          },
          "queryParamsHandling": "merge",
          // preserve the existing query params in the route
          "skipLocationChange": false,
          // do not trigger navigation
        });
        this.cdr.detectChanges();
      },
      (e) => {
        console.error("cannot updateForwarder: ", e);
        Swal.fire(
          "",
          $localize`:@@registration.user.error:Something went wrong while registering, please contact support`,
          "error"
        );
      }
    );
  }

  updateIntercomData (user) {
    const intercomSetting = {
      "app_id": environment.intercomAppId,
      "email": user.userEmail,
      "short_company_name": user.company.companyCode,
      "FORWARDER_IATA_CASS": user.iataCode
        ? `${user.iataCode}-${user.cassCode}`
        : undefined,
      "personal_linkedin_profile": user.linkedInProfile,
      "Account_Activation_status": user.userStatus,
    };
    this.intercomService.update(intercomSetting);
  }

  onBackClick (): void {
    this.router.navigateByUrl(`/register/forwarder?token=${this.token}`);
  }

  onNextClick (): void {
    this.router.navigateByUrl("/auth/login");
  }

  onIataCassPaste (): void {
    const iataCassControl = this.registerForm.controls.iataCass;
    setTimeout(() => {
      iataCassControl.setValue(
        this.iataCassFormatter.transform(iataCassControl.value)
      );
      this.cdr.detectChanges();
    }, 300);
  }

  private initRegisterForm (): void {
    this.registerForm = this.fb.group({
      "companyName": new FormControl(
        { "value": this.user.company.companyName, "disabled": true },
        Validators.required
      ),
      "companyCode": [
        this.user.company.companyCode,
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      "linkedin": [this.user.linkedInProfile],
      "noIataCass": [false, Validators.compose([])],
      "iataCass": [
        `${
          this.user.iataCode
            ? `${this.user.iataCode}-${
                this.user.cassCode ? this.user.cassCode : ""
              }`
            : ""
        }`,
        Validators.compose([Validators.pattern("^[0-9]{7}-[0-9]{4}$")]),
      ],
    });
  }

  isControlHasError (controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  get backgroundImage (): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      "/assets/media/logos/"
    );
  }

  get isCargoMart (): boolean {
    return this.appProperties.name === "cargomart";
  }

  get isCargoWallet (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
