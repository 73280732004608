import { ULDTypeEnum, WeightTypeEnum } from "@cai-services";
import { BUP } from "../../core/_models/bup.model";
import { MeasurementUnits } from "../../core/_models/measurement-system.model";

const mockedBUPs = [
  {
    "uldType": ULDTypeEnum.AKE,
    "quantity": 1,
    "weight": 10,
    "weightType": WeightTypeEnum.PER_ITEM,
    "weightUnit": MeasurementUnits.METRIC.WEIGHT.display,
    "volume": 4.3,
    "volumeUnit": MeasurementUnits.METRIC.VOLUME.display,
    "isNewRow": false,
    "isTouched": true,
    "isFocused": false,
    "errors": [],
  } as BUP,
];
export { mockedBUPs };
