import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { IOfficeInvitation, OfficeService } from '@cai-services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { noLettersValidator } from '../../../validators/no-letters.validator';
import { phoneNumberValidator } from '../../../validators/phone-number.validator';
import { REGEXP_PASSWORD } from '../../../core/_constants/constants';
import { SessionService } from '../../../core/_services/session.service';
import { IntlTelFormatEnum } from '../../../core/_enums/intl-tel-format.enum';
import { APP_PROPS } from '../../../cai-common.module';
import { ApplicationProperties } from '../../../core';

@Component({
  selector: 'cai-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss'],
})
export class AcceptInvitationComponent implements OnInit {
  @Input()
  invitationId: string;
  @Input()
  backgroundImage: string;

  invitation: IOfficeInvitation;
  acceptInvitationForm: FormGroup;
  isLoading = false;
  phoneMask = '012-345-6789';
  intlTelInput: any;
  steps: string[] = [
    $localize`:@@registration.invite-sent:Invite Sent`,
    $localize`:@@registration.verify-details:Verify Details`,
  ];

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly ref: ChangeDetectorRef,
    private readonly officeService: OfficeService,
    private readonly fb: FormBuilder,
    private readonly sessionService: SessionService,
    private readonly router: Router,
  ) {
    this.initializeAcceptInvitationForm();
  }

  ngOnInit() {
    this.initializeInvitation();
  }

  private async initializeInvitation(): Promise<void> {
    try {
      this.invitation = await this.officeService.getInvitation(
        this.invitationId,
      );
    } catch {
      await Swal.fire(
        '',
        $localize`:@@registration.error.invite-expired:This invitation is invalid or expired. Redirecting to the login page.`,
        'error',
      );
      this.router.navigateByUrl('/auth/login');
    }
    await this.initIntlTelInput();
    this.acceptInvitationForm.controls.phoneNumber.addValidators(
      phoneNumberValidator(this.intlTelInput),
    );
    this.acceptInvitationForm.updateValueAndValidity();
  }

  private initializeAcceptInvitationForm() {
    this.acceptInvitationForm = this.fb.group({
      firstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          noLettersValidator,
        ]),
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          noLettersValidator,
        ]),
      ],
      phoneNumber: ['', Validators.compose([Validators.required])],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(REGEXP_PASSWORD),
        ]),
      ],
      device: [this.sessionService.getBrowserKey()],
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.acceptInvitationForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  async initIntlTelInput(): Promise<void> {
    return new Promise((resolve) => {
      const input = document.querySelector('#phone-number') as HTMLInputElement;
      this.intlTelInput = intlTelInput(input, {
        separateDialCode: true,
        utilsScript: '/assets/utils.js',
        dropdownContainer: document.body,
      });
      this.intlTelInput.promise;
      resolve();
      this.ref.detectChanges();
    });
  }

  async onAcceptInvitation(): Promise<void> {
    try {
      await this.officeService.acceptInvitation(this.invitationId, {
        firstName: this.acceptInvitationForm.controls.firstName.value,
        lastName: this.acceptInvitationForm.controls.lastName.value,
        contact: {
          countryCode: this.intlTelInput?.getSelectedCountryData()?.iso2,
          phoneNumber: this.intlTelInput?.getNumber(
            IntlTelFormatEnum.INTERNATIONAL,
          ),
        },
        password: this.acceptInvitationForm.controls.password.value,
        device: this.acceptInvitationForm.controls.device.value,
      });
      await Swal.fire({
        text: $localize`:@@registration.success.accept-invitation:Success! You may now log in.`,
        icon: 'success',
        showCancelButton: false,
        allowOutsideClick: false,
      });
      this.router.navigateByUrl('/auth/login');
    } catch {
      await Swal.fire(
        '',
        $localize`:@@registration.error.accept-invitation:Something went wrong while registering, please contact support`,
        'error',
      );
    }
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }
}
