import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  SimpleChanges,
  Output,
  EventEmitter, OnChanges,
} from "@angular/core";
import { FormArray, FormControl, FormBuilder } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { CdkScrollableModule, ScrollingModule } from "@angular/cdk/scrolling";
import { cloneDeep } from "lodash";

// import { any } from '../../../../../config/_models/office-autocomplete-item.model';

@Component({
  "standalone": true,
  "selector": "common-chip-autocomplete",
  "templateUrl": "./chip-autocomplete.component.html",
  "styleUrls": ["./chip-autocomplete.component.scss"],
  "imports": [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatOptionModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    CdkScrollableModule,
    ScrollingModule,
  ],
})
export class CommonChipComponent implements OnInit, OnChanges {
  @Input() formArray: FormArray;
  @Input() name: string;
  @Input() anchor: string;
  @Input() dictionary: any[];
  @Input() idName: string;
  @Input() autocompleteItems: any[];
  @Input() maxSize: string;
  @Input() disabled: boolean;
  @Output()
  handleChange = new EventEmitter<boolean>();
  @ViewChild("itemInput", { "static": false })
  itemInput: ElementRef<HTMLInputElement>;
  inputFormControl: FormControl;
  groupedFilteredOptions: any[];
  localAutoCompleteItem: any[];

  constructor (private fb: FormBuilder) {}

  ngOnInit () {
    this.inputFormControl = this.fb.control({}, []);
  }

  private groupFilteredOptions (): any[] {
    const groupedFilteredOptions = [];

    this.localAutoCompleteItem?.forEach((item) => {
      const groupName = item.group ?? "",
       groupWithSameName = groupedFilteredOptions.find(
        (group) => group.name === groupName,
      );
      if (groupWithSameName === undefined) {
        const newGroup = {
          "name": groupName,
          "iconUrl": item.groupIconUrl,
          "options": [item],
        };
        groupedFilteredOptions.push(newGroup);
      } else {
        groupWithSameName.options.push(item);
      }
    });
    return groupedFilteredOptions;
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("autocompleteItems")) {
      this.localAutoCompleteItem = cloneDeep(this.autocompleteItems);
      const selectedValues = this.formArray.value?.map(
        (val) => val?.airportCode,
      );

      this.localAutoCompleteItem = this.autocompleteItems?.filter(
        (item) => !selectedValues?.includes(item?.value),
      );
      this.groupedFilteredOptions = this.groupFilteredOptions();
    }
  }

  getItemName (selectedItem: any): string {
    return this.autocompleteItems.find((item) => item?.value === selectedItem?.airportCode)?.value;
  }

  getChipColor (selectedItem: any) {
    if (this.name === "Airports") {
      const index = this.formArray.value.findIndex(
        (item) => item[this.idName] === selectedItem[this.idName],
      ),
       airport = this.dictionary.find(
        (item) =>
          item.airportCode === this.formArray.controls[index].value.airportCode,
      );
      return airport.city.state.country.countryCode === this.anchor
        ? null
        : "matchip-warn";
    } else {
      return null;
    }
  }

  onItemRemove (selectedItem: any): void {
    const index = this.formArray.value.findIndex(
      (item) => item[this.idName] === selectedItem[this.idName],
    );

    if (index >= 0) {
      this.formArray.controls.splice(index, 1);
      this.formArray.value.splice(index, 1);
    }
    this.formArray.markAsDirty();

    const selectedValues = this.formArray.value?.map((val) => val?.airportCode);

    this.localAutoCompleteItem = this.autocompleteItems?.filter(
      (item) => !selectedValues?.includes(item?.value),
    );
    this.groupedFilteredOptions = this.groupFilteredOptions();

    this.handleChange.emit(true);
  }

  handleInputChange (event) {
    if (typeof event === "string") {
      this.localAutoCompleteItem = this.filterItems(event);
      this.groupedFilteredOptions = this.groupFilteredOptions();
    } else {
      this.localAutoCompleteItem = cloneDeep(this.autocompleteItems);
      this.groupedFilteredOptions = this.groupFilteredOptions();
    }
  }

  onItemBlur () {
    const inputValue = this.formArray.value;
    if (typeof inputValue !== "string" || inputValue === "") {
      return;
    }

    const items = this.filterItems(inputValue);
    if (items.length > 0) {
      this.addItem(items[0]);
    } else {
      this.inputFormControl.setValue("");
      this.itemInput.nativeElement.value = "";
    }
  }

  onItemSelected (event: MatAutocompleteSelectedEvent): void {
    const item = this.autocompleteItems.find(
      (option) => option?.label === event.option.viewValue,
    );
    if (item) {
      this.addItem(item);
    }

    const selectedValues = this.formArray.value?.map((val) => val?.airportCode);

    this.localAutoCompleteItem = this.autocompleteItems?.filter(
      (item) => !selectedValues?.includes(item?.value),
    );
    this.groupedFilteredOptions = this.groupFilteredOptions();
  }

  private filterItems (value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.autocompleteItems.filter(
      (selectedItem) =>
        selectedItem.keywords.some(
          (searchValue) =>
            searchValue?.toLowerCase().indexOf(filterValue) === 0,
        ) &&
        !this.formArray.value.find(
          (item) => item?.airportCode === selectedItem?.value,
        ),
    );
  }

  private addItem (item: any) {
    this.formArray.push(this.fb.control(item.object));
    this.inputFormControl.setValue("");
    this.itemInput.nativeElement.value = "";
    this.formArray.markAsDirty();
    this.handleChange.emit(true);
  }

  calculateDropdownHeightPx (): number {
    const groupLabelCount = this.groupedFilteredOptions
      // group labels with no names aren't displayed
      .filter((group) => group.name?.length > 0).length,
     itemCount = this.localAutoCompleteItem?.length;
    return (groupLabelCount + itemCount) * 40;
  }
}
