// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Self,
  ViewChild,
} from "@angular/core";
// Object-Path
import * as objectPath from "object-path";
// Loading bar
import { LoadingBarService } from "@ngx-loading-bar/core";
// HTML Class Service
import { Router } from "@angular/router";
import { AuthenticationMethodEnum, CargoMartPlansType } from "@cai-services";
import moment from "moment";
import { APP_PROPS } from "../../cai-common.module";
import { HtmlClassService } from "../../core/_base/crud/utils/html-class.service";
import { ApplicationProperties } from "../../core/_base/layout/models/app-properties.model";
import { LayoutConfigService } from "../../core/_base/layout/services/layout-config.service";
import { LayoutRefService } from "../../core/_base/layout/services/layout-ref.service";
import { Office } from "../../core/_models/office.model";
import { User } from "../../core/_models/user.model";
import { SessionService } from "../../core/_services/session.service";
import { getDifferenceInDays } from "../../helpers/date-helpers";

@Component({
  "selector": "kt-header",
  "templateUrl": "./header.component.html",
  "styleUrls": ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // Public properties
  menuHeaderDisplay: boolean;
  fluid: boolean;
  currentUser: User;
  @ViewChild("ktHeader", { "static": true }) ktHeader: ElementRef;
  @Input() isWalletApp: boolean;

  /**
   * Component constructor
   *
   * @param router: Router
   * @param layoutRefService: LayoutRefService
   * @param layoutConfigService: LayoutConfigService
   * @param loader: LoadingBarService
   * @param htmlClassService: HtmlClassService
   */
  constructor (
    @Self() private readonly element: ElementRef,
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly cdr: ChangeDetectorRef,
    private sessionService: SessionService,
    private layoutRefService: LayoutRefService,
    private layoutConfigService: LayoutConfigService,
    public loader: LoadingBarService,
    private readonly router: Router,
    public htmlClassService: HtmlClassService
  ) {
    // // page progress bar percentage
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     // set page progress bar loading to start on NavigationStart event router
    //     this.loader.start();
    //   }
    //   if (event instanceof RouteConfigLoadStart) {
    //     this.loader.increment(35);
    //   }
    //   if (event instanceof RouteConfigLoadEnd) {
    //     this.loader.increment(75);
    //   }
    //   if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
    //     // set page progress bar loading to end on NavigationEnd event router
    //     this.loader.complete();
    //   }
    // });
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit (): void {
    const config = this.layoutConfigService.getConfig();

    // get menu header display option
    this.menuHeaderDisplay = objectPath.get(config, "header.menu.self.display");

    // header width fluid
    this.fluid = objectPath.get(config, "header.self.width") === "fluid";

    // animate the header minimize the height on scroll down
    if (
      objectPath.get(config, "header.self.fixed.desktop.enabled") ||
      objectPath.get(config, "header.self.fixed.desktop")
    ) {
      // header minimize on scroll down
      this.ktHeader.nativeElement.setAttribute("data-ktheader-minimize", "1");
    }

    this.sessionService.watchSessionStorage().subscribe((res) => {
      if (res.key === "currentUser") {
        this.currentUser = res.data;
        if (!this.currentUser) {
          this.menuHeaderDisplay = false;
        } else {
          this.menuHeaderDisplay =
            this.menuHeaderDisplay &&
            this.currentUser.authenticationMethod !==
              AuthenticationMethodEnum.TOKEN;
        }
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterViewInit (): void {
    // keep header element in the service
    this.layoutRefService.addElement("header", this.ktHeader.nativeElement);
  }

  handleUpgradeButton (){
    if(this.router.url.includes("settings/office-settings#viewplans")){
      window.location.reload();
      return;
    }
    else if(this.router.url.includes("settings/office-settings")){
      this.router.navigateByUrl("settings/office-settings#viewplans").then(() =>{
        window.location.reload();
      });
      return;
    }
    this.router.navigateByUrl("settings/office-settings#viewplans");
  }

  get trialDays (){
    const selectedOfficeId = this.sessionService.getSelectedOffice().id,
     officeAccess = this.sessionService.getCurrentUser()?.officeAccesses?.find((oa) => oa.office.id == selectedOfficeId),
     office = officeAccess ? officeAccess.office as Office : null,

     expiryDate = moment.utc(office.planExpiryDate).format("yyyy-MM-DDTHH:mm:ssZ"),
     currentDate = moment.utc(new Date()).format("yyyy-MM-DDTHH:mm:ssZ");

    return getDifferenceInDays(currentDate, expiryDate);
  }

  get shouldDisplayTrialHeader (){
    if(this.appProperties.name !== "cargomart"){
      return false;
    }
    const selectedOfficeId = this.sessionService.getSelectedOffice()?.id,
     officeAccess = this.sessionService.getCurrentUser()?.officeAccesses?.find((oa) => oa.office.id == selectedOfficeId),
     office = officeAccess ? officeAccess.office : null;
    return office?.plan === CargoMartPlansType.TRIAL;
  }

  get height (): number {
    return this.element.nativeElement.offsetHeight;
  }
}
