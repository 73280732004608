import { AdhocSearchCard } from "../core/_models/adhoc-search-card.model";
import { AdhocSearchCardEnum } from "../enum/adhoc-search-card.enum";

//Do not change the order
export const AdhocSearchCards: AdhocSearchCard[] = [
  {
    "priority": 0,
    "id": AdhocSearchCardEnum.FLIGHT_CARD,
    "enableSummaryMode": false,
    "summaryMode": false,
    "focused": true,
    "collapsed": false,
    "maxHeight": 0,
    "previousHeight": 0,
  },
  {
    "priority": 3,
    "id": AdhocSearchCardEnum.LOAD_TYPE_CARD,
    "enableSummaryMode": false,
    "summaryMode": false,
    "focused": false,
    "collapsed": false,
    "maxHeight": 0,
    "previousHeight": 0,
  },
  {
    "priority": 2,
    "id": AdhocSearchCardEnum.COMMODITY_CARD,
    "enableSummaryMode": true,
    "summaryMode": false,
    "focused": false,
    "collapsed": false,
    "maxHeight": 0,
    "previousHeight": 0,
  },
  {
    "priority": 1,
    "id": AdhocSearchCardEnum.OPTIONS_CARD,
    "enableSummaryMode": false,
    "summaryMode": false,
    "focused": false,
    "collapsed": false,
    "maxHeight": 0,
    "previousHeight": 0,
  },
];
