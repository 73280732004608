// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
// RxJS
import { Observable, Subscription } from "rxjs";
// Object-Path
import * as objectPath from "object-path";
// User permissions
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { NgxPermissionsService } from "ngx-permissions";
import { APP_PROPS } from "../../cai-common.module";
import { ApplicationProperties } from "../../core";
import { HtmlClassService } from "../../core/_base/crud/utils/html-class.service";
import { HeaderService } from "../../core/_base/layout/services/header.service";
import { LayoutConfigService } from "../../core/_base/layout/services/layout-config.service";
import { MenuConfigService } from "../../core/_base/layout/services/menu-config.service";
import { PageConfigService } from "../../core/_base/layout/services/page-config.service";
import { LayoutConfig } from "../../core/_config/layout.config";
import { PageConfig } from "../../core/_config/page.config";
import { currentUserPermissions } from "../../core/_misc/_selectors/auth.selectors";
import { Permission } from "../../core/_models/permission.model";
import { SessionService } from "../../core/_services/session.service";
import { AppState } from "../../core/reducers";
import { WalletUtil } from "../../utils/wallet.util";

@Component({
  "selector": "kt-base",
  "templateUrl": "./base.component.html",
  "styleUrls": ["./base.component.scss"],
})
export class BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  // Public variables
  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  fluid: boolean;
  isFrench: boolean;
  isShipmentDetailsPage: boolean;
  isEmbedMode: boolean;
  notSupportedBrowser: boolean;
  // Private properties
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private currentUserPermissions$: Observable<Permission[]>;

  @HostListener("window:resize", ["$event"])
  onResize () {
    this.headerService.updateHeight();
  }

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param menuConfigService: MenuConfifService
   * @param pageConfigService: PageConfigService
   * @param htmlClassService: HtmlClassService
   * @param store
   * @param permissionsService
   * @param sessionService: SessionService
   */
  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    public readonly headerService: HeaderService,
    private readonly layoutConfigService: LayoutConfigService,
    private readonly menuConfigService: MenuConfigService,
    private readonly pageConfigService: PageConfigService,
    private readonly htmlClassService: HtmlClassService,
    private readonly store: Store<AppState>,
    private readonly permissionsService: NgxPermissionsService,
    private readonly sessionService: SessionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(async (params) => {
      this.isEmbedMode = WalletUtil.isEmbedMode(this.appProperties, params);
    });
    this.loadRolesWithPermissions();
    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.loadMenu();
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(
      (layoutConfig) => {
        // reset body class based on global and page level layout config, refer to html-class.service.ts
        document.body.className = "";
        this.htmlClassService.setConfig(layoutConfig);
      }
    );
    this.unsubscribe.push(subscr);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit (): void {
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, "self.layout");
    this.asideDisplay = objectPath.get(config, "aside.self.display");
    this.asideSecondary = objectPath.get(
      config,
      "aside-secondary.self.display"
    );
    this.subheaderDisplay = objectPath.get(config, "subheader.display");
    this.fluid = objectPath.get(config, "content.width") === "fluid";

    // let the layout type change
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(
      (cfg) => {
        setTimeout(() => {
          this.selfLayout = objectPath.get(cfg, "self.layout");
        });
      }
    );
    this.unsubscribe.push(subscr);

    this.notSupportedBrowser = this.detectIEEdge() ? true : false;
    this.isFrench = window.location.href?.indexOf("/fr/") >= 0;
    this.isShipmentDetailsPage =
      window.location.href?.indexOf("/shipment-details/") >= 0;
  }

  ngAfterViewInit (): void {
    this.headerService.updateHeight();
    this.cdr.detectChanges();
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  loadMenu (): any {
    const currentUser = this.sessionService.getCurrentUser();
    if (currentUser?.type) {
      const userMenu = this.appProperties.menu.find(
        (config) =>
          currentUser.type === config.userType &&
          config.authenticationMethods.includes(
            currentUser.authenticationMethod
          )
      );
      if (userMenu && !this.isEmbedMode) {
        this.menuConfigService.loadConfigs(userMenu.config.menuItems);
      }
    } else {
      console.warn(`${currentUser?.type || "user type"} is unknown`);
    }
  }

  /**
   * NGX Permissions, init roles
   */
  loadRolesWithPermissions () {
    this.currentUserPermissions$ = this.store.pipe(
      select(currentUserPermissions)
    );
    const subscr = this.currentUserPermissions$.subscribe((res) => {
      if (!res || res.length === 0) {
        return;
      }

      this.permissionsService.flushPermissions();
      res.forEach((pm: Permission) =>
        this.permissionsService.addPermission(pm.name)
      );
    });
    this.unsubscribe.push(subscr);
  }

  detectIEEdge (): number | boolean {
    const ua = window.navigator.userAgent,

     msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    const edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  }

  get otherBrand (): boolean {
    const url = window.location.href;
    return !url.includes("localhost") && !url.split(".")[0].includes("app");
  }

  get isWalletApp (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
