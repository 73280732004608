<div
  class="kt-login__body d-flex flex-column flex-xl-row-reverse align-items-center align-items-xl-stretch"
>
  <!--begin::Signin-->
  <div class="kt-login__form">
    <kt-manual-complete
      *ngIf="success"
      (onNext)="onNextClick()"
    ></kt-manual-complete>
    <div *ngIf="!success" class="registration-card d-flex flex-column">
      <div>
        <div class="mb-4">
          <h3
            class="mb-3 text-center"
            [class.wallet-black]="isCargoWallet"
            i18n="@@registration.information.additional-information"
          >
            Additional Information
          </h3>
          <h6
            class="mb-3"
            i18n="@@registration.information.text-provide-more-details"
            [class.wallet-black]="isCargoWallet"
          >
            Please provide more details below and get access within 24h
          </h6>
        </div>
      </div>
      <div>
        <form *ngIf="registerForm" [formGroup]="registerForm">
          <div class="form-group">
            <label
              required
              for="companyName"
              required
              i18n="@@global.full-company-name"
              [class.wallet-black-label]="isCargoWallet"
              >Full Company Name</label
            >
            <input
              type="text"
              formControlName="companyName"
              class="form-control"
              id="companyName"
            />
          </div>
          <div class="form-group">
            <label
              required
              for="companyCode"
              required
              i18n="@@global.short-company-name"
              [class.wallet-black-label]="isCargoWallet"
              >Short Company Name</label
            >
            <input
              type="text"
              formControlName="companyCode"
              class="form-control"
              id="companyCode"
            />
          </div>
          <div class="form-group mb-1">
            <label
              for="iataCass"
              i18n="@@global.iata-cass"
              class="text-uppercase"
              [class.wallet-black-label]="isCargoWallet"
              >Iata-Cass</label
            >
            <input
              [disabled]="
                registerForm.controls?.noIataCass?.value ? true : null
              "
              type="text"
              formControlName="iataCass"
              class="form-control"
              [value]="registerForm.get('iataCass').value | iataCassFormatter"
              placeholder="xxxxxxx-xxxx"
              maxlength="12"
              id="iataCass"
              [class.invalid]="
                !registerForm ||
                (registerForm.controls.iataCass.invalid &&
                  registerForm.controls.iataCass.dirty)
              "
              [class.valid]="
                registerForm &&
                registerForm.controls.iataCass.valid &&
                registerForm.controls.iataCass.dirty
              "
              (paste)="onIataCassPaste()"
            />
            <mat-error
              *ngIf="
                isControlHasError('iataCass', 'pattern') &&
                !registerForm.controls.noIataCass.value
              "
            >
              <strong
                i18n="@@registration.information.text-enter-valid-iata-cass"
                >Please enter a valid IATA CASS</strong
              >
            </mat-error>
          </div>
          <div [class.invisible]="confirmation" class="form-check mb-4">
            <input
              formControlName="noIataCass"
              class="form-check-input"
              type="checkbox"
              value=""
              id="iataCassCheckbox"
              (change)="onNoIataCassChange()"
            />
            <label
              class="form-check-label ml-3"
              for="iataCassCheckbox"
              i18n="@@registration.text-i-don-t-have-iata-cass"
            >
              I don’t have IATA CASS
            </label>
          </div>
          <span i18n="@@registration.information.text-faster-verification">
            For faster verification, please provide:
          </span>
          <div class="form-group">
            <label
              for="linkedin"
              i18n="@@global.personal-linkedin-profile"
              [class.wallet-black-label]="isCargoWallet"
              >Personal LinkedIn Profile</label
            >
            <input
              type="text"
              formControlName="linkedin"
              class="form-control"
              placeholder="https://"
              id="linkedin"
            />
          </div>
        </form>
      </div>
      <div class="d-flex flex-row">
        <span
          class="btn-link mr-4"
          (click)="onBackClick()"
          i18n="@@global.back"
          [class.wallet-black]="isCargoWallet"
          >Back</span
        >
        <button
          id="btn-register"
          (click)="onRegisterClick()"
          class="flex-fill"
          [disabled]="!registerForm || registerForm.invalid"
          [class.wallet-blue-bg]="isCargoWallet"
          i18n="@@registration.register-and-verify"
        >
          Register & Verify
        </button>
      </div>
      <a href="/">
        <div
          class="signup-logo mt-4"
          [ngStyle]="{
            'background-image': 'url(' + backgroundImage + ')'
          }"
        ></div>
      </a>
    </div>
  </div>
  <kt-registration-tutorial *ngIf="isCargoMart"></kt-registration-tutorial>
  <kt-registration-video *ngIf="isCargoWallet"></kt-registration-video>
</div>
