import { Observable } from "rxjs";
import { AppNotificationTypeEnum, MeasurementSystemEnum } from "@cai-services";
import { Airport } from "../../core/_models/region-airport.model";
import { NotificationType } from "../../core/_models/notification-type.model";
import { NotificationSetting } from "../../core/_models/notification-setting.model";
import { RegisterService } from "../../core/_services/register.service";
import { User } from "../../core/_models/user.model";
import { Forwarder } from "../../core/_models/forwarder.model";

export const mockedUserObject = {
  "type": "FORWARDER",
  "userId": 1,
  "userName": "Forwarder Singapore",
  "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
  "lastName": "Kwan",
  "firstName": "Lee",
  "language": "en",
  "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "+66614614800" },
  "userStatus": "ACTIVATED",
  "branchName": null,
  "branchCode": null,
  "logoPath": "user1.png",
  "sita": null,
  "smtp": "",
  "pima": "pimatest",
  "defaultCurrency": "SGD",
  "measurementSystem": MeasurementSystemEnum.METRIC,
  "company": {
    "id": 128,
    "companyCode": "Forwarder",
    "companyName": "Forwarder",
    "companyType": "FORWARDER",
    "logoHash": "283e3eed-44fb-11eb-88a2-06f57e4303ea",
    "logoType": undefined,
    "users": [],
    "airlineBrands": [],
  },
  "originAirport": {
    "airportCode": "SIN",
    "airportName": "Changi",
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
  } as Airport,
  "country": { "countryCode": "SG", "countryName": "Singapore" },
  "linkedInProfile": null,
  "iataCode": "2347187",
  "cassCode": "2012",
  "mostLikelyOriginAirport": "SIN",
  "timeOfSearch": 5,
  "bookingType": "AUTO_BOOK_IN_ORDER",
  "noIataCass": false,
  "class": "",
} as any;

export const notificationTypeStub = {
  "name": AppNotificationTypeEnum.QUOTE_REJECT,
} as any as NotificationType;
export const userSettingsStub = [
  {
    "isSendEmail": true,
    "isSendToast": true,
    "notificationType": notificationTypeStub,
  } as any as NotificationSetting,
];

export const registerServiceStub: Partial<RegisterService> = {
  "checkExistingEmail": () => new Observable((ob) => {
      ob.next();
      ob.complete();
    }),
  "registerAirlineUser": () => new Observable((user) => {
      user.next();
      user.complete();
    }),

  "registerForwarder": () => new Observable((user) => {
      user.next(mockedUserObject as User);
      user.complete();
    }),
  "getUser": () =>
    new Promise((resolve) => {
      resolve(mockedUserObject as User);
    }),
  "updateAirline": () =>
    new Observable((ob) => {
      ob.next(null);
      ob.complete();
    }),
  "updateForwarder": () =>
    new Observable((ob) => {
      ob.next(null);
      ob.complete();
    }),
  "getForwarders": () =>
    new Promise((resolve) => {
      resolve([mockedUserObject as User as Forwarder]);
    }),
  // getCompaniesLight: () =>
  //   new Promise((resolve) => {
  //     resolve(companiesLight);
  //   }),
  // getOffices: () =>
  //   new Promise((resolve) => {
  //     resolve([]);
  //   }),
  "getPrefillInformation": () =>
    new Observable((ob) => {
      ob.next(null);
      ob.complete();
    }),
  "updateNSByForwarder": () =>
    new Promise((resolve) => {
      resolve([]);
    }),
  "getNSByForwarder": () =>
    new Promise((resolve) => {
      resolve(userSettingsStub);
    }),
};
