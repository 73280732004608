import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  CustomerService,
  WalletService,
  WalletStatusTypeEnum,
} from "@cai-services";
import { Subscription, concatMap } from "rxjs";
import { NumberUnitPipe } from "../../../core/_base/layout/pipes/number-unit.pipe";
import { User } from "../../../core/_models/user.model";
import { AppWalletService } from "../../../core/_services/app-wallet.service";
import { SessionService } from "../../../core/_services/session.service";
import { APP_PROPS } from "../../../cai-common.module";
import { ApplicationProperties } from "../../../core/_base/layout/models/app-properties.model";
import { WalletUtil } from "../../../utils";

@Component({
  "selector": "kt-wallet",
  "templateUrl": "./wallet.component.html",
  "styleUrls": ["./wallet.component.scss"],
  "providers": [NumberUnitPipe],
})
export class WalletComponent implements OnInit, OnDestroy {
  defaultBalance = "0";
  currencySymbole = "$";
  cargoWalletFeature = true;
  currentUser: User;

  private officeChangedStatusSub: Subscription;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly sessionService: SessionService,
    private readonly walletService: WalletService,
    private readonly customerService: CustomerService,
    private ref: ChangeDetectorRef,
    private appWalletService: AppWalletService,
  ) {
    this.currentUser = this.sessionService.getCurrentUser();
  }

  ngOnInit (): void {
    this.officeChangedStatusSub = this.sessionService.officeChangedStatusObs
      .pipe(
        concatMap(async (_) => {
          await this.fetchOfficeBalance();
        }),
      )
      .subscribe();
    this.appWalletService
      .returnUpdateDefaultCurrencyObs()
      .pipe(
        concatMap(async (_) => {
          await this.fetchOfficeBalance();
        }),
      )
      .subscribe();
  }

  ngOnDestroy (): void {
    if (this.officeChangedStatusSub) {
      this.officeChangedStatusSub.unsubscribe();
    }
  }

  async fetchOfficeBalance (): Promise<void> {
    const office = this.sessionService.getSelectedOffice(),
     officeId = office?.id?.toString();
    if (officeId && office.walletStatus == WalletStatusTypeEnum.ACTIVE) {
      let defaultWalletBalance;
      if (this.isCargoWallet) {
        defaultWalletBalance =
          await this.customerService.getDefaultWalletBalance(office.walletId);
      } else {
        defaultWalletBalance =
          await this.walletService.getDefaultWalletBalance(officeId);
      }

      let globalBalance;
      if (this.isCargoWallet) {
        globalBalance = await this.customerService.getGlobalPrepaidBalance(
          office.walletId,
          defaultWalletBalance.currency,
        );
      } else {
        globalBalance = await this.walletService.getGlobalPrepaidBalance(
          officeId,
          defaultWalletBalance.currency,
        );
      }

      this.defaultBalance = globalBalance.amount.toString();
      this.currencySymbole = WalletUtil.getCustomCurrencyCode(
        defaultWalletBalance.currency,
      );

      this.ref.detectChanges();
    } else {
      this.defaultBalance = "0";
      this.currencySymbole = "$";
      this.ref.detectChanges();
    }
  }

  get walletBaseUrl (): string {
    const office = this.sessionService.getSelectedOffice(),
     walletBaseUrl =
      WalletUtil.getWalletManagementUrl(window.location.href) +
      `?officeId=${office?.id}`;
    return walletBaseUrl;
  }

  get isCargoWallet (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
