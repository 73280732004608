import { NgxPermissionsModule } from "ngx-permissions";
// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// NgBootstrap
import {
  NgbProgressbarModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
// Loading bar
import { LoadingBarModule } from "@ngx-loading-bar/core";
// NGRX
import { StoreModule } from "@ngrx/store";
// Ngx DatePicker
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
// Perfect Scrollbar
// Core Module
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BannersModule } from "../banners/banners.module";
import { CommonComponentsModule } from "../components";
import { HeaderSearchModule } from "../components/header-search/header-search.module";
import { HtmlClassService } from "../core/_base/crud/utils/html-class.service";
import { permissionsReducer } from "../core/_misc/_reducers/permission.reducers";
import { rolesReducer } from "../core/_misc/_reducers/role.reducers";
import { CoreModule } from "../core/core.module";
import { ErrorPageComponent } from "../theme/error-page/error-page.component";
import { AsideLeftComponent } from "./aside/aside-left.component";
import { BannerComponent } from "./banner/banner.component";
import { BaseComponent } from "./base/base.component";
import { BrandComponent } from "./brand/brand.component";
import { HeaderMobileComponent } from "./header/header-mobile/header-mobile.component";
import { HeaderComponent } from "./header/header.component";
import { MenuHorizontalComponent } from "./header/menu-horizontal/menu-horizontal.component";
import { TopbarComponent } from "./header/topbar/topbar.component";
import { WalletComponent } from "./header/wallet/wallet.component";
import { IncorrectLoginComponent } from "./incorrect-login/incorrect-login.component";

@NgModule({
  "declarations": [
    BaseComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
    BannerComponent,
    WalletComponent,
    IncorrectLoginComponent,
  ],
  "exports": [
    BaseComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
    IncorrectLoginComponent,
  ],
  "providers": [HtmlClassService],
  "imports": [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature("roles", rolesReducer),
    StoreModule.forFeature("permissions", permissionsReducer),
    CoreModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    LoadingBarModule,
    NgxDaterangepickerMd,
    MatAutocompleteModule,
    // ng-bootstrap modules
    NgbProgressbarModule,
    NgbTooltipModule,
    BannersModule,
    CoreModule,
    HeaderSearchModule,
    CommonComponentsModule,
  ],
})
export class CommonThemeModule {}
