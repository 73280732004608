import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import moment from 'moment';
import { UserTypeEnum } from '@cai-services';
import { SessionService } from '../../core/_services/session.service';
import { HeaderService } from '../../core/_base/layout/services/header.service';
import { User } from '../../core/_models/user.model';
import { environment } from '../../environments';
import { BrandIconUtil } from '../../utils/brand.util';

@Component({
  selector: 'kt-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @ViewChild('bannerContainer', { read: ViewContainerRef })
  bannerContainer: ViewContainerRef;
  banners: any[];
  userBanners: any;
  showBanner: boolean;
  currentUser: User;
  activebanner: any;
  disableDefaultButton: boolean;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly sessionService: SessionService,
    public readonly headerService: HeaderService,
  ) {
    this.banners = environment.banners;
  }

  ngOnInit(): void {
    this.currentUser = this.sessionService.getCurrentUser();
    if (!this.currentUser) {
      return;
    }
    const userCountryCode = this.currentUser.country?.countryCode;
    if (
      this.banners &&
      this.banners.length &&
      BrandIconUtil.isCargoAiDomain()
    ) {
      const bannerDisplayInfo = this.sessionService.getBannerDisplayInfo(),
        isSameAsPreviousUser =
          bannerDisplayInfo &&
          bannerDisplayInfo.userId === this.currentUser.userId;
      let visible =
        !bannerDisplayInfo || ['null', 'undefined'].includes(bannerDisplayInfo);
      if (bannerDisplayInfo) {
        if (isSameAsPreviousUser) {
          const lastShownBanner = moment(
              bannerDisplayInfo.lastShown,
              'yyyy-MM-DD',
            )
              .startOf('day')
              .toDate(),
            today = moment(new Date()).startOf('day').toDate();
          visible = +today > +lastShownBanner;
        } else {
          this.sessionService.removeBannerDisplayInfo();
          visible = true;
        }
      }
      this.userBanners = this.banners
        .filter(
          (banner) =>
            banner.enabled &&
            (!banner.countries || banner.countries.includes(userCountryCode)),
        )
        .filter(
          (banner) =>
            !banner.countriesExclude ||
            !banner.countriesExclude.includes(userCountryCode),
        );
      this.showBanner = visible && this.userBanners.length > 0;
      this.activebanner = this.userBanners[0];
    }
  }

  close(): void {
    const today = moment(new Date()).format('yyyy-MM-DD');
    this.sessionService.setBannerDisplayInfo({
      userId: this.currentUser.userId,
      lastShown: today,
    });
    this.showBanner = false;
    this.cdr.detectChanges();
    this.headerService.updateHeight();
  }

  get isForwarder(): boolean {
    return this.currentUser?.type === UserTypeEnum.FORWARDER;
  }

  get titleLhs(): string {
    if (this.activebanner?.dutchTranslation) {
      return 'Bent u er klaar voor?';
    }
    return 'Are you Ready?';
  }

  get titleRhs(): string {
    if (this.activebanner?.dutchTranslation) {
      return 'is nu LIVE op CargoAi!';
    }

    if (this.activebanner?.additionalText) {
      return `is now LIVE ${this.activebanner?.additionalText} on CargoAi`;
    }
    return 'is now LIVE on CargoAi';
  }

  get subHeader(): string {
    if (this.activebanner?.dutchTranslation) {
      return 'Boek nu eenvoudiger dan ooit met CargoAi.';
    }
    return `${this.activebanner?.airlineName}'s bookings made easy with CargoAi`;
  }
}
