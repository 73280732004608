import {
  WidgetQuotesEnum,
  WidgetQuotesRequestEnum,
} from "../core/_enums/widget-type-enum";
import { StatisticsTypeEnum } from "../enum/statistic-types.enum";

export const StatisticsData = [
  {
    "type": StatisticsTypeEnum.PENDING_QUOTES,
    "title": $localize`:@@global.pending-quotes:Pending Quotes`,
    "class": "pending",
    "description": "TOTAL",
    "value": 0,
    "data": {
      "filterType": WidgetQuotesRequestEnum.ALL_NON_EXPIRED_QUOTES,
    },
    "iconUrl": "/assets/media/icons/search-page/clock.png",
    "path": "/forwarder/quote/quote-status",
  },
  {
    "type": StatisticsTypeEnum.READY_TO_BOOK,
    "title": $localize`:@@global.ready-to-book:Ready To Book`,
    "class": "ready-to-book",
    "description": "TOTAL",
    "value": 0,
    "data": {
      "filterType": WidgetQuotesRequestEnum.ACCEPTED_QUOTES,
    },
    "iconUrl": "/assets/media/icons/search-page/star.png",
    "path": "/forwarder/quote/quote-status",
  },
  {
    "type": StatisticsTypeEnum.CONFIRMED_BOOKING,
    "title": $localize`:@@global.confirmed-booking:Confirmed Bookings`,
    "class": "confirmed",
    "description": "TOTAL",
    "value": 0,
    "data": {
      "filterType": WidgetQuotesEnum.BOOKING_CONFIRMED,
    },
    "iconUrl": "/assets/media/icons/search-page/tick.png",
    "path": "/forwarder/book/booking-history",
  },
  {
    "type": StatisticsTypeEnum.DELIVERED,
    "title": $localize`:@@global.delivered:Delivered`,
    "class": "delivered",
    "description": "TOTAL",
    "value": 0,
    "data": {
      "filterType": WidgetQuotesEnum.NEED_RATING,
    },
    "iconUrl": "/assets/media/icons/search-page/location.png",
    "path": "/forwarder/book/booking-history",
  },
];
