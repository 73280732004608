import {
  LoadTypeEnum,
  MeasurementSystemEnum,
  PackingTypeEnum,
  WeightTypeEnum,
} from "@cai-services";
import { SearchTemplate } from "../../core/_models/search-template.model";
import { mockedCommodities } from "./commodity";

const mockedSearchTemplate = {
  "commodities": mockedCommodities,
  "dangerousGoods": [
    {
      "goodClass": "5B",
      "id": 14,
      "packagingGroup": "n/a",
      "packagingInstruction": "123",
      "shc": "ABC",
      "totalNet": 2,
      "unid": "8678",
    },
  ],
  "requirements": [],
  "destination": "BRU",
  "isDestinationByCity": false,
  "origin": "SIN",
  "isOriginByCity": false,
  "searchDate": new Date(),
  "isHistory": true,
  "isSearch": false,
  "isSearchTemplate": true,
  "searchTemplateId": 1,
  "typeOfProduct": {
    "productCode": "GCR",
  },
  "office": {
    "type": "FORWARDER",
    "id": 14478,
    "country": {
      "countryCode": "SG",
      "countryName": "Singapore",
    },
    "iata": "1234567",
    "sita": "11test",
    "pima": "12test",
    "smtp": "12test",
    "airports": [
      {
        "airportCode": "SIN",
        "airportName": "Changi",
        "regionCode": "ASPAC",
        "regionName": "Asia Pacific",
        "timezone": "Asia/Singapore",
      },
    ],
    "cass": "0001",
  },
  "specialHandlingCode": undefined,
  "searchItemTemplates": [
    {
      "weight": 1,
      "height": -100,
      "length": -100,
      "width": -100,
      "weightType": WeightTypeEnum.TOTAL,
      "numOfItems": 1,
      "packingType": PackingTypeEnum.NONE,
      "searchItemTemplateId": undefined,
      "typeOfProduct": undefined,
      "volume": undefined,
      "uldType": undefined,
      "itemType": LoadTypeEnum.TOTAL,
    } as any,
  ],
  "craftType": "all_aircrafts",
  "volume": 1,
  "weight": 1,
  "chargeableWeight": 166.67,
  "measurementSystem": MeasurementSystemEnum.METRIC,
} as SearchTemplate,

 mockedSearchTemplateWithRequirements = {
  "searchTemplateId": 5940,
  "isSearch": false,
  "isHistory": true,
  "searchDate": "2021-10-15T10:57:08.931+00:00",
  "originAirport": "SIN",
  "destAirport": "NRT",
  "typeOfProduct": {
    "typeOfProductId": 4,
    "productCode": "FRESH",
    "productDescription": "Perishables",
  },
  "office": {
    "type": "FORWARDER",
    "id": 14478,
    "country": {
      "countryCode": "SG",
      "countryName": "Singapore",
    },
    "iata": "1234567",
    "sita": "11test",
    "pima": "12test",
    "smtp": "12test",
    "airports": [
      {
        "airportCode": "SIN",
        "airportName": "Changi",
        "regionCode": "ASPAC",
        "regionName": "Asia Pacific",
        "timezone": "Asia/Singapore",
      },
    ],
    "cass": "0001",
  },
  "searchTemplateType": "AIRPORT_TO_AIRPORT",
  "searchItemTemplates": [
    {
      "searchItemTemplateId": 6375,
      "height": -12,
      "width": -12,
      "length": -12,
      "numOfItems": 33,
      "packingType": "NONE",
      "weight": 33,
      "weightType": "TOTAL",
      "searchTemplate": null,
    },
  ],
  "commodities": [
    {
      "id": 190,
      "name": "Perishable",
      "level": 1,
      "specialHandlingCodes": [
        {
          "shcId": 37,
          "shcCode": "PER",
          "shcDescription": "Perishable",
          "isPublic": false,
        },
      ],
      "scrs": [],
    },
    {
      "id": 199,
      "name": "Flowers, Plants",
      "level": 2,
      "specialHandlingCodes": [
        {
          "shcId": 34,
          "shcCode": "PEF",
          "shcDescription": "Flowers",
          "isPublic": true,
        },
      ],
      "scrs": [],
    },
  ],
  "requirements": [
    {
      "requirement": {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "isPublic": true,
              },
            ],
          },
        ],
      },
      "answer": {
        "code": "xps_yes",
        "specialHandlingCodes": [
          {
            "shcId": 52,
            "shcCode": "XPS",
            "shcDescription": "Express",
            "isPublic": true,
          },
        ],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "cooling_packaging",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "NA",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "NA",
            "specialHandlingCodes": [],
          },
          {
            "code": "icewater",
            "specialHandlingCodes": [
              {
                "shcId": 128,
                "shcCode": "WET",
                "shcDescription":
                  "Shipments of Wet Material not Packed in Watertight Containers",
                "isPublic": true,
              },
            ],
          },
          {
            "code": "dryice",
            "specialHandlingCodes": [
              {
                "shcId": 27,
                "shcCode": "ICE",
                "shcDescription": "DRY ICE",
                "isPublic": true,
              },
            ],
          },
        ],
      },
      "answer": {
        "code": "icewater",
        "specialHandlingCodes": [
          {
            "shcId": 128,
            "shcCode": "WET",
            "shcDescription":
              "Shipments of Wet Material not Packed in Watertight Containers",
            "isPublic": true,
          },
        ],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "temperature_tracking_device",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      "answer": {
        "code": "default_yes",
        "specialHandlingCodes": [],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      "answer": {
        "code": "default_yes",
        "specialHandlingCodes": [],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "tracking_device_number",
        "type": "NUMBER_INPUT",
        "answers": [],
      },
      "answer": null,
      "freeChoice": "7",
    },
    {
      "requirement": {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "isPublic": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "isPublic": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "isPublic": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "isPublic": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "isPublic": true,
              },
            ],
          },
        ],
      },
      "answer": {
        "code": "both",
        "specialHandlingCodes": [
          {
            "shcId": 16,
            "shcCode": "ELM",
            "shcDescription": "Lithium Metal Batteries",
            "isPublic": true,
          },
          {
            "shcId": 15,
            "shcCode": "ELI",
            "shcDescription": "Lithium Ion Batteries",
            "isPublic": true,
          },
        ],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "temperature_full",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "temp_not_required",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "ambiant",
            "specialHandlingCodes": [
              {
                "shcId": 17,
                "shcCode": "ERT",
                "shcDescription": "2 25C",
                "isPublic": true,
              },
            ],
          },
          {
            "code": "controlled",
            "specialHandlingCodes": [
              {
                "shcId": 9,
                "shcCode": "CRT",
                "shcDescription": "Control Room Temp 15 25C",
                "isPublic": false,
              },
            ],
          },
          {
            "code": "cold",
            "specialHandlingCodes": [
              {
                "shcId": 8,
                "shcCode": "COL",
                "shcDescription": "Cool Goods",
                "isPublic": false,
              },
            ],
          },
          {
            "code": "frozen",
            "specialHandlingCodes": [
              {
                "shcId": 19,
                "shcCode": "FRO",
                "shcDescription": "Frozen Goods",
                "isPublic": true,
              },
            ],
          },
          {
            "code": "temp_not_required",
            "specialHandlingCodes": [],
          },
        ],
      },
      "answer": {
        "code": "frozen",
        "specialHandlingCodes": [
          {
            "shcId": 19,
            "shcCode": "FRO",
            "shcDescription": "Frozen Goods",
            "isPublic": true,
          },
        ],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "isPublic": true,
              },
            ],
          },
        ],
      },
      "answer": {
        "code": "dip_yes",
        "specialHandlingCodes": [
          {
            "shcId": 13,
            "shcCode": "DIP",
            "shcDescription": "Diplomatic Mail",
            "isPublic": true,
          },
        ],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [
            {
              "shcId": 84,
              "shcCode": "SPX",
              "shcDescription": "Secured Cargo",
              "isPublic": true,
            },
          ],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 84,
                "shcCode": "SPX",
                "shcDescription": "Secured Cargo",
                "isPublic": true,
              },
            ],
          },
        ],
      },
      "answer": {
        "code": "spx_yes",
        "specialHandlingCodes": [
          {
            "shcId": 84,
            "shcCode": "SPX",
            "shcDescription": "Secured Cargo",
            "isPublic": true,
          },
        ],
      },
      "freeChoice": null,
    },
    {
      "requirement": {
        "code": "need_active_container",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "act_yes",
            "specialHandlingCodes": [
              {
                "shcId": 1,
                "shcCode": "ACT",
                "shcDescription": "Active Temperature",
                "isPublic": false,
              },
            ],
          },
        ],
      },
      "answer": {
        "code": "act_yes",
        "specialHandlingCodes": [
          {
            "shcId": 1,
            "shcCode": "ACT",
            "shcDescription": "Active Temperature",
            "isPublic": false,
          },
        ],
      },
      "freeChoice": null,
    },
  ],
  "dangerousGoods": [],
  "dgdDictionnaries": [],
  "measurementSystem": "IMPERIAL",
} as any;

export { mockedSearchTemplate, mockedSearchTemplateWithRequirements };
