import { RateTypeEnum, RateWeightTypeEnum } from "@cai-services";

export const mockedRateDetail = {
  "id": 2,
  "rateType": RateTypeEnum.ALL_IN_RATE,
  "rate": 1.25,
  "allInRate": 1.25,
  "netRate": null,
  "total": 0,
  "currency": "SGD",
  "weightType": RateWeightTypeEnum.CHARGEABLE_WEIGHT,
  "rateSurcharges": [],
};
