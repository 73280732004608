<a
  role="button"
  [attr.href]="walletBaseUrl"
  class="kt-header__topbar-item"
  target="_blank"
  *ngIf="cargoWalletFeature"
>
  <div class="kt-header__topbar-wrapper">
    <span class="kt-header__topbar-icon">
      <span class="font-weight-500 pr-1 text-nowrap"
        >{{ currencySymbole }} {{ defaultBalance | numberToKMBUnit }}</span
      >
      <img
        src="/assets/media/icons/cargo_wallet.svg"
        class="pb-1"
        alt="cargo_wallet"
      />
    </span>
  </div>
</a>
