import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import moment from "moment";
import { Router } from "@angular/router";
import { UserTypeEnum } from "@cai-services";
import { SessionService } from "../../core/_services/session.service";
import { User } from "../../core/_models/user.model";

@Component({
  "selector": "kt-rewards-banner",
  "templateUrl": "./rewards-banner.component.html",
  "styleUrls": ["./rewards-banner.component.scss"],
})
export class RewardsBannerComponent {
  @Input() activebanner: any;
  @Input() currentUser: User;
  @Input() showBanner: boolean;
  @Output() onClose = new EventEmitter();

  constructor (
    private readonly cdr: ChangeDetectorRef,
    private readonly sessionService: SessionService,
    private readonly router: Router,
  ) {}

  handleClose (): void {
    const today = moment(new Date()).format("yyyy-MM-DD");
    this.sessionService.setBannerDisplayInfo({
      "userId": this.currentUser.userId,
      "lastShown": today,
    });
    this.onClose.emit();
    this.cdr.detectChanges();
  }

  handleLearnMoreClicked (): void {
    const user =
      this.currentUser?.type === UserTypeEnum.FORWARDER
        ? "forwarder"
        : "airline";
    this.router.navigateByUrl(`${user}/profile/${user}-profile`);
  }
}
