import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import {
  IReferrer,
  ReferralService,
  UserStatusEnum,
  UserTypeEnum,
} from '@cai-services';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { APP_PROPS } from '../../../cai-common.module';
import { Country, GlobalService } from '../../../core';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';
import { REGEXP_PASSWORD } from '../../../core/_constants/constants';
import { IntercomService } from '../../../core/_services/intercom.service';
import { RegisterService } from '../../../core/_services/register.service';
import { SessionService } from '../../../core/_services/session.service';
import { environment } from '../../../environments';
import { BrandIconUtil } from '../../../utils/brand.util';
import { emailProviderValidator } from '../../../validators/email-provider.validator';
import { noLettersValidator } from '../../../validators/no-letters.validator';

const DEBOUNCE_TIME = 1500;

declare const grecaptcha: any;

@Component({
  selector: 'kt-register',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          maxHeight: '0',
          overflow: 'hidden',
          opacity: 0,
          marginTop: 0,
          marginBottom: 0,
        }),
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.5s 0.3s')]),
    ]),
  ],
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  registerForm: FormGroup;
  guestUserRegisterForm: FormGroup;
  userTypeForm: FormGroup;
  errors: any = [];
  registerSuccess = false;
  type: string;
  isEmailNew = false;
  countryCodeOptions: any[];

  isLoading = false;
  isVideoClosed = false;
  isGuestUser: boolean;
  countries: Country[];
  shouldDisableEmail: boolean;

  public userTypesOption: any = [
    {
      label: 'Airline / GSA',
      labelIcon: '/assets/media/wallet/registration/airline-user.png',
      value: 'airline',
    },
    {
      label: 'Airport',
      labelIcon: '/assets/media/wallet/registration/airport-user.png',
      value: 'airport',
    },
    {
      label: 'Forwarder',
      labelIcon: '/assets/media/wallet/registration/forwarder-user.png',
      value: 'forwarder',
    },
    {
      label: 'GHA',
      labelIcon: '/assets/media/wallet/registration/gha-user.png',
      value: 'gha',
    },
    {
      label: 'Port',
      labelIcon: '/assets/media/wallet/registration/port-user.png',
      value: 'port',
    },
    {
      label: 'Shipper',
      labelIcon: '/assets/media/wallet/registration/shipper-user.png',
      value: 'shipper',
    },
    {
      label: 'Trucking',
      labelIcon: '/assets/media/wallet/registration/trucking-user.png',
      value: 'trucking',
    },
    {
      label: 'Other',
      labelIcon: '/assets/media/wallet/registration/other-user.png',
      value: 'other',
    },
  ];
  referrer: IReferrer;
  invitationId: string;

  private readonly recaptchaUrl = `https://recaptcha.net/recaptcha/api.js?render=${environment.recaptchaSiteKey}`;

  private unsubscribe = new Subject<void>(); // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private emailSubject: Subject<string> = new Subject();

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private registerService: RegisterService,
    private sessionService: SessionService,
    private referralService: ReferralService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private readonly location: Location,
    private router: Router,
    private readonly intercomService: IntercomService,
    public readonly globalService: GlobalService,
  ) {
    this.emailSubject
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((emailValue) => {
        this.checkEmail(emailValue);
      });
  }

  /*
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  async ngOnInit() {
    await Auth.signOut();
    this.isGuestUser = this.route.snapshot.queryParams?.isGuestUser;
    this.initRegisterForm();
    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.registerForm.controls.email.setValue(params.email);
        this.registerForm.controls.email.markAsTouched();
        this.checkEmail(params.email);
      }
      if (params.prefillRegisterEmail) {
        this.registerForm.controls.email.setValue(params.prefillRegisterEmail);
        this.registerForm.controls.email.markAsTouched();
        this.checkEmail(params.prefillRegisterEmail);
        this.registerForm.controls.email.disable();
        this.shouldDisableEmail = true;
      }
      if (params.type) {
        this.type = params.type;
      }
      if (params.referral) {
        this.registerForm.controls.referralCode.setValue(params.referral);
      }
      if (params.invite) {
        this.invitationId = params.invite;
      }
    });
    if (!this.isScriptLoaded()) {
      this.loadRecaptcha();
    }
    this.setCaptchaVisibility(true);
    this.countries = await this.globalService?.getCountries();
    this.initCountryCodes();
  }

  initCountryCodes(): void {
    this.countryCodeOptions = _.sortBy(this.countries, 'countryCode').map(
      (item) => ({
        label: `${item.countryCode} - ${item.countryName}`,
        value: item.countryCode,
      }),
    );
  }

  ngAfterViewInit() {
    if (this.route.snapshot.queryParamMap.get('success') == 'true') {
      this.registerSuccess = true;
      this.cdr.detectChanges();
    }
  }

  get backgroundImage(): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      '/assets/media/logos/',
    );
  }

  get showBrand(): boolean {
    const url = window.location.href;
    return !url.includes('localhost') && !url.split('.')[0].includes('app');
  }

  private isScriptLoaded() {
    return document.querySelector('script[src="' + this.recaptchaUrl + '"]')
      ? true
      : false;
  }

  private setCaptchaVisibility(visibility: boolean) {
    const recaptchaElements = document.getElementsByClassName(
      'grecaptcha-badge',
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < recaptchaElements.length; i++) {
      recaptchaElements[i].style.visibility = visibility ? 'visible' : 'hidden';
    }
  }

  public loadRecaptcha() {
    const body = document.body as HTMLDivElement,
      script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.recaptchaUrl;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  /*
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoading = false;
    this.setCaptchaVisibility(false);
  }

  setType(type: string) {
    this.type = type;
    this.location.replaceState('/register?type=' + this.type);
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegisterForm() {
    this.userTypeForm = this.fb.group({
      userType: ['', [Validators.required]],
    });
    if (this.isCargoWallet && this.isGuestUser) {
      this.guestUserRegisterForm = this.fb.group({
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
            Validators.minLength(3),
            Validators.maxLength(320),
            emailProviderValidator(),
          ]),
        ],
        companyName: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(320),
            Validators.pattern('.*[^ ].*'),
          ]),
        ],
        countryCode: ['', Validators.compose([Validators.required])],
      });
      return;
    }

    this.registerForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
          Validators.minLength(3),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          Validators.maxLength(320),
          emailProviderValidator(),
        ]),
      ],
      firstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          Validators.maxLength(50),
          noLettersValidator,
        ]),
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          Validators.maxLength(50),
          noLettersValidator,
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(REGEXP_PASSWORD),
        ]),
      ],
      referralCode: [''],
      device: [this.sessionService.getBrowserKey()],
    });

    this.registerForm
      .get('referralCode')
      .valueChanges.subscribe((value) => this.onReferralCodeChange(value));
  }

  onReferralCodeChange(newReferralCode: string): void {
    if (this.type === 'airline') {
      return;
    }
    const referrer =
      this.referralService.getReferrerFromReferralCode(newReferralCode);
    if (referrer === undefined) {
      return;
    }
    this.referrer = referrer;
  }

  onEmailKeyUp(e: Event): void {
    const target = e.target as HTMLInputElement,
      emailValue = target.value;
    this.emailSubject.next(emailValue);
  }

  checkEmail(value: any): void {
    const form = this.isGuestUser
      ? this.guestUserRegisterForm
      : this.registerForm;
    if (form.controls.email.valid) {
      this.registerService
        .checkExistingEmail(
          value,
          this.type === 'airline'
            ? UserTypeEnum.AIRLINE
            : UserTypeEnum.FORWARDER,
        )
        .subscribe((response) => {
          const emailControl = form.controls.email;
          this.isEmailNew = false;
          if (emailControl.errors && emailControl.errors.alreadyregistered) {
            delete emailControl.errors.alreadyregistered;
          }
          if (response) {
            emailControl.setErrors({
              alreadyregistered: true,
            });
          } else {
            this.isEmailNew = !response;
          }
          this.cdr.detectChanges();
        });
    } else {
      this.isEmailNew = false;
      this.cdr.detectChanges();
    }
  }

  public registerUser(): void {
    let registerPromise: Observable<any>;
    this.registerForm.controls.email.enable();
    const selectedLanguage = this.sessionService.getSelectedLanguage();
    if (this.type === 'airline') {
      registerPromise = this.registerService.registerAirlineUser({
        ...this.registerForm.value,
        language: selectedLanguage,
      });
    } else if (this.type === 'forwarder') {
      registerPromise = this.registerService.registerForwarder({
        ...this.registerForm.value,
        language: selectedLanguage,
      });
    } else {
      registerPromise = this.registerService.registerStandardUser({
        ...this.registerForm.value,
        language: selectedLanguage,
        subType: this.type?.toUpperCase(),
      });
    }
    this.isLoading = true;
    this.cdr.detectChanges();
    registerPromise?.subscribe({
      next: () => {
        this.updateIntercomData();
        this.registerSuccess = true;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            success: 'true',
          },
          queryParamsHandling: 'merge',
          // preserve the existing query params in the route
          skipLocationChange: false,
          // do not trigger navigation
        });
        this.cdr.detectChanges();
      },
      error: (e) => {
        if (this.shouldDisableEmail) {
          this.registerForm.controls.email.disable();
        }
        console.error('cannot registerUser: ', e);
        Swal.fire(
          '',
          $localize`:@@registration.user.error:Something went wrong while registering, please contact support`,
          'error',
        ).then();
      },
      complete: () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      },
    });
  }

  updateIntercomData(): void {
    const registeredValue = this.registerForm.value,
      intercomSetting = {
        app_id: environment.intercomAppId,
        name: registeredValue.firstName + ' ' + registeredValue.lastName,
        email: registeredValue.email,
        Customer_type:
          this.type === 'airline'
            ? UserTypeEnum.AIRLINE
            : UserTypeEnum.FORWARDER,
        Account_Activation_status: UserStatusEnum.PENDING_COMPANY_INFORMATION,
      };
    this.intercomService.update(intercomSetting);
  }

  /**
   * Form Submit
   */
  onAgreeClick(): void {
    const controls = this.registerForm.controls;

    // check form
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }
    const userRegistrationComponent: UserRegistrationComponent = this;
    if (!grecaptcha) {
      // Handle error to elt the user now that he needs the captcha to register
      return;
    }
    grecaptcha?.ready(function () {
      grecaptcha
        .execute(environment.recaptchaSiteKey, { action: 'registerUser' })
        .then(function (recaptchaToken) {
          userRegistrationComponent.registerUser();
        });
    });
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const form =
        controlName === 'userType'
          ? this.userTypeForm
          : this.isGuestUser
            ? this.guestUserRegisterForm
            : this.registerForm,
      control = form.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }
  get selectedUserType(): string {
    return this.userTypesOption
      .find((r) => r.value == this.type)
      .label.toUpperCase();
  }
  get iRedirectLink(): string {
    return this.isCargoWallet
      ? 'https://cargowallet.cargoai.co/send-payments'
      : 'https://www.cargoai.co/pricing/';
  }

  onContinueSelected(): void {
    if (this.isGuestUser) {
      this.sessionService.setItemSessionStorage(
        'guestUserEmail',
        this.guestUserRegisterForm.controls.email.value,
      );
      this.sessionService.setItemSessionStorage(
        'guestUserCompanyName',
        this.guestUserRegisterForm.controls.companyName.value,
      );
      this.sessionService.setItemSessionStorage(
        'guestUserCountryCode',
        this.guestUserRegisterForm.controls.countryCode.value,
      );
      this.sessionService.setItemSessionStorage(
        'guestUserType',
        this.userTypeForm.controls.userType.value,
      );
      this.sessionService.setItemSessionStorage('isGuestUser', true);
      this.router.navigate(['/pay-to']);
      return;
    }
    this.setType(this.userTypeForm.controls.userType.value);
  }

  get isSubmitDisabled(): boolean {
    if (!this.isGuestUser) {
      return this.userTypeForm?.invalid;
    }
    return this.guestUserRegisterForm?.invalid || this.userTypeForm?.invalid;
  }
}
