<!-- begin: Header -->
<div class="trial-header" *ngIf="shouldDisplayTrialHeader">
  <div class="trial-days-container">
    <span class="trial-days">{{ trialDays }}</span>
  </div>
  <span i18n="@@global.trial-days-left-message"
    >days left till your 30-day free trial ends. Upgrade now to continue your
    experience with ALL PRO features!</span
  >
  <button (click)="handleUpgradeButton()" i18n="@@global.Upgrade">
    <span>Upgrade</span>
  </button>
</div>
<div
  ktHeader
  #ktHeader
  class="kt-header kt-grid__item"
  [ngClass]="htmlClassService.getClasses('header', true)"
  id="kt_header"
>
  <ngb-progressbar
    class="kt-loading-bar"
    *ngIf="(loader.progress$ | async) > 0"
    [value]="loader.progress$ | async"
    height="3px"
  ></ngb-progressbar>

  <kt-banner></kt-banner>

  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <!-- begin:: Brand -->
    <kt-brand></kt-brand>
    <!-- end:: Brand -->

    <!-- begin: Header Menu -->
    <kt-menu-horizontal
      [isWalletApp]="isWalletApp"
      *ngIf="menuHeaderDisplay"
    ></kt-menu-horizontal>
    <!-- end: Header Menu -->

    <!-- begin:: Header Topbar -->
    <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
    <div *ngIf="!menuHeaderDisplay"></div>
    <div class="d-flex" data-intercom-target="Topbar_Container">
      <kt-topbar></kt-topbar>
    </div>
    <!-- end:: Header Topbar -->
  </div>
</div>
<!-- end: Header -->
