<ul class="time-filter" data-intercom-target="Time_Range_Container">
  <li [class.selected]="selectedRange === TimeRangeEnum.URGENT">
    <input
      type="radio"
      id="urgent"
      [value]="TimeRangeEnum.URGENT"
      name="time"
      [(ngModel)]="selectedRange"
      (ngModelChange)="onRangeChange($event)"
    />
    <label for="urgent" i18n="@@global.urgent">Urgent</label>
  </li>
  <li
    [class.selected]="selectedRange === TimeRangeEnum.WEEK"
    [class.week-extra-width]="isFrench"
  >
    <input
      type="radio"
      id="week"
      [value]="TimeRangeEnum.WEEK"
      name="time"
      [(ngModel)]="selectedRange"
      (ngModelChange)="onRangeChange($event)"
    />
    <label for="week" i18n="@@global.this-week">This Week</label>
  </li>
  <li [class.selected]="selectedRange === TimeRangeEnum.MONTH">
    <input
      type="radio"
      id="month"
      [value]="TimeRangeEnum.MONTH"
      name="time"
      [(ngModel)]="selectedRange"
      (ngModelChange)="onRangeChange($event)"
    />
    <label for="month" i18n="@@global.this-month">This Month</label>
  </li>
  <li
    [class.selected]="selectedRange === TimeRangeEnum.ALL"
    [class.all-extra-width]="isFrench"
  >
    <input
      type="radio"
      id="all"
      [value]="TimeRangeEnum.ALL"
      name="time"
      [(ngModel)]="selectedRange"
      (ngModelChange)="onRangeChange($event)"
    />
    <label for="all" i18n="@@global.all">All</label>
  </li>
</ul>
