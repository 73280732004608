<div *ngIf="showBanner && !activebanner.legacy && !activebanner.isRewardBanner">
  <div id="golive-banner">
    <div
      class="banner-inner"
      [ngStyle]="{
        padding: activebanner.custom ? '0px' : '',
        backgroundColor: activebanner.custom ? activebanner.backgroundColor : ''
      }"
    >
      <ng-container *ngIf="!activebanner.multiple && !activebanner.custom">
        <div class="header">
          <span> {{ titleLhs }} </span>
          <span class="strong">{{ activebanner.airlineName }}</span>
          <span> {{ titleRhs }} </span>
          <div class="subheader">{{ subHeader }}</div>
        </div>
        <!-- <div class="bookable-upto">Bookable Upto</div> -->
        <div class="statistics">
          <ng-container *ngFor="let stats of activebanner.statistics">
            <div class="statistics-item">
              <div class="statistics-value">{{ stats.value }}</div>
              <div
                class="statistics-label"
                [ngStyle]="{
                  width: stats.width,
                  'justify-content': stats.justifyContent,
                  'line-height': stats.lineHeight
                }"
              >
                {{ stats.text }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="logo" [ngStyle]="{ right: activebanner.logoSpacing }">
          <img
            [ngStyle]="{
              transform: activebanner.logoTransform,
              margin: activebanner.logoMargin
            }"
            src="{{ activebanner.logoPath }}"
            alt="Airline logo"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="activebanner.multiple">
        <div class="header multiple">
          {{ activebanner.title.prePink
          }}<span class="strong"> {{ activebanner.title.pink }} </span
          >{{ activebanner.title.postPink }}
        </div>
        <ng-container *ngFor="let logo of activebanner.logos">
          <div
            class="logo-multiple"
            [ngStyle]="{
              marginLeft: logo.transform != null ? '30px' : '0',
              paddingLeft: logo.transform == null ? '30px' : '0'
            }"
          >
            <img
              [ngStyle]="{
                transform: logo.transform
              }"
              src="{{ logo.path }}"
              alt="Airline logo"
            />
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="activebanner.custom">
        <a
          href="{{ activebanner.link }}"
          rel="noopener"
          target="_blank"
          [ngStyle]="{ margin: activebanner.placement }"
        >
          <img
            id="custom-image"
            src="{{ activebanner.logoPath }}"
            alt="Banner image"
        /></a>
      </ng-container>
      <span i18n="@@global.close" class="text-close">Close</span>
      <span *ngIf="!disableDefaultButton" class="icon-close" (click)="close()">
        <svg
          width="34"
          height="34"
          viewBox="0 0 42 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_3559_758)">
            <circle cx="21" cy="17" r="17" fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.3628 11.5219C14.903 10.8939 15.85 10.8226 16.4781 11.3628L21.4234 15.6159L26.3686 11.3628C26.9967 10.8226 27.9438 10.8939 28.4839 11.5219C29.0241 12.15 28.9529 13.0971 28.3248 13.6373L23.7238 17.5943L28.3248 21.5514C28.9529 22.0916 29.0241 23.0386 28.4839 23.6667C27.9438 24.2948 26.9967 24.3661 26.3686 23.8259L21.4234 19.5728L16.4781 23.8259C15.85 24.3661 14.903 24.2948 14.3628 23.6667C13.8226 23.0386 13.8939 22.0916 14.5219 21.5514L19.1229 17.5943L14.5219 13.6373C13.8939 13.0971 13.8226 12.15 14.3628 11.5219Z"
              fill="#679EC6"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3559_758"
              x="0"
              y="0"
              width="42"
              height="42"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3559_758"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3559_758"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </span>
    </div>
    <hr />
  </div>
</div>

<div
  *ngIf="
    showBanner &&
    !activebanner.legacy &&
    activebanner.isRewardBanner &&
    isForwarder
  "
>
  <kt-rewards-banner
    [activebanner]="activebanner"
    [currentUser]="currentUser"
    [showBanner]="showBanner"
    (onClose)="close()"
  ></kt-rewards-banner>
</div>
