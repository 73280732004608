<!-- BEGIN: Horizontal Menu -->
<button
  class="kt-header-menu-wrapper-close"
  id="kt_header_menu_mobile_close_btn"
>
  X
</button>
<div
  ktOffcanvas
  [options]="offcanvasOptions"
  class="kt-header-menu-wrapper"
  [class.wallet-app-menu]="isWalletApp"
  id="kt_header_menu_wrapper"
>
  <!-- begin: Menu toggle -->
  <!-- <button *ngIf="asideDisplay" class="kt-aside-toggler kt-aside-toggler--left" id="kt_aside_toggler"><span></span></button> -->
  <!-- end: Menu toggle -->

  <div
    ktMenu
    [options]="menuOptions"
    id="kt_header_menu"
    class="kt-header-menu kt-header-menu-mobile pt-1"
    [ngClass]="htmlClassService.getClasses('header_menu', true)"
    data-intercom-target="Horizontal_Menu"
  >
    <kt-user-profile2
      *ngIf="!isEmbedMode"
      [handleResponsive]="true"
    ></kt-user-profile2>
    <ul
      class="kt-menu__nav"
      [ngClass]="htmlClassService.getClasses('header_menu_nav', true)"
    >
      <ng-container *ngFor="let item of menuHorService.menuList$ | async">
        <ng-container
          *ngIf="item.title && shouldDisplayRates(item.title)"
          [ngTemplateOutlet]="menuTemplate"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
      </ng-container>
    </ul>
  </div>
</div>
<!-- END: Horizontal Menu -->

<ng-template #menuTemplate let-item="item" let-parentItem="parentItem">
  <!-- //NOSONAR -->
  <li
    [attr.aria-haspopup]="true"
    [attr.data-ktmenu-submenu-toggle]="getItemAttrSubmenuToggle(item)"
    (mouseleave)="mouseLeave($event)"
    (mouseenter)="mouseEnter()"
    [ngClass]="getItemCssClasses(item)"
    (click)="showUpgradeMessage(parentItem, item)"
  >
    <!-- if item has submenu -->
    <ng-container *ngIf="item.submenu">
      <a
        href="javascript:;"
        [ngClass]="{ 'kt-menu__toggle': item.root }"
        class="kt-menu__link"
        id="menu_{{ item.title }}"
      >
        <ng-container
          [ngTemplateOutlet]="menuItemInnerTemplate"
          [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
        ></ng-container>

        <ng-container *ngIf="rootArrowEnabled">
          <!-- arrow icons -->
          <em
            *ngIf="item.submenu && item.root"
            class="kt-menu__hor-arrow la la-angle-down"
          ></em>
          <em
            *ngIf="item.submenu && item.root"
            class="kt-menu__ver-arrow la la-angle-right"
          ></em>
        </ng-container>
        <!-- else arrow icons -->
        <em
          *ngIf="item.submenu && !item.root"
          class="kt-menu__hor-arrow la la-angle-right"
        ></em>
        <em
          *ngIf="item.submenu && !item.root"
          class="kt-menu__ver-arrow la la-angle-right"
        ></em>
      </a>
    </ng-container>

    <!-- if item hasn't sumbenu -->
    <ng-container *ngIf="!item.submenu">
      <a
        [routerLink]="item.page"
        (click)="closeSideMenu()"
        [ngClass]="{ 'kt-menu__toggle': item.root }"
        class="kt-menu__link"
        id="menu_{{ item.title }}"
      >
        <ng-container
          [ngTemplateOutlet]="menuItemInnerTemplate"
          [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
        ></ng-container>
      </a>
    </ng-container>

    <!-- if menu item has submenu child then recursively call new menu item component -->
    <ng-container *ngIf="item.submenu">
      <div
        class="kt-menu__submenu"
        [ngClass]="getItemMenuSubmenuClass(item)"
        [ngStyle]="{ width: item.submenu.width }"
      >
        <span
          class="kt-menu__arrow"
          [ngClass]="{ 'kt-menu__arrow--adjust': item.root }"
        ></span>

        <ul *ngIf="item.submenu?.length" class="kt-menu__subnav">
          <ng-container *ngFor="let child of item.submenu">
            <ng-container
              [ngTemplateOutlet]="menuTemplate"
              [ngTemplateOutletContext]="{ item: child, parentItem: item }"
              *ngIf="shouldShowDashboard(item?.title, child?.title)"
            >
            </ng-container>
          </ng-container>
        </ul>

        <ul *ngIf="item.submenu.items?.length" class="kt-menu__subnav">
          <ng-container *ngFor="let child of item.submenu.items">
            <ng-container
              [ngTemplateOutlet]="menuTemplate"
              [ngTemplateOutletContext]="{ item: child, parentItem: item }"
            >
            </ng-container>
          </ng-container>
        </ul>

        <div
          *ngIf="item.submenu.type === 'mega' && item.submenu.columns?.length"
          class="kt-menu__subnav"
        >
          <ul class="kt-menu__content">
            <ng-container *ngFor="let child of item.submenu.columns">
              <ng-container
                [ngTemplateOutlet]="menuColumnTemplate"
                [ngTemplateOutletContext]="{ item: child }"
              ></ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </li>
</ng-template>

<!-- item inner -->
<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
  <!-- if menu item has icon -->
  <em *ngIf="item.icon" class="kt-menu__link-icon" [ngClass]="item.icon"></em>

  <ng-container *ngIf="!item.icon">
    <!-- if menu item using bullet -->
    <em
      *ngIf="
        (parentItem && parentItem.bullet === 'dot') || item.bullet === 'dot'
      "
      class="kt-menu__link-bullet kt-menu__link-bullet--dot"
    >
      <span></span>
    </em>
    <em
      *ngIf="
        (parentItem && parentItem.bullet === 'line') || item.bullet === 'line'
      "
      class="kt-menu__link-bullet kt-menu__link-bullet--line"
    >
      <span></span>
    </em>
  </ng-container>

  <ng-container *ngIf="!item.badge; else menuLinkBadge">
    <span class="kt-menu__item-here"></span>
    <!-- menu item title text -->
    <span
      class="kt-menu__link-text"
      [translate]="item.translate"
      *ngIf="item.proTag != true && item.addonsTag != true; else lightFontSpan"
    >
      {{ item.title }}
    </span>

    <ng-template #lightFontSpan>
      <span
        class="kt-menu__link-text"
        [translate]="item.translate"
        *ngIf="item.proTag == true || item.addonsTag == true"
        style="color: #a0a0a3"
      >
        {{ item.title }}
      </span>
    </ng-template>

    <img
      *ngIf="item.proTag == true"
      class="tagImg"
      src="/assets/media/icons/pro-tag.svg"
      alt=""
    />

    <img
      *ngIf="item.addonsTag == true"
      class="tagImg"
      src="/assets/media/icons/add-ons-tag.svg"
      alt=""
    />
  </ng-container>

  <ng-template #menuLinkBadge>
    <!-- menu item with badge -->
    <span class="kt-menu__link-text" [translate]="item.translate">{{
      item.title
    }}</span>
    <span class="kt-menu__link-badge">
      <span
        class="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill"
        [ngClass]="item.badge.type"
        [translate]="item.badge.translate"
        >{{ item.badge.value }}</span
      >
    </span>
  </ng-template>
</ng-template>

<!-- item column -->
<ng-template #menuColumnTemplate let-item="item">
  <!-- //NOSONAR -->
  <li class="kt-menu__item">
    <h3 class="kt-menu__heading kt-menu__toggle">
      <span class="kt-menu__link-text" [translate]="item.heading.translate">
        {{ item.heading.title }}
      </span>
      <em class="kt-menu__ver-arrow la la-angle-right"></em>
    </h3>
    <ng-container *ngIf="item.items?.length">
      <ul class="kt-menu__inner">
        <ng-container *ngFor="let child of item.items">
          <ng-container
            [ngTemplateOutlet]="menuTemplate"
            [ngTemplateOutletContext]="{ item: child, parentItem: item }"
          >
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
  </li>
</ng-template>
