import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { CaiNumberFormatterPipe } from "../../pipes/number-formatter.pipe";
import { ShipmentDetail } from "../../core/_base/layout/pipes/shipment-detail.pipe";
import { CustomPipesModule } from "../../core/_pipes/pipe.module";
import { HeaderSearchComponent } from "./header-search.component";

@NgModule({
  "declarations": [HeaderSearchComponent],
  "imports": [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    CustomPipesModule,
    RouterModule,
  ],
  "exports": [HeaderSearchComponent, MatAutocompleteModule],
  "providers": [ShipmentDetail, CaiNumberFormatterPipe],
})
export class HeaderSearchModule {}
