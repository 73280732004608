<!-- begin:: Header Mobile -->
<div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
  <div class="kt-header-mobile__logo">
    <a (click)="handleLogoClick()" [class.pointer]="!isEmbedMode">
      <img alt="logo" [attr.src]="backgroundImage" height="50px"
    /></a>
  </div>
  <div class="kt-header-mobile__toolbar">
    <button
      [hidden]="!asideDisplay"
      class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
      id="kt_aside_mobile_toggler"
    >
      <span></span>
    </button>

    <kt-office-selector
      type="search"
      appearance="icon"
      *ngIf="isForwarder || isCargoWallet"
    ></kt-office-selector>
    <button
      *ngIf="isCargoWallet"
      class="kt-header-mobile__toolbar-toggler"
      id="kt_header_mobile_toggler"
    >
      <span></span>
    </button>
  </div>
</div>
<!-- end:: Header Mobile -->
