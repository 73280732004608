import { AppNotificationTypeEnum } from "@cai-services";

const mockedNotifications = [
  {
    "title": "Quote update @ CargoAi",
    "description": "Forwarder shipment to CDG on 28jan, 1kg GCR, 10237",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611633502000,
    "read": false,
    "seen": false,
    "parameters": [
      { "key": "date", "value": "2021-01-28 16:15" },
      { "key": "forwarderCompany", "value": "Forwarder" },
      {
        "key": "quoteLink",
        "value": "https://app.dev.cargoai.co/forwarder/quote/quote-detail/10237",
      },
      { "key": "airlineCompany", "value": "Singapore Airlines" },
      { "key": "origin", "value": "SIN" },
      { "key": "destination", "value": "CDG" },
      { "key": "dest", "value": "CDG" },
      { "key": "quoteStatus", "value": "ACCEPTED" },
      { "key": "space", "value": "1.0" },
      { "key": "price", "value": "3.00" },
      { "key": "currency", "value": "SGD" },
      { "key": "departureDate", "value": "28 jan" },
      { "key": "airlineLogo", "value": "SQ.png" },
      {
        "key": "forwarderLogo",
        "value": "08893163-dcfe-43f7-b9a8-4894aab3483e.jpeg",
      },
      { "key": "comments", "value": "" },
      { "key": "goodType", "value": "GCR" },
      { "key": "headerDepDate", "value": "28jan" },
      { "key": "quoteId", "value": "10237" },
      { "key": "flightNumber", "value": "SQ336" },
      { "key": "arrivalDate", "value": "29 jan" },
      { "key": "airlineUser", "value": "SQ Singapore Test" },
      { "key": "rejectedReason", "value": "" },
      { "key": "formattedTotalWeight", "value": "1" },
      { "key": "forwarderUser", "value": "Schenker User SIN" },
      { "key": "user", "value": "Schenker User SIN" },
      { "key": "awb", "value": "123-12345678" },
    ],
    "push": true,
  },
  {
    "title": "Booking 10097 confirmed",
    "description": "Forwarder shipment to NRT on 22jan 10kg MAIL ID 10097",
    "type": AppNotificationTypeEnum.AIRLINE_BOOKING_CONFIRMATION,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": [
      { "key": "forwarderCompany", "value": "Forwarder" },
      {
        "key": "quoteLink",
        "value": "https://app.dev.cargoai.co/forwarder/quote/quote-detail/10097",
      },
      { "key": "airlineCompany", "value": "Scoot" },
      { "key": "origin", "value": "SIN" },
      { "key": "destination", "value": "NRT" },
      { "key": "isShipmentScreened", "value": "No" },
      { "key": "price", "value": "45.00" },
      { "key": "forwarder_email", "value": "teamcargoai+Schenker_SIN@gmail.com" },
      { "key": "formattedUSWeight", "value": "10" },
      { "key": "currency", "value": "SGD" },
      { "key": "departureDate", "value": "22 jan" },
      { "key": "shcCodes", "value": "" },
      { "key": "headerDepartureDate", "value": "22jan" },
      { "key": "airlineLogo", "value": "TR.png" },
      {
        "key": "forwarderLogo",
        "value": "08893163-dcfe-43f7-b9a8-4894aab3483e.jpeg",
      },
      { "key": "comments", "value": "to be accepted" },
      { "key": "goodType", "value": "MAIL" },
      { "key": "awb", "value": "668-98569855" },
      { "key": "quoteId", "value": "10097" },
      { "key": "arrivalDate", "value": "23 jan" },
      { "key": "flightNumber", "value": "TR898" },
      { "key": "airlineUser", "value": "Scoot_SIN_jun" },
      { "key": "airlineDomain", "value": "https://app.dev.cargoai.co/" },
      { "key": "forwarderUser", "value": "Schenker User SIN" },
      { "key": "user", "value": "Schenker User SIN" },
    ],
    "push": true,
  },
  {
    "title": "Booking 10011 confirmed",
    "description": "Forwarder shipment to NRT on 22jan 10kg MAIL ID 10011",
    "type": AppNotificationTypeEnum.AIRLINE_BOOKING_CONFIRMATION,
    "link": "test",
    "user": "1",
    "timestamp": 1611630928000,
    "read": false,
    "seen": true,
    "parameters": null,
    "push": true,
  },
  {
    "title": "New chat message",
    "description": "",
    "type": AppNotificationTypeEnum.QUOTE_CHAT_MESSAGE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "originAirport": { "airportCode": "BKK" },
        "destinationAirport": { "airportCode": "SIN" },
        "trips": [
          {
            "startDepartureDate": Date.parse("10 Jul 2021"),
          },
        ],
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update @ CargoAi",
    "description": "",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "SenderType": "AIRLINE",
      "current": {
        "quoteStatus": "ACCEPTED",
        "originAirport": { "airportCode": "BKK" },
        "destinationAirport": { "airportCode": "SIN" },
        "trips": [
          {
            "startDepartureDate": Date.parse("10 Jul 2021"),
            "flights": [
              {
                "originRegionAirport": {
                  "airportCode": "SIN",
                },
                "destRegionAirport": {
                  "airportCode": "MAA",
                },
              },
              {
                "originRegionAirport": {
                  "airportCode": "MAA",
                },
                "destRegionAirport": {
                  "airportCode": "NRT",
                },
              },
            ],
          },
        ],
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
        "quoteRequest": {
          "forwarder": {
            "firstName": "Lee",
            "company": {
              "companyName": "cargo flights",
            },
          },
          "quoteItems": [
            {
              "typeOfProduct": {
                "productCode": "DGR",
              },
            },
          ],
        },
      },
    },
    "push": true,
  },
  {
    "title": "Booking request @ CargoAi",
    "description": "",
    "type": AppNotificationTypeEnum.BOOKING_CHAT_MESSAGE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "SenderType": "FORWARDER",
      "current": {
        "quoteStatus": "ACCEPTED",
        "originAirport": { "airportCode": "BKK" },
        "destinationAirport": { "airportCode": "SIN" },
        "trips": [
          {
            "startDepartureDate": Date.parse("10 Jul 2021"),
            "flights": [
              {
                "originRegionAirport": {
                  "airportCode": "SIN",
                },
                "destRegionAirport": {
                  "airportCode": "MAA",
                },
              },
              {
                "originRegionAirport": {
                  "airportCode": "MAA",
                },
                "destRegionAirport": {
                  "airportCode": "NRT",
                },
              },
            ],
          },
        ],
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
        "quoteRequest": {
          "forwarder": {
            "firstName": "Lee",
            "company": {
              "companyName": "cargo flights",
            },
          },
          "quoteItems": [
            {
              "typeOfProduct": {
                "productCode": "DGR",
              },
            },
          ],
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update - Rate Changed",
    "description": "notification-rate-update",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update - Route Changed",
    "description": "notification-flight-number-update",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update - Validity Changed",
    "description": "notification-quote-validity-update",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update @ CargoAi",
    "description": "",
    "type": AppNotificationTypeEnum.QUOTE_ACCEPT,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "quoteStatus": "ACCEPTED",
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update @ CargoAi",
    "description": "",
    "type": AppNotificationTypeEnum.QUOTE_REJECT,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "quoteStatus": "REJECTED",
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update @ CargoAi",
    "description": "",
    "type": AppNotificationTypeEnum.BOOKING_REJECT,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "quoteStatus": "BOOKING_REJECTED",
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update - Cargo Changed",
    "description": "notification-weight-volume-pieces-update",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
  {
    "title": "Quote update - AWB Changed",
    "description": "",
    "type": AppNotificationTypeEnum.QUOTE_UPDATE,
    "link": "test",
    "user": "1",
    "timestamp": 1611630924,
    "read": false,
    "seen": false,
    "parameters": {
      "current": {
        "airlineBrand": {
          "airlineCompanyId": 29,
          "airlineCompanyName": "Philippine Airlines",
          "airlineCompanyCode": "PR",
        },
      },
    },
    "push": true,
  },
];

export { mockedNotifications };
