import { MeasurementSystemEnum, UserStatusEnum } from "@cai-services";
import { Observable, Subject } from "rxjs";
import { AIRLINE_GROUP } from "../../core/_constants/constants";
import { AircraftCategory } from "../../core/_enums/aircraft-enum";
import { Aircraft } from "../../core/_models/aircraft.model";
import { AirlineBrand } from "../../core/_models/airline.model";
import { AirportLight } from "../../core/_models/airport-light.model";
import { Company } from "../../core/_models/company.model";
import { Office } from "../../core/_models/office.model";
import { Airport } from "../../core/_models/region-airport.model";
import { TypeOfProduct } from "../../core/_models/type-of-product.model";
import { SessionService } from "../../core/_services/session.service";
import { mockedAirlineCompanies } from "../data/airlines";
import { mockedFlight, mockedUnfinishedBookings } from "../data/flights";
import { mockedAirportLight } from "../data/region-airport";
import { mockedSearchTemplate } from "../data/search-template";
import { mockedOffices } from "./company-service-stub";
import { listProducts } from "./global-service-stub";

export const mockedUser = {
  "userId": 1,
  "type": "FORWARDER",
  "userName": "Forwarder Singapore",
  "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
  "userStatus": UserStatusEnum.ACTIVATED,
  "logoPath": "user1.gif",
  "language": "en",
  "contact": {
    "countryCode": "33",
    "cityCode": "",
    "phoneNumber": "",
  },
  "defaultSearchDay": 4,
  "smtp": "",
  "pima": "pimatest",
  "defaultCurrency": "SGD",
  "country": {
    "countryCode": "SG",
    "countryName": "Singapore",
    "states": [],
  },
  "company": {
    "id": 128,
    "companyCode": "Forwarder",
    "companyName": "Forwarder",
    "companyType": "FORWARDER",
    "users": [],
    "airlineBrands": [],
  } as any,
  "mostLikelyOriginAirport": "SIN",
  "originAirport": {
    "airportCode": "SIN",
    "airportName": "Changi",
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
  } as Airport,
  "timeOfSearch": 5,
  "bookingType": "AUTO_BOOK_IN_ORDER",
  "searchTemplates": [
    {
      "searchTemplateId": 6,
      "isSearch": true,
      "isHistory": false,
      "searchDate": new Date(1593503816373),
      "originAirport": "SIN",
      "destAirport": "ZRH",
      "typeOfProduct": {
        "productCode": "GCR",
      } as TypeOfProduct,
      "office": {
        "id": 1,
      } as Office,
      "searchTemplateType": "AIRPORT_TO_AIRPORT",
      "searchItemTemplates": null,
      "craftType": AircraftCategory.ALL_AIRCRAFTS,
      "specialHandlingCode": "GEN",
      "volume": 10,
      "weight": 100,
      "chargeableWeight": 100,
      "totalVolume": 10,
      "totalWeight": 100,
      "measurementSystem": MeasurementSystemEnum.METRIC,
    },
    {
      "searchTemplateId": 72,
      "isSearch": true,
      "isHistory": false,
      "searchDate": new Date(1593503816373),
      "originAirport": "SIN",
      "destAirport": "FRA",
      "typeOfProduct": "GCR",
      "office": {
        "id": 1,
      } as Office,
      "searchTemplateType": "AIRPORT_TO_AIRPORT",
      "searchItemTemplates": null,
      "craftType": AircraftCategory.ALL_AIRCRAFTS,
      "specialHandlingCode": "GEN",
      "volume": 10,
      "weight": 100,
      "chargeableWeight": 100,
      "totalVolume": 10,
      "totalWeight": 100,
      "measurementSystem": MeasurementSystemEnum.METRIC,
    },
  ],
  "class": "co.cargoai.core.entity.Forwarder",
  "noIataCass": false,
  "measurementSystem": MeasurementSystemEnum.METRIC,
} as any;

export const currentAirline = {
  "type": "AIRLINE",
  "authenticationMethod": "COGNITO",
  "userId": 15,
  "userName": "Singapore Airlines",
  "userEmail": "teamcargoai+SQ_SIN@gmail.com",
  "userStatus": "ACTIVATED",
  "company": {
    "id": 7,
    "airlineBrands": [
      {
        "airlineCompanyId": 7,
        "airlineCompanyCode": "SQ",
      },
    ],
  },
  "originAirport": {
    "airportCode": "SIN",
  },
  "contact": {
    "countryCode": "FR",
  },
  "defaultCurrency": "EUR",
};

const currentUser = {
  "type": "FORWARDER",
  "userId": 1,
  "userName": "Fowarder User ",
  "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
  "lastName": "User ",
  "firstName": "Fowarder",
  "contact": { "countryCode": "th", "cityCode": "", "phoneNumber": "+66614614800" },
  "userStatus": "ACTIVATED",
  "logoPath": "user1.png",
  "smtp": "",
  "pima": "pimatest",
  "defaultCurrency": "SGD",
  "authenticationMethod": "COGNITO",
  "company": {
    "id": 128,
    "companyCode": "Forwarder",
    "companyName": "Forwarder",
    "companyType": "FORWARDER",
    "emailDomain": "cargoai.co",
    "logoHash": "08893163-dcfe-43f7-b9a8-4894aab3483e",
    "logoType": "jpeg",
    "airlineBrands": [
      {
        "airlineCompanyId": 7,
        "airlineCompanyCode": "SQ",
      },
    ],
  },
  "country": { "countryCode": "SG", "countryName": "Singapore" },
  "originAirport": {
    "airportCode": "SIN",
    "airportName": "Changi",
    "regionCode": "ASPAC",
    "regionName": "Asia Pacific",
    "city": {
      "cityId": 1455,
      "cityCode": "SIN",
      "cityName": "Singapore",
      "state": {
        "stateId": 266,
        "stateName": "Singapore",
        "stateCode": "SG",
        "country": {
          "countryCode": "SG",
          "countryName": "Singapore",
        },
      },
    },
  },
  "iataCode": "2047049",
  "cassCode": "9512",
  "timeOfSearch": 5,
  "bookingType": "AUTO_BOOK_IN_ORDER",
  "measurementSystem": MeasurementSystemEnum.METRIC,
  "language": "ENG",
},

 aircraftList = [
  {
    "aircraftName": "Airbus A330 Freighter",
    "aircraftCode": "33F",
    "capacity": 65000,
    "aircraftType": {
      "aircraftTypeId": 1,
      "aircraftTypeName": "Freighter",
      "dimensionLength": 480,
      "dimensionWidth": 240,
      "dimensionHeight": 240,
    },
  },
  {
    "aircraftCode": "74Y",
    "aircraftName": "Boeing 747-400F (Freighter)",
    "aircraftType": {
      "aircrafts": [],
      "aircraftTypeId": 1,
      "aircraftTypeName": "Freighter",
      "dimensionHeight": 240,
      "dimensionLength": 480,
      "dimensionWidth": 240,
    },
    "capacity": 113000,
  },
] as Aircraft[],
 a = () => {},

 airlineCompany = {
  "airlineCompanyId": 7,
  "airlineCompanyName": "Singapore Airlines",
  "airlineCompanyCode": "SQ",
  "awbPrefix": "618",
} as AirlineBrand;

export const sessionServiceStub = (
  isAirline?: boolean
): Partial<SessionService> => ({
    "getCurrentUser": () => {
      if (isAirline) {
        return currentAirline;
      }
      return currentUser as any;
    },

    "getCurrentUserGroup": (): Promise<string> => new Promise((resolve) => {
        setTimeout(() => {
          resolve(AIRLINE_GROUP);
        });
      }),

    "getAirports": (): AirportLight[] => mockedAirportLight,
    "getDirectBookFlight": () => mockedFlight as any,
    "setDirectBookFlight": () => {},
    "getRequestBookFlight": () => mockedFlight as any,
    "setRequestBookFlight": () => {},
    "getSearchOfficeId": () => null,
    "getSearchOffice": () => null,
    "setSearchOfficeId": () => {},
    "getSelectedOfficeId": () => null,
    "getSelectedOffice": () => mockedOffices[0],
    "setSelectedOffice": (office: Office) => {},
    "setSelectedLibraryTab": () => null,
    "getSelectedLibraryTab": () => null,
    "setSelectedAllotmentTab": () => null,
    "getSelectedAllotmentTab": () => null,
    "getItemSessionStorage": (key: string) => {
      if (key === "browserRefreshed") {
        return false;
      }
      if (key.includes("selectedRange")) {
        return "all";
      }
      return "";
    },
    "removeItemSessionStorage": (key: string) => {},
    "setItemSessionStorage": (key: string, data: any) => {},
    "setCurrentUser": (user) => {},
    "setCurrentUserCompany": (company: Company) => {},
    "getSearchTemplate": () => mockedSearchTemplate,
    "getAverageCo2OfMostRecentSearchResult": (): number => 0.0,
    "getBrowserKey": () => "browser-key",
    "watchStorage": () => new Observable(),
    "watchSessionStorage": () => new Observable(),
    "setIsLogin": (bool: boolean) => {},
    "getIsLogin": () => true,
    "setAirports": (regionAirports) => {},
    "setOriginName": (fullOriginName) => ({}),
    "setDestinationName": (fulldestinationName) => ({}),
    "setShipmentDate": (dropOffDate) => ({}),
    "setSearchTemplate": (searchTemplate) => ({}),
    "setAverageCo2OfMostRecentSearchResult": (_: number) => ({}),
    "setUUIDSearch": (getSearchUUID) => ({}),
    "prepareBookingScheduler": () => {},
    "getUnfinishedBookings": () => mockedUnfinishedBookings,
    "addUnfinishedBookings": () => {
      alert("unfinished book is added");
    },
    "getUUIDSearch": () => mockedFlight.searchUUID,
    "getAirlineCompanies": () => mockedAirlineCompanies as any,
    "getTypeOfProducts": () => listProducts,
    "getAircrafts": () => aircraftList,
    "getShipmentDate": () => "2020-04-25",
    "getOriginName": () => "",
    "getDestinationName": () => "",
    "getChosenEmailsByAirlineCompany": () => [],
    "setChosenEmailsByAirlineCompany": () => "",
    "setRememberMeLogin": () => undefined,
    "getRememberMeLogin": () => true,
    "isRememberMeSet": () => true,
    "getSpecialHandlingCodes": () => [],
    "getCurrentAirlineCompany": () => airlineCompany,
    "setCurrentAirlineCompany": () => undefined,
    "setSelectedLoadTypeMode": () => {},
    "getSelectedLoadTypeMode": () => null,
    "getQuoteLastViewDate": () => new Date("2011-11-11"),
    "updateQuoteLastViewDate": (quoteId: number) => {},
    "setBannerDisplayInfo": () => {},
    "getBannerDisplayInfo": () => ({ "userId": 2, "lastShown": "2021-08-23" }),
    "getLastCo2ChartGeneratedInfo": () => ({ "userId": 1, "lastShown": "2023-02-23" }),
    "removeBannerDisplayInfo": () => {},
    "sameRouteNotificationQuoteSelected": new Subject<number>(),
    "getSelectedLanguage": () => null,
    "setSelectedLanguage": () => {},
    "getNotQuotableAirlines": () => null,
    "setNotQuotableAirlines": () => {},
    "officeChangedStatusObs": new Observable<boolean>(),
    "walletCurrencyObs": new Observable<{ flag: boolean; currency: any }>(),
    "clearLocalStorage": () => {},
  });
