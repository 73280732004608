import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BannerIagCargoComponent } from "./banner-iag-cargo/banner-iag-cargo.component";
import { BannerIagEtihadCargoComponent } from "./banner-iag-etihad-cargo/banner-iag-etihad-cargo.component";
import { BannerGermanyComponent } from "./banner-germany/banner-germany.component";
import { BannerNewYearComponent } from "./banner-new-year/banner-new-year.component";
import { BannerMasComponent } from "./banner-mas/banner-mas.component";
import { RewardsBannerComponent } from "./rewards-banner/rewards-banner.component";

@NgModule({
  "declarations": [
    BannerIagCargoComponent,
    BannerIagEtihadCargoComponent,
    BannerGermanyComponent,
    BannerNewYearComponent,
    BannerMasComponent,
    RewardsBannerComponent,
  ],
  "imports": [CommonModule, MatTooltipModule],
  "exports": [RewardsBannerComponent],
})
export class BannersModule {}
