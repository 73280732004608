import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import moment from "moment";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimeRangeEnum } from "../../core/_enums/time-range-enum";
import { SessionService } from "../../core/_services/session.service";
import { DATE_FORMAT_WITH_TIMEZONE } from "../../core/_constants/constants";

@Component({
  "standalone": true,
  "selector": "common-time-range",
  "templateUrl": "./time-range.component.html",
  "styleUrls": ["./time-range.component.scss"],
  "imports": [CommonModule, FormsModule, ReactiveFormsModule],
})
export class TimeRangeComponent implements OnInit {
  TimeRangeEnum = TimeRangeEnum;
  @Input() selectedRange: TimeRangeEnum;
  @Output() onTimeRangeChange: EventEmitter<any> = new EventEmitter();
  isFrench: boolean;

  constructor (
    public sessionService: SessionService
  ) {}

  ngOnInit () {
    if (!this.selectedRange) {
      this.selectedRange = TimeRangeEnum.ALL;
    }
    this.isFrench = window.location.href?.indexOf("/fr/") >= 0;

    if (
      this.selectedRange &&
      this.sessionService.getItemSessionStorage("browserRefreshed") === false
    ) {
      this.onRangeChange(this.selectedRange);
    }
  }

  getTimeRangeByOption (
    query: string,
    key?: string
  ): { fromDate: string; toDate: string; key: string } {
    if (!query) {
      return { "fromDate": undefined, "toDate": undefined, key };
    }
    const firstDayOfMonth = moment().startOf("month"),
     lastDayOfMonth = moment().endOf("month"),
     firstDayOfWeek = moment().startOf("week"),
     lastDayOfWeek = moment().endOf("week"),
     twoDaysBefore = moment().subtract(2, "day"),
     today = moment();
    switch (query) {
      case TimeRangeEnum.URGENT:
        return {
          "fromDate": twoDaysBefore
            .startOf("day")
            .format(DATE_FORMAT_WITH_TIMEZONE),
          "toDate": today.endOf("day").format(DATE_FORMAT_WITH_TIMEZONE),
          key,
        };

      case TimeRangeEnum.WEEK:
        return {
          "fromDate": firstDayOfWeek
            .startOf("day")
            .format(DATE_FORMAT_WITH_TIMEZONE),
          "toDate": lastDayOfWeek.endOf("day").format(DATE_FORMAT_WITH_TIMEZONE),
          key,
        };

      case TimeRangeEnum.MONTH:
        return {
          "fromDate": firstDayOfMonth
            .startOf("day")
            .format(DATE_FORMAT_WITH_TIMEZONE),
          "toDate": lastDayOfMonth.endOf("day").format(DATE_FORMAT_WITH_TIMEZONE),
          key,
        };
      case TimeRangeEnum.ALL:
      default:
        return { "fromDate": undefined, "toDate": undefined, key };
    }
  }
  onRangeChange (event: TimeRangeEnum) {
    this.onTimeRangeChange.emit(
      this.getTimeRangeByOption(event, this.selectedRange)
    );
  }
}
