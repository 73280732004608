import { LoadTypeEnum, PackingTypeEnum, WeightTypeEnum } from "@cai-services";
import { Dimension } from "../../core/_models/dimension.model";
import { MeasurementUnits } from "../../core/_models/measurement-system.model";
import { TypeOfProduct } from "../../core/_models/type-of-product.model";

const mockedDimensions = [
  {
    "quoteItemId": 0,
    "quoteRequest": null,
    "numOfItems": 1,
    "weight": 10,
    "dimensionLength": 100,
    "dimensionWidth": 100,
    "dimensionHeight": 100,
    "packing": PackingTypeEnum.NONE,
    "weightType": WeightTypeEnum.PER_ITEM,
    "typeOfProduct": new TypeOfProduct(),
    "dimensionsUnit": MeasurementUnits.METRIC.MEASURE.display,
    "weightUnit": MeasurementUnits.METRIC.WEIGHT.display,
    "volume": undefined,
    "uldType": undefined,
    "loadType": LoadTypeEnum.TOTAL,
    "isNewRow": false,
    "isTouched": false,
    "isFocused": false,
    "errors": [],
  } as Dimension,
];
export { mockedDimensions };
