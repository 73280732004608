// Angular
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserTypeEnum } from "@cai-services";
import { APP_PROPS } from "../../../cai-common.module";
import { ApplicationProperties } from "../../../core/_base/layout/models/app-properties.model";
import { LayoutConfigService } from "../../../core/_base/layout/services/layout-config.service";
import { User } from "../../../core/_models/user.model";
import { SessionService } from "../../../core/_services/session.service";
import { BrandIconUtil } from "../../../utils/brand.util";
import { WalletUtil } from "../../../utils/wallet.util";
// Layout

@Component({
  "selector": "kt-header-mobile",
  "templateUrl": "./header-mobile.component.html",
  "styleUrls": ["./header-mobile.component.scss"],
})
export class HeaderMobileComponent implements OnInit {
  homeURL: string;
  headerLogo: string;
  asideDisplay: boolean;
  currentUser: User;
  isEmbedMode: boolean;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly layoutConfigService: LayoutConfigService,
    private readonly sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.currentUser = this.sessionService.getCurrentUser();
  }

  async ngOnInit () {
    this.headerLogo = this.appProperties.logo;
    this.asideDisplay =
      this.layoutConfigService.getConfig("aside.self.display");
    this.route.queryParams.subscribe(async (params) => {
      this.isEmbedMode = WalletUtil.isEmbedMode(this.appProperties, params);
    });
    await this.checkHomeUrl();
  }

  async checkHomeUrl () {
    const currentGroup = await this.sessionService.getCurrentUserGroup();
    if (!currentGroup) {
      return;
    }
    const userMenu = this.appProperties.menu.find((config) =>
      currentGroup.includes(config.userType.toLowerCase()),
    );
    if (userMenu) {
      const user = this.sessionService.getCurrentUser();
      if (user?.type === UserTypeEnum.AIRLINE && !!user?.activatedFeatures?.find((af)=>af.featureName==="INTERLINE")) {
        this.homeURL = userMenu.secondDefaultPage;
        return;
      }
      this.homeURL = userMenu.defaultPage;
    }
  }

  handleLogoClick (): void {
    if (this.isEmbedMode) {
      return;
    }
    if (this.router.url.slice(1, this.router.url?.length) !== this.homeURL) {
      this.router.navigateByUrl(this.homeURL);
    }
  }

  get backgroundImage (): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.headerLogo,
      "/assets/media/logos/",
    );
  }

  get isForwarder (): boolean {
    return this.currentUser?.type === UserTypeEnum.FORWARDER;
  }

  get isCargoWallet (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
