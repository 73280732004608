<!-- begin:: Brand -->
<div
  class="kt-header__brand kt-grid__item"
  [ngClass]="htmlClassService.getClasses('brand', true)"
  id="kt_header_brand"
>
  <div class="kt-header__brand-logo">
    <!-- <a href="javascript:;" routerLink="forwarder/search/forwarder-search">
			<img alt="logo" [attr.src]="headerLogo"/>
		</a> -->
    <span [class.pointer]="!isEmbedMode">
      <ng-container *ngIf="!shouldDisplayProPlan">
        <img
          alt="logo"
          [attr.src]="backgroundImage"
          height="50px"
          (click)="handleLogoClick()"
        />
      </ng-container>

      <ng-container *ngIf="shouldDisplayProPlan">
        <img
          class="logo"
          alt="logo"
          [attr.src]="backgroundImage"
          (click)="handleLogoClick()"
        />
        <img
          alt="proTag"
          class="protag-img"
          (click)="showUpgradeMessage()"
          src="/assets/media/icons/pro-tag-for-logo.png"
        />
      </ng-container>
    </span>
  </div>
</div>
<!-- end:: Brand -->
