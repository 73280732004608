<div
  class="kt-login__body d-flex flex-column flex-xl-row-reverse align-items-center align-items-xl-stretch"
  (window:resize)="onResize()"
>
  <!--begin::Signin-->
  <div class="kt-login__form">
    <kt-congratulation
      *ngIf="
        (userType == UserTypeEnum.FORWARDER ||
          (userType == UserTypeEnum.AIRLINE && isActivated)) &&
        success &&
        !isLoading
      "
      (onNext)="onNextClick()"
    >
    </kt-congratulation>
    <kt-manual-complete
      *ngIf="
        (userType == UserTypeEnum.STANDARD && success) ||
        (userType == UserTypeEnum.AIRLINE &&
          success &&
          !isActivated &&
          !isLoading)
      "
      (onNext)="onNextClick()"
    >
    </kt-manual-complete>
    <div *ngIf="!success" class="registration-card d-flex flex-column">
      <div
        *ngIf="registerForm"
        [@openClose]="isLoading ? 'open' : 'closed'"
        class="text-center fade-in"
      >
        <img
          class="d-block ml-auto mr-auto"
          src="/assets/animation/loading.gif"
          alt="loading-animation"
          width="170px"
          height="170px"
          loading="lazy"
        />
        <h5 i18n="@@registration.complete.text-verify-credentials">
          We are verifying your credentials. Do not close this page.
        </h5>
        <kt-registration-progress-bar
          [disableCompanyVerification]="true"
          [isCargoWallet]="isCargoWallet"
        ></kt-registration-progress-bar>
        <div
          class="d-flex justify-content-center align-items-center mt-4"
          style="gap: 16px"
        >
          <a href="/">
            <div
              class="signup-logo"
              [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
            ></div>
          </a>
          <img
            *ngIf="referrer?.logoUrl"
            [src]="referrer.logoUrl"
            alt="referrer logo"
            loading="lazy"
            style="max-width: 40%"
          />
        </div>
      </div>
      <div *ngIf="!isLoading">
        <div>
          <div class="mb-4 header-section">
            <h3
              *ngIf="userType === UserTypeEnum.FORWARDER"
              [class.wallet-black]="isCargoWallet"
              i18n="@@registration.complete.text-hello-forwarder"
            >
              Hello Forwarder User!
            </h3>
            <h3
              *ngIf="userType === UserTypeEnum.AIRLINE"
              [class.wallet-black]="isCargoWallet"
              i18n="@@registration.complete.text-hrllo-airline"
            >
              Hello Airline User!
            </h3>
            <h3
              *ngIf="userType === UserTypeEnum.STANDARD"
              [class.wallet-black]="isCargoWallet"
              i18n="@@registration.complete.text-hrllo-airline"
            >
              Hello {{ user?.subType | titlecase }} User!
            </h3>
            <h6
              class="mb-3"
              [class.wallet-black]="isCargoWallet"
              i18n="@@registration.complete.text-register-your-company"
            >
              Register your company to login
            </h6>
            <kt-registration-progress-bar
              [isCargoWallet]="isCargoWallet"
            ></kt-registration-progress-bar>
          </div>
        </div>
        <div class="pt-4 registration-container">
          <form
            [class.overflow-hidden]="!isFormOpened"
            *ngIf="registerForm"
            [formGroup]="registerForm"
          >
            <div *ngIf="registerForm.controls.airlineName" class="form-group">
              <label
                for="airlineName"
                required
                i18n="@@global.airline-name"
                [class.wallet-black-label]="isCargoWallet"
                >Airline Name</label
              >
              <mat-chip-grid
                #chipList
                aria-label="Airline selection"
                class="chip-grid"
              >
                <div class="mat-chip-container" *ngIf="company">
                  <mat-chip-row [removable]="true" (removed)="removeAirline()">
                    <div class="company-name" [title]="company.companyName">
                      {{ company.companyName }}
                    </div>
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip-row>
                </div>
                <input
                  #airlineName
                  type="text"
                  formControlName="airlineName"
                  autocomplete="off"
                  [readonly]="company"
                  [class.ml-0]="isCargoWallet"
                  [class.invalid-wallet-border]="isCargoWallet"
                  [class.invalid]="
                    isControlHasError(
                      registerForm,
                      'airlineName',
                      'mustBeInList'
                    ) || errorMessage('airlineName')
                  "
                  [class.valid]="
                    registerForm.controls.airlineName.valid &&
                    registerForm.controls.airlineName.dirty
                  "
                  class="form-control company-name"
                  id="airlineName"
                  [matAutocomplete]="airlineNameAutocomplete"
                  [matChipInputFor]="chipList"
                />
              </mat-chip-grid>
              <span
                class="error-msg d-flex"
                *ngIf="errorMessage"
                [class.wallet-error-msg]="isCargoWallet"
              >
                {{ errorMessage("airlineName") }}
              </span>
              <mat-autocomplete
                #airlineNameAutocomplete="matAutocomplete"
                [displayWith]="displayCompaniesFn"
                (optionSelected)="onSelectAirline($event)"
              >
                <mat-option
                  *ngFor="let company of filteredCompanies | async"
                  (onSelectionChange)="changeAirline(company.companyName)"
                  [value]="company"
                >
                  {{ company?.companyName }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                class="error-msg"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="
                  isControlHasError(registerForm, 'airlineName', 'mustBeInList')
                "
                i18n="@@registration.select-a-company"
              >
                Please select a company from the list or contact us
              </mat-error>
              <span
                class="error-msg d-flex"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="errorMessage"
              >
                {{ errorMessage("companyName") }}
              </span>
            </div>
            <div
              *ngIf="registerForm.controls.fullCompanyName"
              class="form-group"
              [class.new-company]="isCompanyNew"
            >
              <label
                for="fullCompanyName"
                required
                i18n="@@global.full-company-name"
                [class.wallet-black-label]="isCargoWallet"
                >Full Company Name</label
              >
              <mat-chip-grid
                #chipList
                aria-label="Company selection"
                class="chip-grid"
              >
                <div class="mat-chip-container" *ngIf="company">
                  <mat-chip-row [removable]="true" (removed)="removeCompany()">
                    <div class="company-name" [title]="company.companyName">
                      {{ company.companyName }}
                    </div>
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip-row>
                </div>
                <input
                  type="text"
                  formControlName="fullCompanyName"
                  #fullCompanyName
                  autocomplete="off"
                  [readonly]="company"
                  [class.invalid-wallet-border]="isCargoWallet"
                  [class.invalid]="errorMessage('fullCompanyName')"
                  [class.hide-value]="company?.companyName"
                  [class.valid]="
                    registerForm.controls.fullCompanyName.valid &&
                    registerForm.controls.fullCompanyName.dirty
                  "
                  class="form-control company-name"
                  id="fullCompanyName"
                  [matAutocomplete]="airlineAutocomplete"
                  (focusout)="onFullCompanyNameFocusOut()"
                  [matChipInputFor]="chipList"
                />
              </mat-chip-grid>
              <mat-autocomplete
                #airlineAutocomplete="matAutocomplete"
                [displayWith]="displayCompaniesFn"
                (optionSelected)="onSelectCompany($event)"
              >
                <mat-option
                  *ngFor="let company of filteredCompanies | async"
                  [value]="company"
                >
                  {{ company?.companyName }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                class="error-msg d-flex"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="errorMessage && !isNotAllowedStandardCompany"
              >
                <strong i18n="@@global.text-error-required-field">
                  {{ errorMessage("fullCompanyName") }}
                </strong>
              </mat-error>
              <mat-error
                class="error-msg d-flex"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="isNotAllowedStandardCompany"
              >
                <strong i18n="@@global.text-error-duplicate-field">
                  This company name already exists as
                  {{ this.company?.companyType }} type. Please contact our
                  support via the live chat and we will help you complete your
                  registration
                </strong>
              </mat-error>
              <p
                *ngIf="
                  isCompanyNew &&
                  !isFullCompanyNameInUse &&
                  !errorMessage('fullCompanyName')
                "
                i18n="@@registration.complete.text-unknown-company"
              >
                Looks like we don’t know your company yet. It will be registered
                as new.
              </p>
              <p
                *ngIf="isCompanyNew && !errorMessage('fullCompanyName')"
                i18n="@@registration.complete.text-pick-existing-company"
                class="warning-msg"
              >
                For quicker verification, pick an existing company.
              </p>
            </div>
            <div
              *ngIf="registerForm.controls.GSACompanyName"
              class="form-check mb-4"
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="gsa"
                formControlName="isGSA"
                (change)="onIsGSAChanged()"
              />
              <label
                class="form-check-label ml-2"
                for="gsa"
                required
                i18n="@@registration.complete.text-representing-airline"
                >I am representing this airline as a GSA</label
              >
            </div>
            <div
              *ngIf="
                registerForm.controls.isGSA && registerForm.controls.isGSA.value
              "
              class="form-group"
            >
              <label
                for="GSACompanyName"
                required
                i18n="@@global.gsa-company-name"
                [class.wallet-black-label]="isCargoWallet"
                >GSA Company Name</label
              >
              <input
                type="text"
                formControlName="GSACompanyName"
                autocomplete="off"
                [class.invalid-wallet-border]="isCargoWallet"
                [class.invalid]="errorMessage('GSACompanyName')"
                [class.valid]="
                  registerForm.controls.GSACompanyName.valid &&
                  registerForm.controls.GSACompanyName.dirty
                "
                class="form-control"
                id="GSACompanyName"
                [matAutocomplete]="gsaAutocomplete"
                (focusout)="onGSACompanyNameFocusOut()"
              />
              <mat-autocomplete
                #gsaAutocomplete="matAutocomplete"
                [displayWith]="displayCompaniesFn"
              >
                <mat-option
                  *ngFor="let company of filteredGsa | async"
                  [value]="company"
                >
                  {{ company?.companyName }}
                </mat-option>
              </mat-autocomplete>
              <span
                class="error-msg d-flex"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="errorMessage('GSACompanyName')"
              >
                {{ errorMessage("GSACompanyName") }}
              </span>
              <p
                *ngIf="
                  isGSANew &&
                  !isGSACompanyNameInUse &&
                  !errorMessage('GSACompanyName')
                "
                i18n="@@registration.complete.text-will-create-gsa"
              >
                This GSA Company will be created for you.
              </p>
            </div>
            <div class="d-flex flex-row">
              <div
                class="form-group w-50 pr-1"
                *ngIf="!(userType === UserTypeEnum.STANDARD)"
              >
                <label
                  for="airportCode"
                  required
                  i18n="@@global.airport-code"
                  [class.wallet-black-label]="isCargoWallet"
                  >Airport Code</label
                >
                <input
                  type="text"
                  formControlName="airportCode"
                  class="form-control"
                  id="airportCode"
                  [class.invalid-wallet-border]="isCargoWallet"
                  [class.invalid]="errorMessage('airportCode')"
                  [matAutocomplete]="airportAutocomplete"
                  (focusout)="onAirportFocusOut($event)"
                />
                <mat-autocomplete
                  #airportAutocomplete="matAutocomplete"
                  [displayWith]="displayAirportsFn"
                >
                  <mat-option
                    *ngFor="let airport of filteredAirports | async"
                    [value]="airport"
                    (click)="onAirportChanged(airport?.airportCode)"
                  >
                    {{ airport?.airportCode }}
                  </mat-option>
                </mat-autocomplete>
                <span
                  class="error-msg d-flex"
                  [class.wallet-error-msg]="isCargoWallet"
                  *ngIf="errorMessage"
                >
                  {{ errorMessage("airportCode") }}
                </span>
              </div>
              <div
                class="form-group w-50 pr-1"
                *ngIf="userType === UserTypeEnum.STANDARD"
              >
                <label
                  for="city"
                  required
                  i18n="@@global.city"
                  [class.wallet-black-label]="isCargoWallet"
                  >City</label
                >
                <input
                  type="text"
                  formControlName="city"
                  autocomplete="off"
                  class="form-control"
                  id="city"
                  [matAutocomplete]="cityAutocomplete"
                  [class.invalid-wallet-border]="isCargoWallet"
                  [class.invalid]="errorMessage('city')"
                  (focusout)="onCityFocusOut($event)"
                />
                <mat-autocomplete
                  #cityAutocomplete="matAutocomplete"
                  [displayWith]="displayCityFn"
                  [panelWidth]="'min-content'"
                >
                  <mat-option
                    *ngFor="let city of filteredCity | async"
                    (click)="onCityChanged(city.cityName)"
                    [value]="city"
                    class="text-nowrap-child"
                  >
                    <div
                      class="iti__flag d-inline-block"
                      [ngClass]="
                        'iti__' + city.state.country.countryCode?.toLowerCase()
                      "
                    ></div>
                    {{ city.cityName }}
                  </mat-option>
                </mat-autocomplete>
                <span
                  class="error-msg d-flex"
                  [class.wallet-error-msg]="isCargoWallet"
                  *ngIf="errorMessage"
                >
                  {{ errorMessage("city") }}
                </span>
              </div>
              <div class="form-group w-50 pr-1">
                <label
                  for="country"
                  required
                  i18n="@@global.country"
                  [class.wallet-black-label]="isCargoWallet"
                  >Country</label
                >
                <input
                  type="text"
                  formControlName="country"
                  autocomplete="off"
                  class="form-control"
                  id="country"
                  [matAutocomplete]="countryAutocomplete"
                  [class.invalid-wallet-border]="isCargoWallet"
                  [class.invalid]="errorMessage('country')"
                  (focusout)="onCountryFocusOut($event)"
                />
                <mat-autocomplete
                  #countryAutocomplete="matAutocomplete"
                  [displayWith]="displayCountriesFn"
                >
                  <mat-option
                    *ngFor="let country of filteredCountries | async"
                    (click)="onCountryChanged(country)"
                    [value]="country"
                  >
                    {{ country }}
                  </mat-option>
                </mat-autocomplete>
                <span
                  class="error-msg d-flex"
                  [class.wallet-error-msg]="isCargoWallet"
                  *ngIf="errorMessage"
                >
                  {{ errorMessage("country") }}
                </span>
              </div>
            </div>
            <div
              class="form-group w-50 pr-1"
              *ngIf="userType === UserTypeEnum.FORWARDER"
            >
              <label
                for="city"
                required
                i18n="@@global.city"
                [class.wallet-black-label]="isCargoWallet"
                >City</label
              >
              <input
                type="text"
                formControlName="city"
                autocomplete="off"
                class="form-control"
                id="city"
                [matAutocomplete]="cityAutocomplete"
                [class.invalid-wallet-border]="isCargoWallet"
                [class.invalid]="errorMessage('city')"
                (focusout)="onCityFocusOut($event)"
              />
              <mat-autocomplete
                #cityAutocomplete="matAutocomplete"
                [displayWith]="displayCityFn"
                [panelWidth]="'min-content'"
              >
                <mat-option
                  *ngFor="let city of filteredCity | async"
                  (click)="onCityChanged(city.cityName)"
                  [value]="city"
                >
                  <div
                    class="iti__flag d-inline-block"
                    [ngClass]="
                      'iti__' + city.state.country.countryCode?.toLowerCase()
                    "
                  ></div>
                  {{ city.cityName }}
                </mat-option>
              </mat-autocomplete>
              <span
                class="error-msg d-flex"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="errorMessage"
              >
                {{ errorMessage("city") }}
              </span>
            </div>
            <div class="form-group pb-3">
              <label
                for="phone"
                required
                [class.wallet-black-label]="isCargoWallet"
                >Phone {{ isCargoWallet ? "Number" : "" }}</label
              >
              <input
                id="phone"
                class="form-control w-100"
                formControlName="phone"
                type="tel"
                numbersAndHyphenOnly
                [value]="
                  registerForm.get('phone').value | phoneFormatter: phoneMask
                "
                [class.invalid-wallet-border]="isCargoWallet"
                [class.invalid]="errorMessage('phone')"
                (change)="onPhoneChanged()"
              />
              <span
                class="error-msg d-flex"
                [class.wallet-error-msg]="isCargoWallet"
                *ngIf="errorMessage"
              >
                {{ errorMessage("phone") }}
              </span>
            </div>
          </form>
        </div>
        <div>
          <button
            id="btn-register"
            (click)="onRegisterClick()"
            i18n="@@registration.continue"
            class="btn-register"
            [class.wallet-blue-bg]="isCargoWallet"
            [class.disabled]="
              shouldRegisterBtnDisabled || isNotAllowedStandardCompany
            "
          >
            Continue
          </button>
        </div>
        <div
          class="d-flex justify-content-center align-items-center mt-4"
          style="gap: 16px"
        >
          <a href="/">
            <div
              [class.wallet-logo]="isCargoWallet"
              class="signup-logo"
              [ngStyle]="{
                'background-image': 'url(' + backgroundImage + ')'
              }"
            ></div>
          </a>
          <img
            *ngIf="referrer?.logoUrl"
            [src]="referrer.logoUrl"
            alt="referrer logo"
            loading="lazy"
            style="max-width: 40%"
          />
        </div>
      </div>
    </div>
  </div>
  <kt-registration-tutorial *ngIf="isCargoMart"></kt-registration-tutorial>
  <kt-registration-video *ngIf="isCargoWallet"></kt-registration-video>
</div>
