import { ICommodity } from "@cai-services";

const mockedCommodities = [
  {
    "id": 192,
    "name": "Dangerous Goods",
    "level": 1,
    "childrens": [],
    "specialHandlingCodes": [
      {
        "shcId": 94,
        "shcCode": "DGR",
        "shcDescription": "Shipper’s Declaration for Dangerous Goods",
        "public": true,
      },
    ],
    "scrs": [],
    "requirements": [
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "cargo_aircraft_only",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "pax_no",
          "specialHandlingCodes": [
            {
              "shcId": 32,
              "shcCode": "PAX",
              "shcDescription": "DGR Passenger",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "pax_no",
            "specialHandlingCodes": [
              {
                "shcId": 32,
                "shcCode": "PAX",
                "shcDescription": "DGR Passenger",
                "public": true,
              },
            ],
          },
          {
            "code": "cao_yes",
            "specialHandlingCodes": [
              {
                "shcId": 5,
                "shcCode": "CAO",
                "shcDescription": "Cargo Aircraft Only",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "known_shipper",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "uks_no",
            "specialHandlingCodes": [
              {
                "shcId": 130,
                "shcCode": "UKS",
                "shcDescription": "Unknown Shipper",
                "public": true,
              },
            ],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
    ],
  },
  {
    "id": 193,
    "name": "General Cargo",
    "level": 1,
    "childrens": [
      {
        "id": 214,
        "name": "Personal Effect (household item)",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 215,
        "name": "AircraftParts(not restricted)",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [
          {
            "shcId": 2,
            "shcCode": "AOG",
            "shcDescription": "Aircraft On Ground",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
    ],
    "specialHandlingCodes": [],
    "scrs": [],
    "requirements": [
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    "id": 194,
    "name": "Human Remains",
    "level": 1,
    "childrens": [
      {
        "id": 216,
        "name": "Cremated (ashes)",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "9099",
            "name": "Human Rem Cremtd",
          },
        ],
        "requirements": [],
      },
      {
        "id": 217,
        "name": "Uncremated (in coffin)",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [
          {
            "shcId": 25,
            "shcCode": "HUM",
            "shcDescription": "Human Remains",
            "public": true,
          },
        ],
        "scrs": [
          {
            "code": "9091",
            "name": "Human Remains, Uncremated.",
          },
        ],
        "requirements": [],
      },
    ],
    "specialHandlingCodes": [
      {
        "shcId": 13,
        "shcCode": "DIP",
        "shcDescription": "Diplomatic Mail",
        "public": true,
      },
    ],
    "scrs": [],
    "requirements": [
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    "id": 191,
    "name": "Live Animals",
    "level": 1,
    "childrens": [
      {
        "id": 204,
        "name": "Live stock",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 205,
        "name": "Zoo Animal",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 206,
        "name": "Horse",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 207,
        "name": "Live Fish",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 208,
        "name": "Reptile",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 209,
        "name": "Laboratory Animal",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [
          {
            "shcId": 124,
            "shcCode": "SPF",
            "shcDescription": "Laboratory Animals",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 210,
        "name": "Hatching Eggs",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [
          {
            "shcId": 24,
            "shcCode": "HEG",
            "shcDescription": "Hatching Eggs",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 211,
        "name": "Chicks",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 212,
        "name": "Household Pet",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 213,
        "name": "Other Live Products",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
    ],
    "specialHandlingCodes": [
      {
        "shcId": 3,
        "shcCode": "AVI",
        "shcDescription": "Live Animals",
        "public": true,
      },
    ],
    "scrs": [],
    "requirements": [
      {
        "code": "temperature_living",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "temp_not_required",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "ambiant",
            "specialHandlingCodes": [
              {
                "shcId": 17,
                "shcCode": "ERT",
                "shcDescription": "2 25C",
                "public": true,
              },
            ],
          },
          {
            "code": "controlled",
            "specialHandlingCodes": [
              {
                "shcId": 9,
                "shcCode": "CRT",
                "shcDescription": "Control Room Temp 15 25C",
                "public": false,
              },
            ],
          },
          {
            "code": "NA",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "tracking_device_number",
        "type": "NUMBER_INPUT",
        "answers": [],
      },
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "temperature_tracking_device",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "tracking_device_battery",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
    ],
    "globalHS": "1",
    "europeanHS": "10020000090",
  },
  {
    "id": 190,
    "name": "Perishable",
    "level": 1,
    "childrens": [
      {
        "id": 197,
        "name": "Fish",
        "level": 2,
        "childrens": [
          {
            "id": 235,
            "name": "Albacora",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0336",
                "name": "Albacora",
              },
            ],
            "requirements": [],
          },
          {
            "id": 236,
            "name": "Caviar",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0331",
                "name": "Caviar",
              },
            ],
            "requirements": [],
          },
          {
            "id": 237,
            "name": "Eel",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0320",
                "name": "Eel",
              },
            ],
            "requirements": [],
          },
          {
            "id": 238,
            "name": "Fish Gut-Dried",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [],
            "requirements": [],
          },
          {
            "id": 239,
            "name": "Fish Roe",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0330",
                "name": "Fish Roe",
              },
            ],
            "requirements": [],
          },
          {
            "id": 240,
            "name": "Hake",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0332",
                "name": "Hake",
              },
            ],
            "requirements": [],
          },
          {
            "id": 241,
            "name": "Mahi Mahi",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0319",
                "name": "Mahi Mahi",
              },
            ],
            "requirements": [],
          },
          {
            "id": 242,
            "name": "Salmon",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0340",
                "name": "Salmon",
              },
            ],
            "requirements": [],
          },
          {
            "id": 243,
            "name": "Shark Fins",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0370",
                "name": "Shark Fins",
              },
            ],
            "requirements": [],
          },
          {
            "id": 244,
            "name": "Snapper",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0355",
                "name": "Snapper",
              },
            ],
            "requirements": [],
          },
          {
            "id": 245,
            "name": "Tilapia",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0318",
                "name": "Tilapia",
              },
            ],
            "requirements": [],
          },
          {
            "id": 246,
            "name": "Tuna-Fresh",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [],
            "requirements": [],
          },
          {
            "id": 247,
            "name": "Other Fish - Fresh",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0301",
                "name": "Fish (Edible), Seafood (Excluding Caviar)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 248,
            "name": "Other Fish - Processed",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0307",
                "name": "Fish (Processed)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 249,
            "name": "Other Fish - Smoked",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0308",
                "name": "Fish (Smoked)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 250,
            "name": "Other Fish Products",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0306",
                "name": "Fish, Fish Products",
              },
            ],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 38,
            "shcCode": "PES",
            "shcDescription": "Fish",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 198,
        "name": "Seafood",
        "level": 2,
        "childrens": [
          {
            "id": 251,
            "name": "Abalone",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0314",
                "name": "Abalone",
              },
            ],
            "requirements": [],
          },
          {
            "id": 252,
            "name": "Clams",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0310",
                "name": "Clams, Oysters, Scallops",
              },
            ],
            "requirements": [],
          },
          {
            "id": 253,
            "name": "Oysters",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0310",
                "name": "Clams, Oysters, Scallops",
              },
            ],
            "requirements": [],
          },
          {
            "id": 254,
            "name": "Scallops",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0310",
                "name": "Clams, Oysters, Scallops",
              },
            ],
            "requirements": [],
          },
          {
            "id": 255,
            "name": "Cockles",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0311",
                "name": "Cockles",
              },
            ],
            "requirements": [],
          },
          {
            "id": 256,
            "name": "Crabs",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0316",
                "name": "Crabs",
              },
            ],
            "requirements": [],
          },
          {
            "id": 257,
            "name": "Crawfish",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0315",
                "name": "Crabs, Crawfish, Lobsters",
              },
            ],
            "requirements": [],
          },
          {
            "id": 258,
            "name": "Frogs",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0335",
                "name": "Frogs",
              },
            ],
            "requirements": [],
          },
          {
            "id": 259,
            "name": "Lobsters",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0386",
                "name": "Lobsters",
              },
            ],
            "requirements": [],
          },
          {
            "id": 260,
            "name": "Sea Urchin - Fresh",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0305",
                "name": "Sea Urchin",
              },
            ],
            "requirements": [],
          },
          {
            "id": 261,
            "name": "Sea Urchin - Processed",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0304",
                "name": "Sea Urchin / Processed",
              },
            ],
            "requirements": [],
          },
          {
            "id": 262,
            "name": "Shellfish",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0309",
                "name": "Shellfish",
              },
            ],
            "requirements": [],
          },
          {
            "id": 263,
            "name": "Shrimps",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0380",
                "name": "Shrimps, Prawns",
              },
            ],
            "requirements": [],
          },
          {
            "id": 264,
            "name": "Prawns",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0380",
                "name": "Shrimps, Prawns",
              },
            ],
            "requirements": [],
          },
          {
            "id": 265,
            "name": "Turtles",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0385",
                "name": "Turtles",
              },
            ],
            "requirements": [],
          },
          {
            "id": 266,
            "name": "Other Crustacean",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0384",
                "name": "Shrimps, Prawns, Lobsters",
              },
            ],
            "requirements": [],
          },
          {
            "id": 267,
            "name": "Other Seafood",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0302",
                "name": "Seafood",
              },
            ],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 38,
            "shcCode": "PES",
            "shcDescription": "Fish",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 199,
        "name": "Flowers, Plants",
        "level": 2,
        "childrens": [
          {
            "id": 268,
            "name": "Aquatic Plants",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1475",
                "name": "Plants",
              },
            ],
            "requirements": [],
          },
          {
            "id": 269,
            "name": "Bulbs",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1409",
                "name": "Bulbs, Tubers",
              },
            ],
            "requirements": [],
          },
          {
            "id": 270,
            "name": "Tubers",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1409",
                "name": "Bulbs, Tubers",
              },
            ],
            "requirements": [],
          },
          {
            "id": 271,
            "name": "Cut Flowers",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1430",
                "name": "Cut Flowers (Excluding Orchids)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 272,
            "name": "Fern",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1471",
                "name": "Fern",
              },
            ],
            "requirements": [],
          },
          {
            "id": 273,
            "name": "Foliage",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1421",
                "name": "Cut Flowers, Foliage, Including Mimosa",
              },
            ],
            "requirements": [],
          },
          {
            "id": 274,
            "name": "Medicinal Plants",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1482",
                "name": "Plants Medicinal",
              },
            ],
            "requirements": [],
          },
          {
            "id": 275,
            "name": "Nursery Stock",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1401",
                "name": "Floral Stock, Nursery Stock, Bulbs, Seeds, Plants, Flowers",
              },
            ],
            "requirements": [],
          },
          {
            "id": 276,
            "name": "Orchids",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1429",
                "name": "Orchids",
              },
            ],
            "requirements": [],
          },
          {
            "id": 277,
            "name": "Rose Petals",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1411",
                "name": "Rose Petals",
              },
            ],
            "requirements": [],
          },
          {
            "id": 278,
            "name": "Tagua",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1481",
                "name": "Tagua",
              },
            ],
            "requirements": [],
          },
          {
            "id": 279,
            "name": "Tropical Plants",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1477",
                "name": "Tropical Plants (Excluding Cut Flowers)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 280,
            "name": "Tropical Plants Seeds",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1480",
                "name": "Tropical Plants, Seeds",
              },
            ],
            "requirements": [],
          },
          {
            "id": 281,
            "name": "Other Flowers",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [],
            "requirements": [],
          },
          {
            "id": 282,
            "name": "Other Plants Cuttings",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1403",
                "name": "Cut Flowers, Foliage, Cuttings",
              },
            ],
            "requirements": [],
          },
          {
            "id": 283,
            "name": "Other Plants Seeds",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "1485",
                "name": "Seeds",
              },
            ],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 34,
            "shcCode": "PEF",
            "shcDescription": "Flowers",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 200,
        "name": "Food stuff",
        "level": 2,
        "childrens": [
          {
            "id": 284,
            "name": "Beverage",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0110",
                "name": "Beverages",
              },
            ],
            "requirements": [],
          },
          {
            "id": 285,
            "name": "Cakes",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0059",
                "name": "Chocolate Manufactures, Candies, Wafer Biscuits",
              },
            ],
            "requirements": [],
          },
          {
            "id": 286,
            "name": "Cheese",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0220",
                "name": "Cheese",
              },
            ],
            "requirements": [],
          },
          {
            "id": 287,
            "name": "Chocolate",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0056",
                "name": "Chocolate Manufactures",
              },
            ],
            "requirements": [],
          },
          {
            "id": 288,
            "name": "Coffee",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0180",
                "name": "Coffee",
              },
            ],
            "requirements": [],
          },
          {
            "id": 289,
            "name": "Dairy product",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0201",
                "name": "Dairy Products (Excluding Eggs)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 290,
            "name": "Eggs",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0230",
                "name": "Eggs",
              },
            ],
            "requirements": [],
          },
          {
            "id": 291,
            "name": "Ice Cream",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0245",
                "name": "Ice Cream",
              },
            ],
            "requirements": [],
          },
          {
            "id": 292,
            "name": "Infant Food",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0010",
                "name": "Infant Food",
              },
            ],
            "requirements": [],
          },
          {
            "id": 293,
            "name": "Nuts",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0008",
                "name": "Fruit, Vegetables - Fresh -",
              },
            ],
            "requirements": [],
          },
          {
            "id": 294,
            "name": "Spices",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0006",
                "name": "Foodstuffs, Spices, Beverages",
              },
            ],
            "requirements": [],
          },
          {
            "id": 295,
            "name": "Spirits, Liquors, Beers",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0129",
                "name": "Spirits, Liquors, Wines, Beers",
              },
            ],
            "requirements": [],
          },
          {
            "id": 296,
            "name": "Tea",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0185",
                "name": "Tea",
              },
            ],
            "requirements": [],
          },
          {
            "id": 297,
            "name": "Wine",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0130",
                "name": "Wines",
              },
            ],
            "requirements": [],
          },
          {
            "id": 298,
            "name": "Other Food stuff",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 14,
            "shcCode": "EAT",
            "shcDescription": "Foodstuff",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 201,
        "name": "Fruits",
        "level": 2,
        "childrens": [
          {
            "id": 299,
            "name": "Avocados",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0025",
                "name": "Avocados, Pineapples",
              },
            ],
            "requirements": [],
          },
          {
            "id": 300,
            "name": "Pineapples",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0025",
                "name": "Avocados, Pineapples",
              },
            ],
            "requirements": [],
          },
          {
            "id": 301,
            "name": "Berries",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0014",
                "name": "Fruits And Vegetables Excl. Bananas",
              },
            ],
            "requirements": [],
          },
          {
            "id": 302,
            "name": "Citrus Fruits, Persimmons",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0029",
                "name": "Melons, Persimmons And Citrus Fruits",
              },
            ],
            "requirements": [],
          },
          {
            "id": 303,
            "name": "Mangoes",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0027",
                "name": "Mangoes, Melons, Pineapples, Uchuvas",
              },
            ],
            "requirements": [],
          },
          {
            "id": 304,
            "name": "Melons",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0027",
                "name": "Mangoes, Melons, Pineapples, Uchuvas",
              },
            ],
            "requirements": [],
          },
          {
            "id": 305,
            "name": "Uchuvas",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0027",
                "name": "Mangoes, Melons, Pineapples, Uchuvas",
              },
            ],
            "requirements": [],
          },
          {
            "id": 306,
            "name": "Dried Fruits",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0002",
                "name": "Fruit (Dry, Fresh)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 307,
            "name": "Chayote",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0024",
                "name": "Avocados, Pineapples, Papaya, Melons, Tamarind, Chayote",
              },
            ],
            "requirements": [],
          },
          {
            "id": 308,
            "name": "Papaya",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0024",
                "name": "Avocados, Pineapples, Papaya, Melons, Tamarind, Chayote",
              },
            ],
            "requirements": [],
          },
          {
            "id": 309,
            "name": "Tamarind",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0024",
                "name": "Avocados, Pineapples, Papaya, Melons, Tamarind, Chayote",
              },
            ],
            "requirements": [],
          },
          {
            "id": 310,
            "name": "Other Fruits",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0007",
                "name": "Fruit, Vegetables",
              },
            ],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 36,
            "shcCode": "PEP",
            "shcDescription": "Fruits &amp; Vegetables",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 202,
        "name": "Meat",
        "level": 2,
        "childrens": [
          {
            "id": 311,
            "name": "Horse Meat",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0670",
                "name": "Horse Meat",
              },
            ],
            "requirements": [],
          },
          {
            "id": 312,
            "name": "Lamb",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0630",
                "name": "Lamb, Mutton",
              },
            ],
            "requirements": [],
          },
          {
            "id": 313,
            "name": "Mutton",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0630",
                "name": "Lamb, Mutton",
              },
            ],
            "requirements": [],
          },
          {
            "id": 314,
            "name": "Liver (Raw)",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0645",
                "name": "Liver (Raw)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 315,
            "name": "Pork Products",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0651",
                "name": "Pork, Chicken - Chilled Or Frozen -",
              },
            ],
            "requirements": [],
          },
          {
            "id": 316,
            "name": "Slaughtered Poultry",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0600",
                "name": "Meat, Incl. Slaughtered Poultry And Game",
              },
            ],
            "requirements": [],
          },
          {
            "id": 317,
            "name": "Other Meat Products*",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 35,
            "shcCode": "PEM",
            "shcDescription": "Meat",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 203,
        "name": "Vegetables",
        "level": 2,
        "childrens": [
          {
            "id": 318,
            "name": "Asparagus",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0805",
                "name": "Asparagus",
              },
            ],
            "requirements": [],
          },
          {
            "id": 319,
            "name": "Beans",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0810",
                "name": "Beans",
              },
            ],
            "requirements": [],
          },
          {
            "id": 320,
            "name": "Broccoli",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0802",
                "name": "Broccoli",
              },
            ],
            "requirements": [],
          },
          {
            "id": 321,
            "name": "Cucumber",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0806",
                "name": "Cucumber",
              },
            ],
            "requirements": [],
          },
          {
            "id": 322,
            "name": "Endives",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0838",
                "name": "Endives, Celery",
              },
            ],
            "requirements": [],
          },
          {
            "id": 323,
            "name": "Celery",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0838",
                "name": "Endives, Celery",
              },
            ],
            "requirements": [],
          },
          {
            "id": 324,
            "name": "Lettuce",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0803",
                "name": "Lettuce",
              },
            ],
            "requirements": [],
          },
          {
            "id": 325,
            "name": "Mushroom",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0850",
                "name": "Mushrooms, Mushroom Spawn",
              },
            ],
            "requirements": [],
          },
          {
            "id": 326,
            "name": "Peppers",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0881",
                "name": "Peppers",
              },
            ],
            "requirements": [],
          },
          {
            "id": 327,
            "name": "Taros",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0866",
                "name": "Taros",
              },
            ],
            "requirements": [],
          },
          {
            "id": 328,
            "name": "Tomatoes",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0880",
                "name": "Tomatoes",
              },
            ],
            "requirements": [],
          },
          {
            "id": 329,
            "name": "Plantains",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0807",
                "name": "Yams, Cocoyams, Plantains",
              },
            ],
            "requirements": [],
          },
          {
            "id": 330,
            "name": "Yams",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0807",
                "name": "Yams, Cocoyams, Plantains",
              },
            ],
            "requirements": [],
          },
          {
            "id": 331,
            "name": "Yeast",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0899",
                "name": "Yeast",
              },
            ],
            "requirements": [],
          },
          {
            "id": 332,
            "name": "Yeast - Inactive",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0898",
                "name": "Yeast - Inactive",
              },
            ],
            "requirements": [],
          },
          {
            "id": 333,
            "name": "Other Vegetables",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "0800",
                "name": "Vegetables",
              },
            ],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [
          {
            "shcId": 36,
            "shcCode": "PEP",
            "shcDescription": "Fruits &amp; Vegetables",
            "public": true,
          },
        ],
        "scrs": [],
        "requirements": [],
      },
    ],
    "specialHandlingCodes": [
      {
        "shcId": 37,
        "shcCode": "PER",
        "shcDescription": "Perishable",
        "public": false,
      },
    ],
    "scrs": [],
    "requirements": [
      {
        "code": "need_active_container",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "act_yes",
            "specialHandlingCodes": [
              {
                "shcId": 1,
                "shcCode": "ACT",
                "shcDescription": "Active Temperature",
                "public": false,
              },
            ],
          },
        ],
      },
      {
        "code": "tracking_device_number",
        "type": "NUMBER_INPUT",
        "answers": [],
      },
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "temperature_tracking_device",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "cooling_packaging",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "NA",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "NA",
            "specialHandlingCodes": [],
          },
          {
            "code": "icewater",
            "specialHandlingCodes": [
              {
                "shcId": 128,
                "shcCode": "WET",
                "shcDescription":
                  "Shipments of Wet Material not Packed in Watertight Containers",
                "public": true,
              },
            ],
          },
          {
            "code": "dryice",
            "specialHandlingCodes": [
              {
                "shcId": 27,
                "shcCode": "ICE",
                "shcDescription": "DRY ICE",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "tracking_device_battery",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "temperature_full",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "temp_not_required",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "ambiant",
            "specialHandlingCodes": [
              {
                "shcId": 17,
                "shcCode": "ERT",
                "shcDescription": "2 25C",
                "public": true,
              },
            ],
          },
          {
            "code": "controlled",
            "specialHandlingCodes": [
              {
                "shcId": 9,
                "shcCode": "CRT",
                "shcDescription": "Control Room Temp 15 25C",
                "public": false,
              },
            ],
          },
          {
            "code": "cold",
            "specialHandlingCodes": [
              {
                "shcId": 8,
                "shcCode": "COL",
                "shcDescription": "Cool Goods",
                "public": false,
              },
            ],
          },
          {
            "code": "frozen",
            "specialHandlingCodes": [
              {
                "shcId": 19,
                "shcCode": "FRO",
                "shcDescription": "Frozen Goods",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    "id": 196,
    "name": "Pharmaceuticals",
    "level": 1,
    "childrens": [
      {
        "id": 227,
        "name": "Biotechnology Products",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "6100",
            "name": "Biologicals",
          },
        ],
        "requirements": [],
      },
      {
        "id": 228,
        "name": "Chemical Reagents",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "6010",
            "name": "Chemical Reagents",
          },
        ],
        "requirements": [],
      },
      {
        "id": 229,
        "name": "Diagnostics Kits",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 230,
        "name": "Medicines",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "6501",
            "name": "Medicines, Pharmaceuticals",
          },
        ],
        "requirements": [],
      },
      {
        "id": 231,
        "name": "Vaccines",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "6099",
            "name": "Vaccines",
          },
        ],
        "requirements": [],
      },
      {
        "id": 232,
        "name": "Veterinary Medicines",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "6502",
            "name": "Veterinary Pharmaceuticals",
          },
        ],
        "requirements": [],
      },
      {
        "id": 233,
        "name": "Other Research and Development Products",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 234,
        "name": "Other Drugs",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
    ],
    "specialHandlingCodes": [
      {
        "shcId": 39,
        "shcCode": "PIL",
        "shcDescription": "Pharmaceutical",
        "public": true,
      },
    ],
    "scrs": [],
    "requirements": [
      {
        "code": "need_active_container",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "act_yes",
            "specialHandlingCodes": [
              {
                "shcId": 1,
                "shcCode": "ACT",
                "shcDescription": "Active Temperature",
                "public": false,
              },
            ],
          },
        ],
      },
      {
        "code": "tracking_device_number",
        "type": "NUMBER_INPUT",
        "answers": [],
      },
      {
        "code": "pharma_dangerous_good",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "temperature_tracking_device",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "cooling_packaging",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "NA",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "NA",
            "specialHandlingCodes": [],
          },
          {
            "code": "icewater",
            "specialHandlingCodes": [
              {
                "shcId": 128,
                "shcCode": "WET",
                "shcDescription":
                  "Shipments of Wet Material not Packed in Watertight Containers",
                "public": true,
              },
            ],
          },
          {
            "code": "dryice",
            "specialHandlingCodes": [
              {
                "shcId": 27,
                "shcCode": "ICE",
                "shcDescription": "DRY ICE",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "tracking_device_battery",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "temperature_full",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "temp_not_required",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "ambiant",
            "specialHandlingCodes": [
              {
                "shcId": 17,
                "shcCode": "ERT",
                "shcDescription": "2 25C",
                "public": true,
              },
            ],
          },
          {
            "code": "controlled",
            "specialHandlingCodes": [
              {
                "shcId": 9,
                "shcCode": "CRT",
                "shcDescription": "Control Room Temp 15 25C",
                "public": false,
              },
            ],
          },
          {
            "code": "cold",
            "specialHandlingCodes": [
              {
                "shcId": 8,
                "shcCode": "COL",
                "shcDescription": "Cool Goods",
                "public": false,
              },
            ],
          },
          {
            "code": "frozen",
            "specialHandlingCodes": [
              {
                "shcId": 19,
                "shcCode": "FRO",
                "shcDescription": "Frozen Goods",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    "id": 195,
    "name": "Valuables",
    "level": 1,
    "childrens": [
      {
        "id": 218,
        "name": "Precious Stones",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "9004",
            "name": "Pre Stones",
          },
        ],
        "requirements": [],
      },
      {
        "id": 219,
        "name": "Legal Banknotes,Coins",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 220,
        "name": "Traveller'sCheque, Securities, Shares, Share Coupons and Stamps",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "7127",
            "name": "Books, Postcards, Greeting Cards, Calendars, Credit Card Blanks, Paper Envelopes, Paper Bags, Table Decorations (Made Of Paper), Advertising Posters, Picture Reproductions, Phonograph Record Covers, Annual Reports, Blueprints, White Prints, Booklets, Pamphlets, Catalogues, Cancelled Revenue Stamps, Instruction Manuals, Maps, Prospectus, Promotional Coupons Excluding Newspapers, Photographs, Postage Stamps, Legal Banknotes, Securities, Shares, Share Coupons, Uncancelled Revenue Stamps, Diplomatic Pouches",
          },
        ],
        "requirements": [],
      },
      {
        "id": 221,
        "name": "Artworks, Artefacts",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 222,
        "name": "Jewelleries, Watches",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "9001",
            "name": "Imitation Jewelry, Personal Ornaments, Artificial Stones, Glass Stones, Beads Excluding Original Works Of Art, Antiques, Watches, Clocks, Articles Which Contain One Or More Of The Articles Listed In Resolution 594",
          },
        ],
        "requirements": [],
      },
      {
        "id": 223,
        "name": "Wines, Liquor",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 224,
        "name": "Medical Equipment",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [
          {
            "code": "8603",
            "name": "Electronic Medical Equipment",
          },
        ],
        "requirements": [],
      },
      {
        "id": 225,
        "name": "High-End Fashion",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 226,
        "name": "Other Valuables*",
        "level": 2,
        "childrens": [],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
      {
        "id": 334,
        "name": "Precious metals",
        "level": 2,
        "childrens": [
          {
            "id": 335,
            "name": "Gold",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "9005",
                "name": "Gold Bar",
              },
            ],
            "requirements": [],
          },
          {
            "id": 336,
            "name": "Platinum",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "9007",
                "name": "Platinum",
              },
            ],
            "requirements": [],
          },
          {
            "id": 337,
            "name": "Silver",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "3015",
                "name": "Silver Bullion, Silver Ingots, Silver Specie - Semi-Finished -, Silver (In Grain, Sheet, Foil, Powder, Sponge, Wire, Rod, Tube, Circles, Castings)",
              },
            ],
            "requirements": [],
          },
          {
            "id": 338,
            "name": "Other Metals",
            "level": 3,
            "childrens": [],
            "specialHandlingCodes": [],
            "scrs": [
              {
                "code": "9722",
                "name": "Metals, Minerals - Excluding Gold, Silver, Platinum, Platinum Alloys -",
              },
            ],
            "requirements": [],
          },
        ],
        "specialHandlingCodes": [],
        "scrs": [],
        "requirements": [],
      },
    ],
    "specialHandlingCodes": [
      {
        "shcId": 50,
        "shcCode": "VAL",
        "shcDescription": "Valuable",
        "public": true,
      },
    ],
    "scrs": [],
    "requirements": [
      {
        "code": "need_active_container",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "act_yes",
            "specialHandlingCodes": [
              {
                "shcId": 1,
                "shcCode": "ACT",
                "shcDescription": "Active Temperature",
                "public": false,
              },
            ],
          },
        ],
      },
      {
        "code": "contain_lithium_battery",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "default_yes",
            "specialHandlingCodes": [],
          },
        ],
      },
      {
        "code": "express",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "xps_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "XPS",
                "shcDescription": "Express",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "screened",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "spx_yes",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "spx_yes",
            "specialHandlingCodes": [
              {
                "shcId": 52,
                "shcCode": "SPX",
                "shcDescription": "Screened",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "cooling_packaging",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "NA",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "NA",
            "specialHandlingCodes": [],
          },
          {
            "code": "icewater",
            "specialHandlingCodes": [
              {
                "shcId": 128,
                "shcCode": "WET",
                "shcDescription":
                  "Shipments of Wet Material not Packed in Watertight Containers",
                "public": true,
              },
            ],
          },
          {
            "code": "dryice",
            "specialHandlingCodes": [
              {
                "shcId": 27,
                "shcCode": "ICE",
                "shcDescription": "DRY ICE",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "temperature_full",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "temp_not_required",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "ambiant",
            "specialHandlingCodes": [
              {
                "shcId": 17,
                "shcCode": "ERT",
                "shcDescription": "2 25C",
                "public": true,
              },
            ],
          },
          {
            "code": "controlled",
            "specialHandlingCodes": [
              {
                "shcId": 9,
                "shcCode": "CRT",
                "shcDescription": "Control Room Temp 15 25C",
                "public": false,
              },
            ],
          },
          {
            "code": "cold",
            "specialHandlingCodes": [
              {
                "shcId": 8,
                "shcCode": "COL",
                "shcDescription": "Cool Goods",
                "public": false,
              },
            ],
          },
          {
            "code": "frozen",
            "specialHandlingCodes": [
              {
                "shcId": 19,
                "shcCode": "FRO",
                "shcDescription": "Frozen Goods",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "lithium_battery_detail",
        "type": "SINGLE_CHOICE",
        "defaultAnswer": {
          "code": "lithium_ion",
          "specialHandlingCodes": [
            {
              "shcId": 15,
              "shcCode": "ELI",
              "shcDescription": "Lithium Ion Batteries",
              "public": true,
            },
          ],
        },
        "answers": [
          {
            "code": "lithium_metal",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "both",
            "specialHandlingCodes": [
              {
                "shcId": 16,
                "shcCode": "ELM",
                "shcDescription": "Lithium Metal Batteries",
                "public": true,
              },
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
          {
            "code": "lithium_ion",
            "specialHandlingCodes": [
              {
                "shcId": 15,
                "shcCode": "ELI",
                "shcDescription": "Lithium Ion Batteries",
                "public": true,
              },
            ],
          },
        ],
      },
      {
        "code": "diplomatic",
        "type": "YES_NO",
        "defaultAnswer": {
          "code": "default_no",
          "specialHandlingCodes": [],
        },
        "answers": [
          {
            "code": "default_no",
            "specialHandlingCodes": [],
          },
          {
            "code": "dip_yes",
            "specialHandlingCodes": [
              {
                "shcId": 13,
                "shcCode": "DIP",
                "shcDescription": "Diplomatic Mail",
                "public": true,
              },
            ],
          },
        ],
      },
    ],
  },
] as ICommodity[];

export { mockedCommodities };
