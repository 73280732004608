<div
  class="kt-login__body d-flex flex-column flex-xl-row-reverse align-items-center align-items-xl-stretch"
  (window:resize)="onResize()"
>
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="registration-card d-flex flex-column">
      <div>
        <div class="mb-4">
          <h4
            *ngIf="!confirmation"
            class="mb-3 text-center"
            [class.wallet-black]="isCargoWallet"
            i18n="@@registration.iata-cass.text-confirm-iata-cass"
          >
            Please Confirm Your IATA CASS
          </h4>
          <h4
            *ngIf="confirmation && !isIataCassFromOtherCompany"
            class="mb-3 text-center"
            i18n="@@registration.iata-cass.text-proceed-iata-cass"
            [class.wallet-black]="isCargoWallet"
          >
            Proceed with this IATA CASS ?
          </h4>
          <h4
            *ngIf="confirmation && isIataCassFromOtherCompany"
            [class.wallet-black]="isCargoWallet"
            class="mb-3 text-center"
            i18n="@@registration.iata-cass.text-iata-cass-other-company"
          >
            Looks like this IATA CASS belongs to another company
          </h4>
        </div>
      </div>
      <form
        class="d-flex iata-cass flex-column flex-grow-1 mt-4 mb-4 unfold"
        *ngIf="registerForm"
        [formGroup]="registerForm"
      >
        <div *ngIf="registerForm" class="d-flex flex-row equal-row iata-row">
          <div class="flex-grow-1"></div>
          <div class="d-flex flex-column">
            <input
              formControlName="iataCass"
              (input)="onIataCassChange()"
              [value]="registerForm.get('iataCass').value | iataCassFormatter"
              maxlength="12"
              placeholder="xxxxxxx-xxxx"
              type="text"
              [class.invalid]="!isIataCassValid"
              #iataCassInput
              [disabled]="isViewOnly"
              (blur)="handleBlur()"
            />
            <mat-error *ngIf="!isIataCassValid" class="text-center">
              <strong i18n="@@registration.iata-cass.text-invalid-iata-cass"
                >Please insert valid IATA CASS</strong
              >
            </mat-error>
          </div>
          <div class="flex-grow-1">
            <div
              *ngIf="!confirmation && !shouldDisableEdit()"
              class="pl-3 btn-edit"
              (click)="onEditClick()"
            >
              <em class="material-icons mr-2">edit</em>
              <a i18n="@@global.edit" [class.wallet-blue]="isCargoWallet"
                >Edit</a
              >
            </div>
          </div>
        </div>
        <div
          [class.invisible]="confirmation"
          [@openClose]="!confirmation ? 'open' : 'closed'"
          class="p-3 mt-4 accordion mb-4"
          [class.wallet-blue]="isCargoWallet"
        >
          <span
            [class.selected]="accordionSelected === 0"
            (click)="onAccordionClick(0)"
            i18n="@@registration.iata-cass.text-why-iata-cass"
            [class.wallet-blue]="isCargoWallet"
            >Why do you need my IATA CASS?</span
          >
          <em
            [@openClose]="accordionSelected === 0 ? 'open' : 'closed'"
            i18n="@@registration.iata-cass.text-iata-cass-use"
            [class.wallet-blue]="isCargoWallet"
            >This will be used for booking with airlines, though it is not
            mandatory.</em
          >
          <span
            [class.selected]="accordionSelected === 1"
            (click)="onAccordionClick(1)"
            [class.wallet-blue]="isCargoWallet"
            i18n="@@registration.iata-cass.text-where-iata-cass-used"
            >Where my IATA CASS will be used?</span
          >
          <em
            [@openClose]="accordionSelected === 1 ? 'open' : 'closed'"
            i18n="@@global.pending"
            [class.wallet-blue]="isCargoWallet"
            >Pending</em
          >
          <span
            [class.selected]="accordionSelected === 2"
            (click)="onAccordionClick(2)"
            [class.wallet-blue]="isCargoWallet"
            i18n="@@registration.iata-cass.text-prodive-information-later"
            >Can I provide this information later?</span
          >
          <em
            [class.wallet-blue]="isCargoWallet"
            [@openClose]="accordionSelected === 2 ? 'open' : 'closed'"
            i18n="@@global.pending"
            >Pending</em
          >
          <span
            [class.selected]="accordionSelected === 3"
            (click)="onAccordionClick(3)"
            [class.wallet-blue]="isCargoWallet"
            i18n="@@registration.iata-cass.text-can-book"
            >Can I still do the booking?</span
          >
          <em
            [class.wallet-blue]="isCargoWallet"
            [@openClose]="accordionSelected === 3 ? 'open' : 'closed'"
            i18n="@@registration.iata-cass.text-can-book-extended"
          >
            Can I still do the booking? Yes. You can do normal booking though
            ebooking and contract rates might not be available.
          </em>
        </div>
        <div
          [class.invisible]="confirmation"
          [@openClose]="!confirmation ? 'open' : 'closed'"
          class="form-check mb-4 text-center"
        >
          <input
            formControlName="noIataCass"
            class="form-check-input checkbox"
            type="checkbox"
            value=""
            (change)="onNoIataCassChange($event)"
            id="iataCassCheckbox"
            #checkbox
          />
          <label
            class="form-check-label ml-3"
            for="iataCassCheckbox"
            i18n="@@registration.text-i-don-t-have-iata-cass"
          >
            I don’t have IATA CASS
          </label>
        </div>
        <span
          class="text-center"
          *ngIf="registerForm?.controls?.noIataCass?.value"
          i18n="@@registration.iata-cass.text-add-later"
          >You can always add it later from the settings</span
        >
      </form>
      <div
        class="md-flex flex-row equal-row"
        *ngIf="confirmation && isIataCassFromOtherCompany"
      >
        <span
          class="text-center text-block-iata"
          i18n="@@registration.iata-cass.text-wrong-iata-cass"
          >Please verify your IATA CASS or contact our support team via the live
          chat so we can activate your account</span
        >
      </div>
      <div *ngIf="confirmation"></div>
      <div class="d-flex flex-row equal-row">
        <span
          *ngIf="confirmation && !isIataCassFromOtherCompany"
          class="btn-link"
          (click)="onBackClick()"
          [class.wallet-black]="isCargoWallet"
          i18n="@@global.back"
          >Back</span
        >
        <div *ngIf="confirmation && isIataCassFromOtherCompany"></div>
        <div *ngIf="!confirmation"></div>
        <div>
          <button
            *ngIf="!confirmation"
            (click)="onRegisterClick()"
            [disabled]="isDisabled || !isIataCassValid"
            i18n="@@global.confirm"
            [ngClass]="{
              'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light':
                isWaiting
            }"
            [class.wallet-blue-bg]="isCargoWallet"
          >
            Confirm
          </button>
          <button
            *ngIf="confirmation && !isIataCassFromOtherCompany"
            (click)="onConfirmClick()"
          >
            <span i18n="@@global.yes">Yes</span>
          </button>
          <button
            class="button-accent"
            *ngIf="confirmation && isIataCassFromOtherCompany"
            (click)="onBackClick()"
          >
            <span i18n="@@global.back">Back</span>
          </button>
        </div>
        <div *ngIf="confirmation"></div>
        <span
          *ngIf="!confirmation"
          class="btn-link"
          (click)="onSkipClick()"
          i18n="@@global.skip"
          [class.wallet-black]="isCargoWallet"
          >Skip</span
        >
      </div>
      <div class="mt-2">
        <a href="/">
          <div
            class="signup-logo mt-4"
            [ngStyle]="{
              'background-image': 'url(' + backgroundImage + ')'
            }"
          ></div>
        </a>
      </div>
    </div>
  </div>
  <kt-registration-tutorial *ngIf="isCargoMart"></kt-registration-tutorial>
  <kt-registration-video *ngIf="isCargoWallet"></kt-registration-video>
</div>
