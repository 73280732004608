<form autocomplete="off" [formGroup]="acceptInvitationForm">
  <h3
    i18n="@@registration.user.create-account"
    class="font-weight-bold create-account-txt text-center mt-3"
  >
    Hello New User!
  </h3>
  <p
    style="font-size: 14px"
    class="text-center"
    i18n="@@registration.user.verify-details"
  >
    Please enter the following fields for confirmation
  </p>
  <kt-registration-progress-bar [steps]="steps"></kt-registration-progress-bar>

  <div class="d-flex flex-row mt-4">
    <div class="form-group w-50 pr-1">
      <label required for="firstName" i18n="@@global.first-name"
        >First name</label
      >
      <input
        type="text"
        formControlName="firstName"
        autocomplete="off"
        class="form-control"
        id="firstName"
        [class.invalid]="
          acceptInvitationForm.controls.firstName.invalid &&
          acceptInvitationForm.controls.firstName.dirty
        "
        [class.valid]="
          acceptInvitationForm.controls.firstName.valid &&
          acceptInvitationForm.controls.firstName.dirty
        "
      />
      <mat-error
        *ngIf="
          isControlHasError('firstName', 'required') ||
          isControlHasError('firstName', 'noLetters')
        "
      >
        <strong i18n="@@global.text-error-required-field"
          >Required field</strong
        >
      </mat-error>
    </div>
    <div class="form-group w-50">
      <label required for="lastName" i18n="@@global.last-name">Last name</label>
      <input
        type="text"
        formControlName="lastName"
        autocomplete="off"
        class="form-control"
        id="lastName"
        [class.invalid]="
          acceptInvitationForm.controls.lastName.invalid &&
          acceptInvitationForm.controls.lastName.dirty
        "
        [class.valid]="
          acceptInvitationForm.controls.lastName.valid &&
          acceptInvitationForm.controls.lastName.dirty
        "
      />
      <mat-error
        *ngIf="
          isControlHasError('lastName', 'required') ||
          isControlHasError('lastName', 'noLetters')
        "
      >
        <strong i18n="@@global.text-error-required-field"
          >Required field</strong
        >
      </mat-error>
    </div>
  </div>

  <div class="form-group">
    <label required for="phone-number" i18n="@@global.phone-number"
      >Phone</label
    >
    <input
      id="phone-number"
      type="tel"
      numbersAndHyphenOnly
      formControlName="phoneNumber"
      class="form-control form-control-solid office-phone-input borderless"
      [value]="
        acceptInvitationForm.get('phoneNumber').value
          | phoneFormatter: phoneMask
      "
    />
    <mat-error
      *ngIf="
        isControlHasError('phoneNumber', 'required') &&
        !isControlHasError('phoneNumber', 'phoneInvalid')
      "
    >
      <strong i18n="@@global.text-error-required-field">Required field</strong>
    </mat-error>
    <mat-error *ngIf="isControlHasError('phoneNumber', 'phoneInvalid')">
      <strong i18n="@@global.invalid-phone-number"
        >Please enter a valid phone number</strong
      >
    </mat-error>
  </div>
  <cai-registration-password-field
    [passwordControl]="acceptInvitationForm.controls.password"
    [isCargoWallet]="isCargoWallet"
  ></cai-registration-password-field>
  <div>
    <p>
      <span i18n="@@registration.agree">
        By clicking below, you agree to CargoAi
      </span>
      <a
        target="_blank"
        href="https://cargoai.co/terms"
        i18n="@@registration.terms-of-service"
        rel="noopener"
        >Terms of Service</a
      >
      and
      <a
        target="_blank"
        href="https://cargoai.co/privacy-policy"
        i18n="@@registration.privacy-policy"
        rel="noopener"
        >Privacy Policy</a
      >
    </p>
  </div>
</form>
<div class="mt-2">
  <button
    (click)="onAcceptInvitation()"
    [disabled]="!acceptInvitationForm.valid"
  >
    <span i18n="@@global.continue">Submit</span>
    <span
      *ngIf="isLoading"
      class="spinner-border"
      role="status"
      aria-hidden="true"
    ></span>
  </button>
</div>
<div
  class="d-flex justify-content-center align-items-center mt-4"
  style="gap: 16px"
>
  <a href="/">
    <div
      class="signup-logo"
      [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    ></div>
  </a>
</div>
