<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <!-- begin:: Header Mobile -->
  <kt-header-mobile></kt-header-mobile>
  <!-- end:: Header Mobile -->
  <!-- begin::Body -->
  <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
    <!-- begin:: Aside Left -->
    <ng-container *ngIf="asideDisplay" \>
      <button class="kt-aside-close" id="kt_aside_close_btn">
        <em class="la la-close"></em>
      </button>
      <kt-aside-left></kt-aside-left>
    </ng-container>
    <!-- end:: Aside Left -->

    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
      id="kt_wrapper"
    >
      <!-- begin:: Header -->
      <kt-header [isWalletApp]="isWalletApp"></kt-header>
      <!-- end:: Header -->

      <!-- begin:: Content -->
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
        [style.top.px]="headerService.headerHeight$ | async"
      >
        <!-- begin:: Content Head -->
        <!-- <kt-subheader *ngIf="subheaderDisplay"></kt-subheader> -->
        <!-- end:: Content Head -->

        <!-- begin:: Content Body -->

        <div
          ktContentAnimate
          class="kt-grid__item kt-grid__item--fluid main-container"
          [ngClass]="{ 'kt-container--fluid': fluid }"
          [class.bg-color]="isFrench || isShipmentDetailsPage"
        >
          <router-outlet></router-outlet>
          <div class="brand-container" *ngIf="otherBrand">
            <span i18n="@@global.powered-by">Powered by</span>
            <div
              class="cargoai-icon"
              [ngStyle]="{
                'background-image':
                  'url(' + '/assets/media/logos/cargoai-without-logo.png' + ')',
                'text-align': 'center',
              }"
            ></div>
          </div>
        </div>
        <!-- end:: Content Body -->
      </div>
      <!-- end:: Content -->
    </div>
  </div>
  <!-- end:: Body -->

  <!-- <kt-scroll-top></kt-scroll-top> -->
  <!-- <kt-sticky-toolbar></kt-sticky-toolbar> -->
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
  <router-outlet></router-outlet>
</ng-template>
