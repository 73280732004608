import { AuthenticationMethodEnum, UserTypeEnum } from "@cai-services";
import { ApplicationProperties } from "../../core/";

export const mockedAppProperties: ApplicationProperties = {
  "name": undefined,
  "logo": undefined,
  "registerUrl": undefined,
  "walletBaseUrl": undefined,
  "menu": [
    {
      "userType": UserTypeEnum.AIRLINE,
      "authenticationMethods": [
        AuthenticationMethodEnum.COGNITO,
        AuthenticationMethodEnum.DEV,
        AuthenticationMethodEnum.ANONYMOUS,
        AuthenticationMethodEnum.SYSTEM,
      ],
      "defaultPage": "/airline/quote/quote-list",
      "config": {},
    },
    {
      "userType": UserTypeEnum.FORWARDER,
      "authenticationMethods": [
        AuthenticationMethodEnum.COGNITO,
        AuthenticationMethodEnum.DEV,
        AuthenticationMethodEnum.TOKEN,
        AuthenticationMethodEnum.ANONYMOUS,
        AuthenticationMethodEnum.SYSTEM,
      ],
      "defaultPage": "/forwarder/search/forwarder-search",
      "config": {},
    },
  ],
};
