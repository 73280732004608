import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { QuoteStatusEnum } from "@cai-services";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import moment from "moment";
import { LightQuote } from "../../core/_models/light-quote.model";
import { QuoteListService } from "../../core/_services/quote-list/quote-list.service";
import { SessionService } from "../../core/_services/session.service";
import {
  AIRLINE_GROUP,
  FORWARDER_GROUP,
} from "../../core/_constants/constants";
import { MeasurementUnits } from "../../core/_models/measurement-system.model";
import { APP_PROPS } from "../../cai-common.module";
import { ApplicationProperties } from "../../core/_base/layout/models/app-properties.model";

@Component({
  "selector": "kt-header-search",
  "templateUrl": "./header-search.component.html",
  "styleUrls": ["./header-search.component.scss"],
})
export class HeaderSearchComponent implements OnInit {
  isUserType: string;
  quotesList: LightQuote[] = [];
  filteredQuotes: Observable<LightQuote[]>;
  userId: number;
  quoteCtrl = new FormControl();
  searchValue: string;
  quoteStatusEnum = QuoteStatusEnum;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private quoteListService: QuoteListService,
    private ref: ChangeDetectorRef,
    private sessionService: SessionService
  ) {}

  ngOnInit () {
    this.checkUserType();
    this.filteredQuotes = this.quoteCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter (value: string): LightQuote[] {
    if (value?.length < 1 || typeof value !== "string") {
      this.searchValue = undefined;
      return this.quotesList;
    }

    const queryValue = value?.toLocaleLowerCase();
    this.searchValue = queryValue;
    const filteredList = this.quotesList.filter((quote) => {
      const quoteIdFilterResults = this.filterByQuoteId(queryValue, quote),

       airlineCompanyNameFilterResults = this.filterByAirlineCompanyName(
        queryValue,
        quote
      ),
       destAirportFilterResults = this.filterByDestAirport(
        queryValue,
        quote
      ),
       awbFilterResults = this.filterByAwb(queryValue, quote);

      return (
        quoteIdFilterResults ||
        airlineCompanyNameFilterResults ||
        destAirportFilterResults ||
        awbFilterResults
      );
    });
    if (
      this.quoteCtrl.value.length === 4 &&
      this.isUserType === "F" &&
      +this.quoteCtrl.value &&
      filteredList.length > 0
    ) {
      const quote = this.quotesList.find((res) =>
        res.quoteId.toString().includes(this.quoteCtrl.value)
      );
      if (quote !== undefined) {
        const relatedQuotes = this.retrieveQuotesInSameQuoteRequest(
          quote.quoteRequestId
        );
        if (relatedQuotes.length > 1) {
          const quoteRequestQuotes = relatedQuotes.filter(
            (res) => !this.quoteCtrl.value.includes(res.quoteId)
          );
          quoteRequestQuotes.forEach((res) => {
            filteredList.push(res);
          });
        }
      }
    }
    return filteredList;
  }

  filterByQuoteId (queryString: string, quote: LightQuote) {
    return quote.quoteId.toString().includes(queryString);
  }

  filterByAirlineCompanyName (queryString: string, quote: LightQuote): boolean {
    return (
      quote.airlineCompanyCode.toLocaleLowerCase().includes(queryString) ||
      quote.airlineCompanyName.toLocaleLowerCase().includes(queryString)
    );
  }

  filterByDestAirport (queryString: string, quote: LightQuote) {
    return (
      quote.destRegionAirportName.toLowerCase().includes(queryString) ||
      quote.destRegionAirportCode.toLocaleLowerCase().includes(queryString)
    );
  }

  filterByAwb (queryString: string, quote: LightQuote) {
    return quote.awb?.includes(queryString);
  }

  retrieveQuotesInSameQuoteRequest (quoteRequestId) {
    return this.quotesList.filter(
      (quote) => quote.quoteRequestId === quoteRequestId
    );
  }

  displayFn (selection?: any): string | undefined {
    if (typeof selection === "string" || typeof selection === "number") {
      return `${selection}`;
    }

    return selection?.quoteId ? selection.quoteId : undefined;
  }

  async checkUserType () {
    const currentGroups = await this.sessionService.getCurrentUserGroup();

    if (currentGroups && currentGroups.includes(FORWARDER_GROUP)) {
      this.isUserType = "F";
    } else if (currentGroups && currentGroups.includes(AIRLINE_GROUP)) {
      this.isUserType = "A";
    }
    if (this.isUserType && !this.isCargoWallet) {
      this.fetchQuotes();
    }
  }

  getAWBNumber (awbNumber: string): string {
    return awbNumber
      ? awbNumber.substr(0, 3) + "-" + awbNumber.substr(3) + "-"
      : "";
  }

  formatQuoteString (quote: LightQuote): string {
    let nbPiece = "";
    if (quote.actualPieces) {
      nbPiece = `${quote.actualPieces} ${
        quote.actualPieces > 1 ? "pcs" : "pc"
      }`;
    }
    return (
      quote.quoteId +
      " - " +
      quote.airlineCompanyCode +
      "-" +
      quote.originRegionAirportCode +
      "/" +
      this.dateFormatter(quote.startDepartureDate) +
      "-" +
      `${quote.chargeableWeight} ${
        MeasurementUnits[quote.measurementSystem].WEIGHT.display
      }, ${quote.actualVolumes} ${
        MeasurementUnits[quote.measurementSystem].VOLUME.display
      }${this.showPieces(nbPiece)}` +
      "-" +
      this.getAWBNumber(quote.awb) +
      this.convertStatus(quote.quoteStatus)
    );
  }

  showPieces (nbPieces: string): string {
    if (nbPieces === "") {
      return "";
    } else {
      return ", " + nbPieces;
    }
  }

  dateFormatter (date) {
    return moment(date).format("DD MMM");
  }

  convertStatus (quoteStatus: QuoteStatusEnum) {
    switch (quoteStatus) {
      case QuoteStatusEnum.PENDING_AIRLINE:
        return "Pending Airline";
      case QuoteStatusEnum.EXPIRED:
        return "Expired";
      case QuoteStatusEnum.COUNTER_OFFER:
        return "Counter Offer";
      case QuoteStatusEnum.PENDING_AIRLINE_INFORMATION:
        return "Booking Requested";
      case QuoteStatusEnum.DELIVERED:
        return "Delivered";
      case QuoteStatusEnum.REJECTED_WITH_NEW_CAPACITY:
      case "REJECTED":
        return QuoteStatusEnum.REJECTED;
      case QuoteStatusEnum.ACCEPTED:
        return "Accepted";
      case QuoteStatusEnum.PENDING_DELIVERY:
        return "Pending Delivery";
      case QuoteStatusEnum.IN_TRANSIT:
        return "In Transit";
      case QuoteStatusEnum.AT_DESTINATION:
        return "At Destination";
      case QuoteStatusEnum.INVALID:
        return "Invalid";
      case QuoteStatusEnum.PENDING_BOOKING_MODIFY:
        return "Booking Modification Requested";
      default:
        return "Undefined";
    }
  }

  fetchQuotes () {
    this.quoteListService.retrieveAllQuotesByUser().subscribe((quotes) => {
      this.quotesList = quotes;
      this.ref.detectChanges();
    });
  }

  get isCargoWallet (): boolean {
    return this.appProperties.name === "cargowallet";
  }
  get isCargoAdmin (): boolean {
    return this.appProperties.name === "cargoadmin";
  }
}
