<div class="component-container" *ngIf="isUserType && !isCargoWallet && !isCargoAdmin">
  <div class="search-container">
    <input
      type="text"
      i18n-placeholder="@@header-search.search-shipment"
      placeholder="Search shipment..."
      aria-label="quotes"
      matInput
      [matAutocomplete]="searchHeader"
      [formControl]="quoteCtrl"
      class="search-input"
    />
    <em class="flaticon-search"></em>
    <mat-autocomplete
      #searchHeader="matAutocomplete"
      class="option-container"
      [displayWith]="displayFn"
      [panelWidth]="400"
    >
      <div class="header-container">
        <div class="title-container">
          <em class="flaticon2-crisp-icons"></em>
          <span class="search-title" i18n="@@global.quotes">Quotes</span>
          <span class="quotesCount">{{ quotesList.length }}</span>
        </div>
      </div>
      <span class="border-black"></span>
      <mat-option
        *ngFor="let quote of filteredQuotes | async | slice : 0 : 25"
        [value]="quote"
        class="option-quote"
        [ngClass]="{
          'green-background':
            quote?.quoteStatus === quoteStatusEnum.ACCEPTED ||
            quote?.quoteStatus === quoteStatusEnum.DELIVERED,
          'light-blue-background':
            quote?.quoteStatus === quoteStatusEnum.COUNTER_OFFER,
          'pink-background':
            quote?.quoteStatus === quoteStatusEnum.REJECTED ||
            quote?.quoteStatus === quoteStatusEnum.REJECTED_WITH_NEW_CAPACITY ||
            quote?.quoteStatus === quoteStatusEnum.NOT_REPRESENTATIVE ||
            quote?.quoteStatus === quoteStatusEnum.EXPIRED ||
            quote?.quoteStatus === quoteStatusEnum.INVALID,
          'indigo-background':
            quote?.quoteStatus === quoteStatusEnum.PENDING_AIRLINE ||
            quote?.quoteStatus === quoteStatusEnum.PENDING_AIRLINE_INFORMATION,
          'yellow-orange-background':
            quote?.quoteStatus === quoteStatusEnum.PENDING_DELIVERY
        }"
      >
        <a [routerLink]="['/shipment-details/' + quote.quoteId]">
          <div class="flex-option">
            <span class="border-left"></span>
            <span
              [innerHTML]="
                formatQuoteString(quote) | highlightSearch : searchValue
              "
            ></span>
          </div>
        </a>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
