import {
  BookingTypeEnum,
  CompanyTypeEnum,
  FlightRateTypeEnum,
  PackingTypeEnum,
  QuoteRequestStatusEnum,
  QuoteStatusEnum,
  UserStatusEnum,
  WeightTypeEnum,
} from "@cai-services";
import { Company } from "../../core/_models/company.model";
import { FlightInput } from "../../core/_models/flight-input.model";
import { Forwarder } from "../../core/_models/forwarder.model";
import { QuoteItem } from "../../core/_models/quote-item.model";

const mockedFlight = {
  "input": {
    "flightUUID": "aa1c2054-1057-11eb-af0f-32ec17a64612",
    "source": null,
    "arrivalTime": "2020-10-21T07:35:00+08:00",
    "arrivalAirport": "CDG",
    "airlineCode": "SQ",
    "departureTime": "2020-10-21T00:15:00+08:00",
    "departureAirport": "SIN",
    "flightNumber": "SQ336",
    "bookable": true,
    "sortingFlightKey": "SQ3362020-10-21",
    "legs": [
      {
        "flightNumber": "SQ336",
        "arrivalTime": "2020-10-21T07:35:00+08:00",
        "arrivalAirport": "CDG",
        "departureTime": "2020-10-21T00:15:00+08:00",
        "departureAirport": "SIN",
        "bodyType": "Widebody",
        "legId": 0,
        "aircraftCode": "359",
        "description": "",
        "co2": {
          "value": 5,
          "estimation": true,
        },
        "aircraft": {
          "aircraftName": "Airbus A350-900",
          "aircraftCode": "359",
          "capacity": 16000,
          "aircraftType": {
            "aircraftTypeId": 4,
            "aircraftTypeName": "Widebody",
            "dimensionLength": 315,
            "dimensionWidth": 240,
            "dimensionHeight": 160,
          },
        },
      },
    ],
    "rates": [
      {
        "id": "0",
        "name": "Standard",
        "type": "live",
        "currency": "EUR",
        "netRate": 0,
        "allInRate": 1.8,
        "total": 300.06,
        "minimumRate": false,
        "charges": null,
        "formattedName": "Live Rate",
      },
      {
        "id": "2",
        "name": "Premium",
        "type": "live",
        "currency": "EUR",
        "netRate": 0,
        "allInRate": 2.16,
        "total": 360.072,
        "minimumRate": false,
        "charges": null,
        "formattedName": "Live Rate",
      },
    ],
    "freightRate": {
      "rate": 0,
      "charge": 0,
      "currency": "",
      "otherCharges": 0,
      "grandTotal": 0,
      "otherChargeDetails": [],
    },
    "airlineCompany": {
      "airlineCompanyId": 7,
      "airlineCompanyName": "Singapore Airlines",
      "airlineCompanyCode": "SQ",
      "mainAirlineLogoPath":
        "https://forwarders.cargoai.co/assets/media/airlinesLogo/SQ.png",
      "mainWebsiteUrl": "http://www.siacargo.com/",
      "awbPrefix": "618",
      "generalConditions": "This is a general condition paragraph for testing.",
      "company": {
        "id": 7,
        "companyCode": "Singapore Airlines",
        "companyName": "Singapore Airlines",
        "companyType": "AIRLINE",
      },
    },
    "selectedRate": {
      "allInRate": 1.8,
      "charges": null,
      "currency": "EUR",
      "formattedName": "Live Rate",
      "id": "0",
      "name": "Standard",
      "netRate": 0,
      "total": 300.06,
      "type": "live",
    },
  },
  "searchUUID": "5c1bc56f-9d73-4f39-b09c-36cf9afb8d45",
},

 mockedUnfinishedBookings = [
  {
    "quoteId": 1,
    "quoteStatus": QuoteStatusEnum.PENDING_AIRLINE_INFORMATION,
    "quoteDate": new Date(1600751162516),
    "awb": "15711112222",
    "awbType": null,
    "awbDisabled": false,
    "maxAttachmentCount": 3,
    "maxAttachmentTotalSize": 9000000,
    "isViewForwarder": true,
    "isViewAirline": false,
    "quoteCurrency": "SGD",
    "lastUpdatedDate": new Date(1600751162516),
    "isRate": false,
    "minWeight": 1767,
    "maxWeight": 3314,
    "maxDensity": 20,
    "validity": new Date(1600923965000),
    "contacts": [{ "contact": { "userEmail": "teamcargoai+PR_SIN@gmail.com" } }],
    "searchUUID": "aaaaaaaa",
    "flightUUID": "bbbbbbbb",
    "attachments": [],
    "quoteRequest": {
      "quotes": [],
      "quoteRequestId": 2266,
      "quoteRequestStatus": QuoteRequestStatusEnum.PENDING,
      "transCurrencyAmount": 1,
      "originalQuotePrice": 0,
      "originalQuoteCapacity": 2209,
      "bookingType": BookingTypeEnum.MANUAL_BOOK,
      "comments": "AutoTest CargoAI Matt22/09 13:05",
      "maxDepartureDays": 1,
      "forwarder": {
        "userId": 1,
        "userName": "Forwarder Singapore",
        "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
        "userStatus": UserStatusEnum.ACTIVATED,
        "logoPath": "user1.png",
        "contact": { "countryCode": "33", "cityCode": "", "phoneNumber": "" },
        "smtp": "",
        "pima": "pimatest",
        "defaultCurrency": "SGD",
      } as Forwarder,
      "quoteItems": [
        {
          "quoteItemId": 5253,
          "weight": 2209,
          "dimensionLength": -100,
          "dimensionWidth": -100,
          "dimensionHeight": -1000,
          "numOfItems": 1,
          "weightType": WeightTypeEnum.TOTAL,
          "typeOfProduct": {
            "typeOfProductId": 5,
            "productCode": "GCR",
            "productDescription": "General Cargo",
          },
          "packing": PackingTypeEnum.NONE,
        } as QuoteItem,
      ],
      "isShipmentScreened": false,
      "specialHandlingCodes": [],
    },
    "trips": [
      {
        "id": 96958,
        "endArrivalDate": "2020-09-25T17:00:00+08:00",
        "startDepartureDate": "2020-09-24T19:30:00+08:00",
        "flightNo": "TBC",
        "flights": [
          {
            "id": 189165,
            "arrivalDate": "2020-09-24T23:10:00+08:00",
            "departureDate": "2020-09-24T19:30:00+08:00",
            "flightNo": "TBC",
            "originRegionAirport": {
              "regionCode": "ASPAC",
              "regionName": "Asia Pacific",
              "airportCode": "SIN",
              "airportName": "Changi",
              "timezone": "Asia/Singapore",
              "city": { "cityId": 1455, "cityCode": "SIN", "cityName": "Singapore" },
              "destFlights": undefined,
              "originFlights": undefined,
            },
            "destRegionAirport": {
              "regionCode": "ASPAC",
              "regionName": "Asia Pacific",
              "airportCode": "MNL",
              "airportName": "Ninoy Aquino Intl",
              "timezone": "Asia/Singapore",
              "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
              "destFlights": undefined,
              "originFlights": undefined,
            },
            "aircraft": {
              "aircraftName": "Unknown Aircraft",
              "aircraftCode": "UNKN",
              "capacity": 50000,
              "aircraftType": {
                "aircraftTypeId": 5,
                "aircraftTypeName": "Unknown",
                "dimensionLength": 0,
                "dimensionWidth": 0,
                "dimensionHeight": 0,
                "aircrafts": undefined,
              },
            },
          },
          {
            "id": 189166,
            "arrivalDate": new Date(1601053200000),
            "departureDate": new Date(1601039400000),
            "flightNo": "TBC",
            "originRegionAirport": {
              "regionCode": "ASPAC",
              "regionName": "Asia Pacific",
              "airportCode": "MNL",
              "airportName": "Ninoy Aquino Intl",
              "timezone": "Asia/Singapore",
              "city": { "cityId": 1303, "cityCode": "MNL", "cityName": "Manila" },
              "destFlights": undefined,
              "originFlights": undefined,
            },
            "destRegionAirport": {
              "regionCode": "ASPAC",
              "regionName": "Asia Pacific",
              "airportCode": "NRT",
              "airportName": "Narita Intl",
              "city": { "cityId": 912, "cityCode": "TYO", "cityName": "Tokyo" },
              "destFlights": undefined,
              "originFlights": undefined,
            },
            "aircraft": {
              "aircraftName": "Unknown Aircraft",
              "aircraftCode": "UNKN",
              "capacity": 50000,
              "aircraftType": {
                "aircraftTypeId": 5,
                "aircraftTypeName": "Unknown",
                "dimensionLength": 0,
                "dimensionWidth": 0,
                "dimensionHeight": 0,
                "aircrafts": undefined,
              },
            },
          },
        ],
      },
    ],
    "airlineBrand": {
      "airlineCompanyCode": "SQ",
      "airlineCompanyId": 7,
      "airlineCompanyName": "Singapore Airlines",
      "awbPrefix": "618",
      "company": {
        "companyType": "AIRLINE",
        "companyCode": "Singapore Airlines",
        "companyName": "Singapore Airlines",
        "id": 7,
        "users": [],
        "airlineBrands": [],
      },
      "generalConditions": "This is a general condition paragraph for testing.",
      "mainAirlineLogoPath":
        "https://forwarders.cargoai.co/assets/media/airlinesLogo/SQ.png",
      "mainWebsiteUrl": "http://www.siacargo.com/",
      "routes": [],
      "negoRoutes": [],
      "emailToConfirms": [],
    },
    "skyPalletID": undefined,
    "originAirport": undefined,
    "destinationAirport": undefined,
    "possibleActions": [],
    "provider": undefined,
  } as any,
],

 mockedCustomFlight: FlightInput[] = [
  {
    "airlineCode": "SQ",
    "airlineCompany": {
      "airlineCompanyCode": "SQ",
      "airlineCompanyId": 7,
      "airlineCompanyName": "Singapore Airlines",
      "awbPrefix": "618",
      "company": {
        "companyType": CompanyTypeEnum.AIRLINE,
        "companyCode": "Singapore Airlines",
        "companyName": "Singapore Airlines",
        "id": 7,
        "users": [],
        "airlineBrands": [],
      } as Company,
      "mainAirlineLogoPath":
        "https://forwarders.cargoai.co/assets/media/airlinesLogo/SQ.png",
      "mainWebsiteUrl": "http://www.siacargo.com/",
      "routes": [],
      "negoRoutes": [],
      "emailToConfirms": [],
    } as any,
    "arrivalAirport": "PVG",
    "arrivalTime": "2020-10-24T00:10:00+08:00",
    "checked": true,
    "departureAirport": "SIN",
    "departureTime": "2020-10-23T18:40:00+08:00",
    "latestAcceptanceTime": "2020-10-23T18:40:00+08:00",
    "timeOfAvailability": "2020-10-24T12:20:00+08:00",
    "handlingInfoLink": "https://www.cargoai.co/terms",
    "ghaName": "Singaport",
    "ghaAddress": "Singapore",
    "flightNumber": "SQ7858",
    "flightUUID": "771eb75c-135d-11eb-9390-72d7d11fad68",
    "loading": false,
    "available": null,
    "availableReason": "",
    "checkoutUrl": "",
    "features": {
      "equote": false,
      "bookable": false,
      "instantBookable": false,
      "checkoutUrl": "",
      "awbOptional": false,
      "awbDisabled": false,
      "eaw": false,
      "eap": false,
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
    },
    "freightRate": {
      "charge": 0,
      "currency": "",
      "grandTotal": 0,
      "otherChargeDetail": [],
      "otherCharges": 0,
      "rate": 4,
    },
    "legs": [
      {
        "aircraft": {
          "aircraftCode": "74Y",
          "aircraftName": "Boeing 747-400F (Freighter)",
          "aircraftType": {
            "aircrafts": [] as any,
            "aircraftTypeId": 1,
            "aircraftTypeName": "Freighter",
            "dimensionHeight": 240,
            "dimensionLength": 480,
            "dimensionWidth": 240,
          } as any,
          "capacity": 113000,
        } as any,
        "co2": {
          "value": 5,
          "estimation": true,
        },
        "bodyType": "Freighter",
        "aircraftCode": "74Y",
        "arrivalAirport": "PVG",
        "arrivalTime": "2020-10-24T00:10:00+08:00",
        "departureAirport": "SIN",
        "departureTime": "2020-10-23T18:40:00+08:00",
        "description": "",
        "flightNumber": "SQ7858",
        "legId": "",
        "parentFlightUUID": "",
      },
    ],
    "rates": [
      {
        "name": "",
        "type": FlightRateTypeEnum.MARKET_RATE,
        "currency": "EUR",
        "netRate": 0,
        "allInRate": 1.8,
        "total": 300.06,
        "minimumRate": false,
        "charges": null,
        "ocdc": null,
        "formattedName": "Market Rate",
        "lastUpdatedDate": null,
      },
      {
        "name": "",
        "type": FlightRateTypeEnum.CONTRACT_RATE,
        "currency": "EUR",
        "netRate": 0,
        "allInRate": 2.16,
        "total": 360.072,
        "charges": null,
        "ocdc": null,
        "minimumRate": false,
        "formattedName": "Contract Rate",
        "lastUpdatedDate": null,
      },
    ],
    "sortingFlightKey": "SQ78582020-10-23",
    "source": null,
    "stops": "",
    "elapsedTime": "",
    "airlineConditions": "",
    "salesConditions": "",
  },
],

 mockedSelectedFlights = {
  "values": mockedCustomFlight,
  "searchUUID": "771eb75c-135d-11eb-9390-72d7d11fad68",
},

 mockedSpecialAirlineFlight = {
  "flightUUID": "9b09dd39-60b4-11eb-95ed-d224ec3a054d",
  "source": null,
  "arrivalTime": "2021-02-19T20:25:00+08:00",
  "arrivalAirport": "JFK",
  "airlineCode": "YW",
  "departureTime": "2021-02-19T08:25:00+08:00",
  "departureAirport": "LIS",
  "flightNumber": "YW8765-IB6253",
  "bookable": false,
  "sortingFlightKey": "YW87652021-02-19IB62532021-02-19",
  "loading": false,
  "available": null,
  "availableReason": "",
  "features": {
    "equote": false,
    "bookable": false,
    "instantBookable": false,
    "awbOptional": false,
    "awbDisabled": false,
    "eaw": false,
    "eap": false,
    "maxAttachmentCount": 3,
    "maxAttachmentTotalSize": 9000000,
  },
  "legs": [
    {
      "flightUUID": undefined,
      "parentFlightUUID": undefined,
      "flightNumber": "YW8765",
      "arrivalTime": "2021-02-19T10:50:00+08:00",
      "arrivalAirport": "MAD",
      "departureTime": "2021-02-19T08:25:00+08:00",
      "departureAirport": "LIS",
      "bodyType": "Narrowbody",
      "legId": null,
      "aircraftCode": "CRK",
      "description": null,
      "co2": {
        "value": 5,
        "estimation": true,
      },
      "aircraft": {
        "aircraftName": "Canadair (Bombardier) Regional Jet 1000",
        "aircraftCode": "CRK",
        "capacity": 500,
        "aircraftType": {
          "aircraftTypeId": 2,
          "aircraftTypeName": undefined,
          "dimensionLength": 0,
          "dimensionWidth": 0,
          "dimensionHeight": 0,
          "aircrafts": [],
        },
      },
    },
    {
      "flightUUID": undefined,
      "parentFlightUUID": undefined,
      "flightNumber": "IB6253",
      "arrivalTime": "2021-02-19T20:25:+08:00",
      "arrivalAirport": "JFK",
      "departureTime": "2021-02-19T17:40:00+08:00",
      "departureAirport": "MAD",
      "bodyType": "Widebody",
      "legId": null,
      "aircraftCode": "330",
      "description": null,
      "co2": {
        "value": 5,
        "estimation": true,
      },
      "aircraft": {
        "aircraftName": "Airbus A330",
        "aircraftCode": "330",
        "capacity": 16000,
        "aircraftType": {
          "aircraftTypeId": 4,
          "aircraftTypeName": "Widebody",
          "dimensionLength": 315,
          "dimensionWidth": 240,
          "dimensionHeight": 160,
          "aircrafts": [],
        },
      },
    },
  ],
  "freightRate": {
    "rate": 0,
    "charge": 0,
    "currency": "",
    "otherCharges": 0,
    "grandTotal": 0,
    "otherChargeDetails": [],
  },
  "rates": null,
  "airlineCompany": {
    "airlineCompanyId": 93,
    "airlineCompanyName": "Iberia",
    "airlineCompanyCode": "IB",
    "awbPrefix": "075",
    "company": {
      "id": 8228,
      "companyCode": "Iberia",
      "companyName": "Iberia",
      "companyType": "AIRLINE",
      "logoHash": "28767a96-44fb-11eb-88a2-06f57e4303ea",
      "users": [],
      "airlineBrands": [],
    },
    "routes": undefined,
    "negoRoutes": undefined,
    "emailToConfirms": undefined,
  },
  "checked": false,
  "elapsedTime": undefined,
  "stops": undefined,
  "airlineConditions": "",
  "salesConditions": "",
} as any,

 mockedShipmentDetailFlight = {
  "id": 100893,
  "endArrivalDate": "2020-09-25T17:00:00+08:00",
  "startDepartureDate": "2020-09-25T17:00:00+08:00",
  "flightNo": "TBC",
  "flights": [
    {
      "id": 195310,
      "arrivalDate": "2020-09-25T17:00:00+08:00",
      "departureDate": "2020-09-25T17:00:00+08:00",
      "flightNo": "TBC",
      "originRegionAirport": {
        "airportCode": "AUH",
        "airportName": "International",
        "regionCode": "MENA",
        "regionName": "Middle East & North Africa",
        "timezone": "Asia/Singapore",
        "city": {
          "cityId": 1650,
          "cityCode": "AUH",
          "cityName": "Abu Dhabi",
          "state": {
            "stateId": 297,
            "stateName": "United Arab Emirates",
            "stateCode": "AE",
            "country": {
              "countryCode": "AE",
              "countryName": "United Arab Emirates",
            },
          },
        },
      },
      "destRegionAirport": {
        "airportCode": "NRT",
        "airportName": "Narita Intl",
        "regionCode": "ASPAC",
        "regionName": "Asia Pacific",
        "city": {
          "cityId": 912,
          "cityCode": "TYO",
          "cityName": "Tokyo",
          "state": {
            "stateId": 178,
            "stateName": "Japan",
            "stateCode": "JP",
            "country": {
              "countryCode": "JP",
              "countryName": "Japan",
            },
          },
        },
      },
      "aircraft": {
        "aircraftName": "Boeing 787-9",
        "aircraftCode": "789",
        "capacity": 16000,
        "aircraftType": {
          "aircraftTypeId": 4,
          "aircraftTypeName": "Widebody",
          "dimensionLength": 315,
          "dimensionWidth": 240,
          "dimensionHeight": 160,
        },
      },
    },
    {
      "id": 195309,
      "arrivalDate": "2021-02-20T05:40:00.000+00:00",
      "departureDate": "2021-02-20T02:00:00.000+00:00",
      "flightNo": "TBC",
      "originRegionAirport": {
        "airportCode": "SIN",
        "airportName": "Changi",
        "regionCode": "ASPAC",
        "regionName": "Asia Pacific",
        "timezone": "Asia/Singapore",
        "city": {
          "cityId": 1455,
          "cityCode": "SIN",
          "cityName": "Singapore",
          "state": {
            "stateId": 266,
            "stateName": "Singapore",
            "stateCode": "SG",
            "country": {
              "countryCode": "SG",
              "countryName": "Singapore",
            },
          },
        },
      },
      "destRegionAirport": {
        "airportCode": "AUH",
        "airportName": "International",
        "regionCode": "MENA",
        "regionName": "Middle East & North Africa",
        "timezone": "Asia/Singapore",
        "city": {
          "cityId": 1650,
          "cityCode": "AUH",
          "cityName": "Abu Dhabi",
          "state": {
            "stateId": 297,
            "stateName": "United Arab Emirates",
            "stateCode": "AE",
            "country": {
              "countryCode": "AE",
              "countryName": "United Arab Emirates",
            },
          },
        },
      },
      "aircraft": {
        "aircraftName": "Boeing 787-9",
        "aircraftCode": "789",
        "capacity": 16000,
        "aircraftType": {
          "aircraftTypeId": 4,
          "aircraftTypeName": "Widebody",
          "dimensionLength": 315,
          "dimensionWidth": 240,
          "dimensionHeight": 160,
        },
      },
    },
  ],
} as any,

 mockedFilteredFlights = [
  {
    "flightUUID": "b699d656-1627-11ec-93d4-be3a81333479",
    "source": "AI-CIR",
    "arrivalTime": "2021-09-16T11:05:00Z",
    "arrivalAirport": "NRT",
    "airlineCode": "TR",
    "departureTime": "2021-09-16T01:00:00Z",
    "departureAirport": "SIN",
    "flightNumber": "TR898",
    "sortingFlightKey": "TR8982021-09-16",
    "loading": true,
    "available": false,
    "availableReason": null,
    "awbPrefix": null,
    "features": {
      "equote": null,
      "bookable": true,
      "awbOptional": null,
      "awbDisabled": null,
      "eap": null,
      "eaw": null,
      "allotment": null,
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
    },
    "legs": [
      {
        "flightNumber": "TR898",
        "arrivalTime": "2021-09-16T11:05:00+09:00",
        "arrivalAirport": "NRT",
        "departureTime": "2021-09-16T01:00:00+08:00",
        "departureAirport": "SIN",
        "bodyType": "Widebody",
        "aircraftCode": "788",
        "co2": {
          "value": 0.9341583055703184,
          "estimation": false,
        },
        "aircraft": {
          "aircraftName": "Boeing 787-8",
          "aircraftCode": "788",
          "capacity": 12000,
          "volumeCapacity": null,
          "aircraftType": {
            "aircraftTypeId": 4,
            "aircraftTypeName": "Widebody",
            "dimensionLength": 315,
            "dimensionWidth": 240,
            "dimensionHeight": 160,
          },
        },
        "untilEnd": 53.81944444444444,
        "mainPath": 42.013888888888886,
      },
    ],
    "rates": null,
    "airlineConditions": null,
    "salesConditions": null,
    "airlineCompany": {
      "airlineCompanyId": 28,
      "airlineCompanyName": "Scoot",
      "airlineCompanyCode": "TR",
      "awbPrefix": "668",
      "mainWebsiteUrl": "NULL",
      "company": {
        "id": 28,
        "companyCode": "Scoot",
        "companyName": "Scoot",
        "companyType": "AIRLINE",
        "emailDomains": [],
        "providers": [],
        "logoHash": "283df06b-44fb-11eb-88a2-06f57e4303ea",
      },
    },
    "disabled": false,
  },
  {
    "flightUUID": "b699d680-1627-11ec-93d4-be3a81333479",
    "source": "AI-CIR",
    "arrivalTime": "2021-09-16T16:20:00Z",
    "arrivalAirport": "NRT",
    "airlineCode": "JL",
    "departureTime": "2021-09-16T08:10:00Z",
    "departureAirport": "SIN",
    "flightNumber": "JL712",
    "sortingFlightKey": "JL7122021-09-16",
    "loading": true,
    "available": false,
    "availableReason": null,
    "awbPrefix": null,
    "features": {
      "equote": null,
      "bookable": true,
      "awbOptional": null,
      "awbDisabled": null,
      "eap": null,
      "eaw": null,
      "allotment": null,
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
    },
    "legs": [
      {
        "flightNumber": "JL712",
        "arrivalTime": "2021-09-16T16:20:00+09:00",
        "arrivalAirport": "NRT",
        "departureTime": "2021-09-16T08:10:00+08:00",
        "departureAirport": "SIN",
        "bodyType": "Widebody",
        "aircraftCode": "789",
        "co2": {
          "value": 0.9341583055703184,
          "estimation": false,
        },
        "aircraft": {
          "aircraftName": "Boeing 787-9",
          "aircraftCode": "789",
          "capacity": 16000,
          "volumeCapacity": null,
          "aircraftType": {
            "aircraftTypeId": 4,
            "aircraftTypeName": "Widebody",
            "dimensionLength": 315,
            "dimensionWidth": 240,
            "dimensionHeight": 160,
          },
        },
        "untilEnd": 31.944444444444443,
        "mainPath": 34.027777777777786,
      },
    ],
    "rates": null,
    "airlineConditions": null,
    "salesConditions": null,
    "airlineCompany": {
      "airlineCompanyId": 51,
      "airlineCompanyName": "Japan Airlines",
      "airlineCompanyCode": "JL",
      "awbPrefix": "131",
      "mainWebsiteUrl": "http://www.jal.co.jp/en/jalcargo/inter/awb/",
      "company": {
        "id": 48,
        "companyCode": "Japan Airlines",
        "companyName": "Japan Airlines",
        "companyType": "AIRLINE",
        "emailDomains": [],
        "providers": [],
        "logoHash": "283e0b84-44fb-11eb-88a2-06f57e4303ea",
      },
    },
    "disabled": false,
  },
  {
    "flightUUID": "bb267466-1627-11ec-8426-ea24c139eb3b",
    "source": "AI-AFKL",
    "arrivalTime": "2021-09-20T08:35:00+09:00",
    "arrivalAirport": "NRT",
    "airlineCode": "KL",
    "departureTime": "2021-09-17T23:55:00+08:00",
    "departureAirport": "SIN",
    "flightNumber": "KL840-KL861",
    "sortingFlightKey": "KL8402021-09-17KL8612021-09-19",
    "loading": false,
    "available": true,
    "availableReason": null,
    "awbPrefix": null,
    "features": {
      "equote": null,
      "bookable": true,
      "awbOptional": null,
      "awbDisabled": null,
      "eap": null,
      "eaw": null,
      "allotment": null,
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
    },
    "legs": [
      {
        "flightNumber": "KL840",
        "arrivalTime": "2021-09-18T06:50:00+02:00",
        "arrivalAirport": "AMS",
        "departureTime": "2021-09-17T23:55:00+08:00",
        "departureAirport": "SIN",
        "bodyType": "Widebody",
        "aircraftCode": "789",
        "co2": {
          "value": 1.7875306834554372,
          "estimation": false,
        },
        "aircraft": {
          "aircraftName": "Boeing 787-9",
          "aircraftCode": "789",
          "capacity": 16000,
          "volumeCapacity": null,
          "aircraftType": {
            "aircraftTypeId": 4,
            "aircraftTypeName": "Widebody",
            "dimensionLength": 315,
            "dimensionWidth": 240,
            "dimensionHeight": 160,
          },
        },
        "transitTime": 33.07291666666667,
        "mainPath": 13.45486111111111,
      },
      {
        "flightNumber": "KL861",
        "arrivalTime": "2021-09-20T08:35:00+09:00",
        "arrivalAirport": "NRT",
        "departureTime": "2021-09-19T14:35:00+02:00",
        "departureAirport": "AMS",
        "bodyType": "Widebody",
        "aircraftCode": "77W",
        "co2": {
          "value": 2.0581815434743533,
          "estimation": false,
        },
        "aircraft": {
          "aircraftName": "Boeing 777-300ER Passenger",
          "aircraftCode": "77W",
          "capacity": 20000,
          "volumeCapacity": null,
          "aircraftType": {
            "aircraftTypeId": 4,
            "aircraftTypeName": "Widebody",
            "dimensionLength": 315,
            "dimensionWidth": 240,
            "dimensionHeight": 160,
          },
        },
        "untilEnd": 16.05902777777778,
        "mainPath": 12.499999999999993,
      },
    ],
    "rates": [
      {
        "id": "0",
        "name": "MARKET",
        "type": "live",
        "currency": "SGD",
        "netRate": 0,
        "allInRate": 12.5,
        "total": 2091,
        "minimumRate": false,
        "charges": [
          {
            "code": "MJ",
            "label": "net_rate",
            "type": "CHARGEABLE_WEIGHT",
            "rate": 3.5,
          },
        ],
        "formattedName": "Live Rate",
      },
    ],
    "airlineConditions": null,
    "salesConditions": null,
    "airlineCompany": {
      "airlineCompanyId": 10,
      "airlineCompanyName": "KLM-Royal Dutch Airlines",
      "airlineCompanyCode": "KL",
      "awbPrefix": "074",
      "mainWebsiteUrl": "https://www.afklcargo.com/mycargo/shipment/singlesearch",
      "company": {
        "id": 8993,
        "companyCode": "KL",
        "companyName": "KLM-Royal Dutch Airlines",
        "companyType": "AIRLINE",
        "emailDomains": [],
        "providers": [],
        "logoHash": "2c370949-2494-47fb-8633-049368bdab66",
      },
    },
    "selectedRate": {
      "id": "0",
      "name": "MARKET",
      "type": "live",
      "currency": "SGD",
      "netRate": 0,
      "allInRate": 12.5,
      "total": 2091,
      "charges": [
        {
          "code": "MJ",
          "label": "net_rate",
          "type": "CHARGEABLE_WEIGHT",
          "rate": 3.5,
        },
      ],
      "formattedName": "Live Rate",
    },
    "disabled": false,
  },
],

 mockedInstantBookableFlight = {
  "flightUUID": "f7ed49a4-1b62-11ec-adce-2e57fbfbb0f7",
  "source": "AI-MAS",
  "arrivalTime": "2021-10-02T16:20:00+07:00",
  "arrivalAirport": "BKK",
  "airlineCode": "MH",
  "departureTime": "2021-09-30T01:00:00+02:00",
  "departureAirport": "CDG",
  "flightNumber": "MH6781-MH6125-MH782",
  "sortingFlightKey": "MH67812021-09-30MH61252021-09-30MH7822021-10-02",
  "loading": false,
  "available": true,
  "availableReason": null,
  "awbPrefix": null,
  "features": {
    "equote": null,
    "bookable": true,
    "instantBookable": true,
    "awbOptional": null,
    "awbDisabled": null,
    "eap": null,
    "eaw": null,
    "allotment": null,
    "maxAttachmentCount": 3,
    "maxAttachmentTotalSize": 9000000,
  },
  "legs": [
    {
      "flightNumber": "MH6781",
      "arrivalTime": "2021-09-30T06:00:00+02:00",
      "arrivalAirport": "AMS",
      "departureTime": "2021-09-30T01:00:00+02:00",
      "departureAirport": "CDG",
      "bodyType": "Surface",
      "aircraftCode": "RFS",
      "co2": {
        "value": 4.124692726690095475,
        "estimation": false,
      },
      "aircraft": {
        "aircraftName": "Truck",
        "aircraftCode": "RFS",
        "capacity": 20000,
        "volumeCapacity": null,
        "aircraftType": {
          "aircraftTypeId": 3,
          "aircraftTypeName": "Surface",
          "dimensionLength": 318,
          "dimensionWidth": 244,
          "dimensionHeight": 300,
        },
      },
      "transitTime": 6.1342592592592595,
      "mainPath": 6.944444444444445,
    },
    {
      "flightNumber": "MH6125",
      "arrivalTime": "2021-10-01T07:50:00+08:00",
      "arrivalAirport": "KUL",
      "departureTime": "2021-09-30T10:25:00+02:00",
      "departureAirport": "AMS",
      "bodyType": "Freighter",
      "aircraftCode": "33X",
      "co2": {
        "value": 3.469682628817481,
        "estimation": false,
      },
      "aircraft": {
        "aircraftName": "Airbus A330-200 Freighter",
        "aircraftCode": "33X",
        "capacity": 65000,
        "volumeCapacity": null,
        "aircraftType": {
          "aircraftTypeId": 1,
          "aircraftTypeName": "Freighter",
          "dimensionLength": 318,
          "dimensionWidth": 244,
          "dimensionHeight": 300,
        },
      },
      "transitTime": 43.40277777777778,
      "mainPath": 21.412037037037038,
    },
    {
      "flightNumber": "MH782",
      "arrivalTime": "2021-10-02T16:20:00+07:00",
      "arrivalAirport": "BKK",
      "departureTime": "2021-10-02T15:05:00+08:00",
      "departureAirport": "KUL",
      "bodyType": "Widebody",
      "aircraftCode": "333",
      "co2": {
        "value": 0.3159480630916641,
        "estimation": false,
      },
      "aircraft": {
        "aircraftName": "Airbus A330-300",
        "aircraftCode": "333",
        "capacity": 16000,
        "volumeCapacity": null,
        "aircraftType": {
          "aircraftTypeId": 4,
          "aircraftTypeName": "Widebody",
          "dimensionLength": 315,
          "dimensionWidth": 240,
          "dimensionHeight": 160,
        },
      },
      "untilEnd": 10.648148148148147,
      "lastPosition": 90,
      "mainPath": 10.069444444444448,
    },
  ],
  "rates": [
    {
      "id": "1",
      "name": "",
      "type": "live",
      "currency": "EUR",
      "netRate": 4.8,
      "allInRate": 4.8,
      "total": 801.6,
      "minimumRate": false,
      "charges": null,
      "lastUpdatedDate": "",
      "formattedName": "Live Rate",
    },
  ],
  "airlineConditions": null,
  "salesConditions": null,
  "airlineCompany": {
    "airlineCompanyId": 15,
    "airlineCompanyName": "Malaysia Airlines",
    "airlineCompanyCode": "MH",
    "awbPrefix": "232",
    "mainWebsiteUrl": "http://www.maskargo.com/",
    "company": {
      "id": 15,
      "companyCode": "Malaysia Airlines",
      "companyName": "Malaysia Airlines",
      "companyType": "AIRLINE",
      "emailDomains": [],
      "providers": [],
      "logoHash": "283c9517-44fb-11eb-88a2-06f57e4303ea",
    },
  },
  "selectedRate": {
    "id": "1",
    "name": "",
    "type": "live",
    "currency": "EUR",
    "netRate": 4.8,
    "allInRate": 4.8,
    "total": 801.6,
    "charges": null,
    "lastUpdatedDate": "",
    "formattedName": "Live Rate",
  },
  "disabled": false,
},

 mockedBookableFlight = {
  "flightUUID": "f7ed49a4-1b62-12ec-adce-2e57fbfbb0f7",
  "source": "AI-MAS",
  "arrivalTime": "2021-10-02T16:20:00+07:00",
  "arrivalAirport": "BKK",
  "airlineCode": "MH",
  "departureTime": "2021-09-30T01:00:00+02:00",
  "departureAirport": "CDG",
  "flightNumber": "MH6781-MH6125-MH782",
  "sortingFlightKey": "MH67812021-09-30MH61252021-09-30MH7822021-10-02",
  "loading": false,
  "available": true,
  "availableReason": null,
  "awbPrefix": null,
  "features": {
    "equote": null,
    "bookable": true,
    "instantBookable": false,
    "awbOptional": null,
    "awbDisabled": null,
    "eap": null,
    "eaw": null,
    "allotment": null,
    "maxAttachmentCount": 3,
    "maxAttachmentTotalSize": 9000000,
  },
  "legs": [
    {
      "flightNumber": "MH6781",
      "arrivalTime": "2021-09-30T06:00:00+02:00",
      "arrivalAirport": "AMS",
      "departureTime": "2021-09-30T01:00:00+02:00",
      "departureAirport": "CDG",
      "bodyType": "Surface",
      "aircraftCode": "RFS",
      "co2": {
        "value": 0.024692726690095475,
        "estimation": false,
      },
      "aircraft": {
        "aircraftName": "Truck",
        "aircraftCode": "RFS",
        "capacity": 20000,
        "volumeCapacity": null,
        "aircraftType": {
          "aircraftTypeId": 3,
          "aircraftTypeName": "Surface",
          "dimensionLength": 318,
          "dimensionWidth": 244,
          "dimensionHeight": 300,
        },
      },
      "transitTime": 6.1342592592592595,
      "mainPath": 6.944444444444445,
    },
    {
      "flightNumber": "MH6125",
      "arrivalTime": "2021-10-01T07:50:00+08:00",
      "arrivalAirport": "KUL",
      "departureTime": "2021-09-30T10:25:00+02:00",
      "departureAirport": "AMS",
      "bodyType": "Freighter",
      "aircraftCode": "33X",
      "co2": {
        "value": 3.469682628817481,
        "estimation": false,
      },
      "aircraft": {
        "aircraftName": "Airbus A330-200 Freighter",
        "aircraftCode": "33X",
        "capacity": 65000,
        "volumeCapacity": null,
        "aircraftType": {
          "aircraftTypeId": 1,
          "aircraftTypeName": "Freighter",
          "dimensionLength": 318,
          "dimensionWidth": 244,
          "dimensionHeight": 300,
        },
      },
      "transitTime": 43.40277777777778,
      "mainPath": 21.412037037037038,
    },
    {
      "flightNumber": "MH782",
      "arrivalTime": "2021-10-02T16:20:00+07:00",
      "arrivalAirport": "BKK",
      "departureTime": "2021-10-02T15:05:00+08:00",
      "departureAirport": "KUL",
      "bodyType": "Widebody",
      "aircraftCode": "333",
      "co2": {
        "value": 0.3159480630916641,
        "estimation": false,
      },
      "aircraft": {
        "aircraftName": "Airbus A330-300",
        "aircraftCode": "333",
        "capacity": 16000,
        "volumeCapacity": null,
        "aircraftType": {
          "aircraftTypeId": 4,
          "aircraftTypeName": "Widebody",
          "dimensionLength": 315,
          "dimensionWidth": 240,
          "dimensionHeight": 160,
        },
      },
      "untilEnd": 10.648148148148147,
      "lastPosition": 90,
      "mainPath": 10.069444444444448,
    },
  ],
  "rates": [
    {
      "id": "1",
      "name": "",
      "type": "live",
      "currency": "EUR",
      "netRate": 4.8,
      "allInRate": 4.8,
      "total": 801.6,
      "minimumRate": false,
      "charges": null,
      "lastUpdatedDate": "",
      "formattedName": "Live Rate",
    },
  ],
  "airlineConditions": null,
  "salesConditions": null,
  "airlineCompany": {
    "airlineCompanyId": 15,
    "airlineCompanyName": "Malaysia Airlines",
    "airlineCompanyCode": "MH",
    "awbPrefix": "232",
    "mainWebsiteUrl": "http://www.maskargo.com/",
    "company": {
      "id": 15,
      "companyCode": "Malaysia Airlines",
      "companyName": "Malaysia Airlines",
      "companyType": "AIRLINE",
      "emailDomains": [],
      "providers": [],
      "logoHash": "283c9517-44fb-11eb-88a2-06f57e4303ea",
    },
  },
  "selectedRate": {
    "id": "1",
    "name": "",
    "type": "live",
    "currency": "EUR",
    "netRate": 4.8,
    "allInRate": 4.8,
    "total": 801.6,
    "charges": null,
    "lastUpdatedDate": "",
    "formattedName": "Live Rate",
  },
  "disabled": false,
};

export {
  mockedFlight,
  mockedCustomFlight,
  mockedUnfinishedBookings,
  mockedSpecialAirlineFlight,
  mockedShipmentDetailFlight,
  mockedSelectedFlights,
  mockedFilteredFlights,
  mockedBookableFlight,
  mockedInstantBookableFlight,
};
